import {
  CONTENT_FILE_SOCIAL_ADD_REQUEST,
  CONTENT_FILE_SOCIAL_ADD_SUCCESS,
  CONTENT_FILE_SOCIAL_ADD_FAIL,
  CONTENT_FILE_SOCIAL_REMOVE_REQUEST,
  CONTENT_FILE_SOCIAL_REMOVE_SUCCESS,
  CONTENT_FILE_SOCIAL_REMOVE_FAIL,
  SHARE_LINK_REQUEST,
  SHARE_LINK_SUCCESS,
  SHARE_LINK_FAIL,
  SHARE_LINK_CLEAR,
  COLLECTION_SOCIAL_ADD_REQUEST,
  COLLECTION_SOCIAL_ADD_SUCCESS,
  COLLECTION_SOCIAL_ADD_FAIL,
  FLAG_VIDEO_REQUEST,
  FLAG_VIDEO_SUCCESS,
  FLAG_VIDEO_FAIL,
  FLAG_VIDEO_CLEAR,
  LIST_UPLOAD_REQUEST,
  LIST_UPLOAD_SUCCESS,
  LIST_UPLOAD_FAIL,
  LIST_UPLOAD_CLEAR
} from "../types";

export const LikeReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_SOCIAL_ADD_REQUEST:
      return { loading: true };
    case CONTENT_FILE_SOCIAL_ADD_SUCCESS:
      return { loading: false, Like: action.payload };
    case CONTENT_FILE_SOCIAL_ADD_FAIL:
      return { loading: false, Like: action.payload };
    default:
      return state;
  }
};
export const RemoveLikeReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_SOCIAL_REMOVE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_SOCIAL_REMOVE_SUCCESS:
      return { loading: false, RemoveLike: action.payload };
    case CONTENT_FILE_SOCIAL_REMOVE_FAIL:
      return { loading: false, RemoveLike: action.payload };
    default:
      return state;
  }
};

export const ShareLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case SHARE_LINK_REQUEST:
      return { loading: true };
    case SHARE_LINK_SUCCESS:
      return {
        loading: false,
        ShareLink: action.payload,
        socialName: action.payload.socialName,
      };
    case SHARE_LINK_FAIL:
      return { loading: false, ShareLink: action.payload };
    case SHARE_LINK_CLEAR:
      return { loading: false, ShareLink: {} };
    default:
      return state;
  }
};

export const CollectionSocialAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COLLECTION_SOCIAL_ADD_REQUEST:
      return { loading: true };
    case COLLECTION_SOCIAL_ADD_SUCCESS:
      return { loading: false, CollectionSocialAdd: action.payload };
    case COLLECTION_SOCIAL_ADD_FAIL:
      return { loading: false, CollectionSocialAdd: action.payload };
    default:
      return state;
  }
};

export const ListUploadCallReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_UPLOAD_REQUEST:
      return { loading: true };
    case LIST_UPLOAD_SUCCESS:
      return { loading: false, ListUpload: action.payload };
    case LIST_UPLOAD_FAIL:
      return { loading: false, ListUpload: action.payload };
    case LIST_UPLOAD_CLEAR:
      return { loading: false, ListUpload: "" };
    default:
      return state;
  }
};

export const FlagVideoReducer = (state = {}, action) => {
  switch (action.type) {
    case FLAG_VIDEO_REQUEST:
      return { loading: true };
    case FLAG_VIDEO_SUCCESS:
      return { loading: false, flagVideo: action.payload };
    case FLAG_VIDEO_FAIL:
      return { loading: false, flagVideo: action.payload };
    case FLAG_VIDEO_CLEAR:
      return { loading: false, flagVideo: "" };
    default:
      return state;
  }
};