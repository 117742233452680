import {
  POST_LIST_FOR_CONTENTFILE_REQUEST,
  POST_LIST_FOR_CONTENTFILE_SUCCESS,
  POST_LIST_FOR_CONTENTFILE_FAIL,
  POST_LIST_SORT,
  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_CREATE_FAIL,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
} from "../types";

export const PostListForContentFileReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_LIST_FOR_CONTENTFILE_REQUEST:
      return { loading: true };
    case POST_LIST_FOR_CONTENTFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        PostListForContentFile: action.payload,
      };
    case POST_LIST_FOR_CONTENTFILE_FAIL:
      return { loading: false, PostListForContentFile: action.payload };
    case POST_LIST_SORT: {
      let ascPosts = [...state.PostListForContentFile];

      return {
        loading: false,
        PostListForContentFile: ascPosts.reverse(),
      };
    }
    default:
      return state;
  }
};
export const PostCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CREATE_REQUEST:
      return { loading: true };
    case POST_CREATE_SUCCESS:
      return { loading: false, PostCreate: action.payload };
    case POST_CREATE_FAIL:
      return { loading: false, PostCreate: action.payload };
    default:
      return state;
  }
};
export const PostDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_DELETE_REQUEST:
      return { loading: true };
    case POST_DELETE_SUCCESS:
      return { loading: false, PostDelete: action.payload };
    case POST_DELETE_FAIL:
      return { loading: false, PostDelete: action.payload };
    default:
      return state;
  }
};
