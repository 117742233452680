import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PrivateRoute from "./Routes/PrivateRoute";
import { getBrowserInfo, getOsInfo, isSignIn } from "./utils";
import RequestFollowContribute from "./Screens/RequestFollowContribute";

const JoinUs = React.lazy(() => import("./Screens/JoinUs.js"));
const SignUp = React.lazy(() => import("./Screens/SignUp"));
const SignIn = React.lazy(() => import("./Screens/SignIn"));
const Error = React.lazy(() => import("./Screens/Error404"));
const DeveloperSupport = React.lazy(() => import("./Screens/DeveloperSupport"));
const HelpUs = React.lazy(() => import("./Screens/HelpUs"));
const Faq = React.lazy(() => import("./Screens/Faq"));
const Profile = React.lazy(() => import("./Screens/Profile"));
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const ForgotUsername = React.lazy(() => import("./Screens/ForgotUsername"));
const ForgotPassword = React.lazy(() => import("./Screens/ForgotPassword"));
const VideoPlaying = React.lazy(() => import("./Screens/VideoPlaying"));
const Notification = React.lazy(() => import("./Screens/Notification"));
const UploadVideos = React.lazy(() => import("./Screens/UploadVideos"));
const UploadVideoMultiple = React.lazy(() =>
  import("./Screens/UploadVideoMultiple")
);
const Searching = React.lazy(() => import("./Components/Views/Search"));
const MyProfile = React.lazy(() => import("./Screens/MyProfile"));
const Embed = React.lazy(() => import("./Screens/Embed"));

// This function checks if the user session has timed out or not
// It receives the reference to the session start time (as a ref object) and the browser history object
function checkSession(sessionStartTimeRef, history) {
  // Get the current time in milliseconds
  const currentTime = new Date().getTime();
  const sessionTimeout = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

  // Check if the difference between the current time and session start time is greater than the session timeout
  if (currentTime - sessionStartTimeRef.current > sessionTimeout) {
    // If the session has timed out, clear the local storage and redirect to the signin page
    // Selectively remove values from localStorage
    const keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key !== 'uploadVideoData') {
        localStorage.removeItem(key);
      }
    }
    localStorage.removeItem("sessionStartTime"); // Remove session start time from localStorage
    history.push("/signin");
  }
}

function App() {
  const history = useHistory();
  localStorage.removeItem("searchingData");
  const isLoggedIn = useSelector((state) => state.Login.loggedIn);
  const loginData = useSelector((state) => state.Login.loginData);
  const sessionStartTimeRef = useRef(
    parseInt(localStorage.getItem("sessionStartTime"))
  );
  const [browser, setBrowser] = useState(getBrowserInfo());
  const [osInfo, setOsInfo] = useState(getOsInfo());

  function fetchData() {
    let releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
    let buildVersion = process.env.REACT_APP_BUILD_VERSION;
    let browser = getBrowserInfo();
    let osInfo = getOsInfo();
    let client = `Web+[${browser.name}+${browser.version}]-[${osInfo.name}+(${buildVersion})]+v${releaseVersion}+(v${buildVersion})`;
    localStorage.setItem("Client", client);
  }

  useEffect(() => {
    fetchData();
  }, [loginData]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check if user is logged in
    const loggedInCheck = localStorage.getItem("loggedIn");
    if (loggedInCheck === "true") {
      // Set session timeout interval
      const intervalId = setInterval(() => {
        checkSession(sessionStartTimeRef, history);
        checkVideoPlaying();
        checkVideoUpload();
      }, 1000); // Check session and user activity every second

      // Add event listeners to reset session when user is active
      const handleUserActivity = () => {
        if (sessionStartTimeRef.current) {
          checkSession(sessionStartTimeRef, history);
        }
        resetSession(sessionStartTimeRef);
      };

      const checkVideoPlaying = () => {
        const videoElement = document.querySelector('.video-js');
        if (videoElement && videoElement.classList.contains('vjs-playing')) {
          handleUserActivity();
        }
      };

      const checkVideoUpload = () => {
        const videoUploadElement = document.getElementById('upload-progress');
        if (videoUploadElement) {
          const progressBarElement = videoUploadElement.querySelector('.progress-bar-animated');
          if (progressBarElement) {
            handleUserActivity();
          }
        }
      };

      document.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);

      // Cleanup function to remove event listeners and clear interval on unmount
      return () => {
        clearInterval(intervalId);
        document.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
      };
    }
  }, [sessionStartTimeRef, isLoggedIn, history]);

  // This function resets the session start time to the current time
  // It receives the reference to the session start time (as a ref object)
  function resetSession(sessionStartTimeRef) {
    sessionStartTimeRef.current = new Date().getTime();
    localStorage.setItem("sessionStartTime", sessionStartTimeRef.current.toString());
  }


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense>
        <Switch>
          <Route exact path="/help" render={(props) => <HelpUs {...props} />} />
          <Route exact path="/faqs" render={(props) => <Faq {...props} />} />
          <PrivateRoute
            name="profile"
            path="/notification"
            component={Notification}
          />
          <Route
            exact
            path="/devloper-support"
            render={(props) => <DeveloperSupport {...props} />}
          />
          <Route
            exact
            path="/forgot-username"
            render={(props) => <ForgotUsername {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} />}
          />

          {isSignIn() ? null : (
            <Route
              exact
              path="/joinUs"
              render={(props) => <JoinUs {...props} />}
            />
          )}
          <Route
            exact
            path="/signup"
            render={(props) => <SignUp {...props} />}
          />
          <Route
            exact
            path="/signin"
            render={(props) => <SignIn {...props} />}
          />

          <PrivateRoute component={Profile} path="/profile" name="profile" />
          <PrivateRoute
            component={MyProfile}
            path="/my-profile"
            name="my-profile"
          />
          <Route
            exact
            path="/watch"
            render={(props) => <VideoPlaying {...props} />}
          />
          <Route
            exact
            path="/embed/:id"
            render={(props) => <Embed {...props} />}
          />

          <PrivateRoute
            name="upload-videos"
            path="/upload-videos"
            component={UploadVideoMultiple}
          // render={(props) => <UploadVideos {...props} />}
          />

          {/* <Route
            exact
            path="/results"
            render={(props) => <Searching {...props} />}
          /> */}

          {/* <Route exact path="*" render={(props) => <Error {...props} />} /> */}
          <Route path="/home/request/:type" render={props => <RequestFollowContribute {...props} />} />
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;