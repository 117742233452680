import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
    FaFacebookF,
    FaTwitter,
} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaLink } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ContentFileLinkCreateAction, ContentFileLinkCreateClear, ContentFileLinkDeleteAction, ContentFileLinkDeleteClear, ContentFileLinkUpdateAction } from "../../Redux/Actions/ContentFileGetNodeAction";
import { isSignIn, jsonToFormData } from "../../utils";
import { ShareLinkAction, ShareLinkClearAction } from "../../Redux/Actions/SocialAction";

const ManagedLinksModal = ({
    videoId = "",
    isClickAdd = false,
    setIsClickAdd = () => { },
    fetchContentFileLinkList = () => { },
    editLinkData = {},
    setEditLinkData = () => { },
    setIsClickUpdate = () => { },
    isDuplicate = false,
    setIsDuplicate = () => { },
    isDelete = false,
    setIsDelete = () => { },
    origin = "",
    handleClearEditModalData = () => { }
}) => {
    let Client = localStorage.getItem("Client");
    let SessionGuid = isSignIn()
        ? JSON.parse(localStorage.getItem("SessionGuid"))
        : null;

    const defautFormData = {
        Client: "",
        SessionGuid: "",
        Active: "",
        MaxViews: "",
        Notes: "",
        Expires: "",
        ContentFileId: "",
    };

    const { ContentFileLinkCreate } = useSelector(
        (state) => state.ContentFileLinkCreate
    );
    const { ContentFileLinkDelete } = useSelector(
        (state) => state.ContentFileLinkDelete
    );

    const { ShareLink } = useSelector((state) => state.ShareLink);
    const { userListCollection } = useSelector(
        (state) => state.UserListCollection
    );

    const { ContentFileGetNode } = useSelector(
        (state) => state.ContentFileGetNode
    );

    const ContentFileGetNodeData = ContentFileGetNode?.length
        ? ContentFileGetNode?.[ContentFileGetNode?.length - 1]
        : ContentFileGetNode;

    let iconStyles = { color: "white", fontSize: "1.5em" };

    const dispatch = useDispatch();
    let location = useLocation();

    const [formData, setFormCreateChannel] = useState(defautFormData);
    const [urlLink, setUrlLink] = useState("");
    const [selectedShareType, setSelectedShareType] = useState("");
    const [isReuse, setIsReuse] = useState(false);
    const [socialType, setSocialType] = useState("");
    const [isClickedAfterSocial, setIsClickedAfterSocial] = useState(false);
    const [isToast, setIsToast] = useState(false);

    useEffect(() => {
        if (ContentFileLinkCreate?.ContentFileLink_Create?.ContentFileLink && !isClickAdd) {
            setUrlLink(ContentFileLinkCreate?.ContentFileLink_Create?.ContentFileLink?.ShortUrl);
            setIsClickUpdate(false);
            if (socialType === "linkShare" && !isToast) {
                setIsToast(true);
                toast.success("New managed link was created");
            }
            if (socialType === "" && selectedShareType !== "created" && selectedShareType !== "facebookShare" && selectedShareType !== "twitterShare" && selectedShareType !== "gmail" && selectedShareType !== "reuse again" && selectedShareType !== "reuse link" && selectedShareType !== "create new" && socialType !== "linkShare") {
                toast.success("New managed link was created");
                dispatch(ContentFileLinkCreateClear());
            }
            if (socialType === "" && selectedShareType !== "facebookShare" && selectedShareType !== "twitterShare" && selectedShareType !== "gmail" && selectedShareType !== "create new" && selectedShareType !== "reuse link" && selectedShareType !== "reuse again" && socialType !== "linkShare") {
                setSelectedShareType("created");
            }
        } else if (ContentFileLinkCreate?.ContentFileLink_Create?.ContentFileLink && isClickAdd) {
            if (isClickAdd && isDuplicate) {
                toast.success("Selected managed link was duplicated");
            } else {
                toast.success("New managed link was created");
            }
            dispatch(ContentFileLinkCreateClear());
            fetchContentFileLinkList("active", videoId);
            fetchContentFileLinkList("inactive", videoId);
            if (selectedShareType !== "facebookShare" && selectedShareType !== "twitterShare" && selectedShareType !== "gmail") {
                setIsClickAdd(false);
                setIsDuplicate(false);
            }
        }
    }, [ContentFileLinkCreate, isClickAdd, isDuplicate, setIsClickUpdate, selectedShareType]);

    useEffect(() => {
        if (ContentFileLinkDelete?.ContentFileLink_Delete?.["#text"] && isDelete) {
            toast.success("Selected managed link was deleted");
            dispatch(ContentFileLinkDeleteClear());
            fetchContentFileLinkList("active", videoId);
            fetchContentFileLinkList("inactive", videoId);
            setEditLinkData({});
            setIsClickAdd(false);
            setIsDelete(false);
        }
    }, [ContentFileLinkDelete, isDelete])

    useEffect(() => {
        if (origin === "EditVideoModal") {
            if (editLinkData && Object.keys(editLinkData)?.length) {
                const inputDateString = editLinkData?.Expires;
                const inputDate = new Date(inputDateString);

                setFormCreateChannel({
                    ...formData,
                    MaxViews: editLinkData?.MaxViews,
                    Expires: editLinkData?.Expires === "" ? "" : inputDate,
                    Notes: editLinkData?.Notes
                });
            } else {
                setFormCreateChannel(defautFormData);
            }
        }
    }, [editLinkData, origin]);

    useEffect(() => {
        if (ShareLink && ((selectedShareType && selectedShareType !== "create new") || isClickedAfterSocial) && (ContentFileLinkCreate && Object.keys(ContentFileLinkCreate)?.length)) {
            if (isClickedAfterSocial) {
                setIsClickedAfterSocial(false);
            }
            let name = ShareLink?.socialName;
            const { ShortUrl } = ContentFileLinkCreate?.ContentFileLink_Create?.ContentFileLink;
            let linkUrl = `${ShortUrl?.split(":")?.[0]}s:${ShortUrl?.split(":")?.[1]}`;

            const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                linkUrl
            )}`;

            let channelName = "";
            if (userListCollection?.length) {
                userListCollection?.forEach(item => {
                    if (item?.CollectionId === location.pathname?.split("/")?.[2]) {
                        channelName = item?.Name;
                    }
                })
            }
            if (name === "facebookShare") {
                window.open(
                    `https://www.facebook.com/dialog/feed?app_id=1060192688025761&caption=EQ%20Network&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3523a2d138e8e%26domain%3Deqnetwork.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Feqnetwork.com%252Ff1e933f47bccf74%26relation%3Dopener&display=popup&e2e=%7B%7D&link=${linkUrl}&locale=en_US&name=${channelName}&next=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df36515fc9c87694%26domain%3Deqnetwork.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Feqnetwork.com%252Ff1e933f47bccf74%26relation%3Dopener%26frame%3Df2bee87ba347944%26result%3D%2522xxRESULTTOKENxx%2522&sdk=joey&version=v15.0`,
                    "targetWindow",
                    `toolbar=no,
              location=no,
              status=no,
              menubar=no,
              scrollbars=yes,
              resizable=yes,
              width=500px,
              height=500px`
                );
            }
            if (name === "twitterShare") {
                window.open(
                    tweetUrl,
                    "targetWindow",
                    `toolbar=no,
              location=no,
              status=no,
              menubar=no,
              scrollbars=yes,
              resizable=yes,
              width=500px,
              height=500px`
                );
            }
            if (name === "gmail") {
                window.location = `mailto:?subject=Check out this video:${ContentFileGetNodeData?.Name} &body=Take a look at this video from the EQ Network.%0D%0A %0D%0A${linkUrl}%0D%0A %0D%0AThe new EQ Network is the worlds first video site that automatically synchronizes both private and public video channels to all your devices, enabling an optimized video viewing experience for all. %0D%0A %0D%0ADownload the EQ Network iPhone and iPad app at http://eqn.tv/ios. %0D%0A %0D%0A`;
            }
        }
    }, [ShareLink, ContentFileLinkCreate, isClickedAfterSocial, ContentFileGetNodeData]);

    const handleFormData = (value, name) => {
        // Check if the value is a date object with time set to 00:00:00
        if (value instanceof Date && value.getHours() === 0 && value.getMinutes() === 0 && value.getSeconds() === 0) {
            // Update the time to the current time
            value.setHours(new Date().getHours());
            value.setMinutes(new Date().getMinutes());
            value.setSeconds(new Date().getSeconds());
        }

        // Update the form data
        setFormCreateChannel({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e, type = "") => {
        e.preventDefault();
        // Parse the original date using Moment.js
        const originalDate = moment(formData?.Expires);

        // Format the date in the desired format
        let formattedDateTime = originalDate.format("YYYY-MM-DDTHH:mm:ss.SSS");
        if (formData?.Expires === "") {
            formattedDateTime = "";
        }

        if (isNaN(formData?.MaxViews) || formData?.MaxViews > 2147483647) {
            // Display an error message or handle it as needed
            toast.warn('The number of views was too large');
            // You can return the current value or another default value as per your requirements
            setFormCreateChannel({
                ...formData,
                MaxViews: 2147483647
            });
        } else if (
            ((formData?.MaxViews === "" && formData?.Expires === "" && formData?.Notes === "")
                || (formData?.MaxViews ||
                    formData?.Expires !== "" ||
                    formData?.Notes !== ""))
            || type === "social"
            || type === "link"
        ) {
            if (Object.keys(editLinkData)?.length && !isDuplicate) {
                setIsClickUpdate(true);
                delete formData?.ContentFileId;
                dispatch(
                    ContentFileLinkUpdateAction(
                        jsonToFormData({
                            ...formData,
                            Client,
                            SessionGuid,
                            Active: editLinkData?.Active === "True" ? "Y" : "N",
                            MaxViews: formData?.MaxViews,
                            Notes: formData?.Notes,
                            Expires: formattedDateTime,
                            ContentFileLinkId: editLinkData?.ContentFileLinkId
                        })
                    )
                );
            } else {
                let body = {
                    ...formData,
                    Client,
                    SessionGuid,
                    Active: "Y",
                    Expires: formattedDateTime,
                    ContentFileId: videoId
                }
                if (Object.keys(editLinkData)?.length && isDuplicate) {
                    body = {
                        ...formData,
                        Client,
                        SessionGuid,
                        Active: editLinkData?.Active === "True" ? "Y" : "N",
                        MaxViews: formData?.MaxViews,
                        Notes: formData?.Notes,
                        Expires: formattedDateTime,
                        ContentFileId: videoId
                    }
                }
                dispatch(ContentFileLinkCreateAction(jsonToFormData(body)));
            }
        }
    }

    const gmailHandler = async (e, name = "", socialTypeVal = "") => {
        setIsClickAdd(true);
        setSelectedShareType(name);
        if (name === "reuse again" && socialTypeVal !== "") {
            setSocialType(socialTypeVal);
        }
        if (name !== "" && name !== "reuse again" && name !== "facebookShare" && name !== "twitterShare" && name !== "gmail" && socialTypeVal === "") {
            handleSubmit(e, "social");
        }
        if ((name === "" && ((socialTypeVal === "facebookShare" || socialTypeVal === "twitterShare" || socialTypeVal === "gmail"))
            || (name === "facebookShare" || name === "twitterShare" || name === "gmail"))) {
            if (urlLink === "") {
                handleSubmit(e, "social");
            }
            //  for devlopment
            if (window.location.href) {
                let contentGetNode = {
                    Client: Client,
                    RealUrl: window.location.href,
                };

                dispatch(ShareLinkAction(jsonToFormData(contentGetNode), name === "" && (socialTypeVal === "facebookShare" || socialTypeVal === "twitterShare" || socialTypeVal === "gmail") ? socialTypeVal : name));
            }
        } else {
            if (name === "create new") {
                setUrlLink("");
            } else {
                setIsReuse(true);
            }
        }
    };

    const handleDelete = () => {
        let body = {
            Client,
            SessionGuid,
            ContentFileLinkId: editLinkData?.ContentFileLinkId
        }
        dispatch(ContentFileLinkDeleteAction(jsonToFormData(body)));
    }

    const handleClearAllData = () => {
        setFormCreateChannel(defautFormData);
        setUrlLink("");
        setSelectedShareType("");
        setIsReuse(false);
        setSocialType("");
        setIsClickedAfterSocial(false);
        setIsToast(false);
        dispatch(ContentFileLinkCreateClear());
        dispatch(ShareLinkClearAction());
    }

    const handleDisplayForm = () => {
        if (isDelete) {
            return <div>
                <div style={{ color: "#ffffff" }}>Are you sure you want to delete this link?</div>
                <div className='d-flex align-items-end justify-content-end mt-5'>
                    <Button
                        className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                        onClick={isDelete
                            ? () => { setIsDelete(false); setIsClickAdd(false) }
                            : () => { }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        className="btn eq_button my-2 mx-2 text-white pointer"
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        } else {
            return <form
                encType="multipart/form-data"
                onSubmit={(e) => handleSubmit(e)}
                className="managed-link-form"
                novalidate
            >
                <div className="mt-2" style={{ color: "#ffffff", width: "100%", marginLeft: "10px" }}>
                    <h5>Max Views:</h5>
                    <input
                        name="MaxViews"
                        type="number"
                        style={{ width: "96%" }}
                        className="form-control"
                        value={formData?.MaxViews}
                        onChange={(e) => handleFormData(e.target.value, e.target.name)}
                        placeholder="Unlimited"
                    />
                </div>
                <div className="mt-2" style={{ color: "#ffffff", width: "100%", marginLeft: "10px", position: "relative" }}>
                    <h5>Expires:</h5>
                    <DatePicker
                        id="session-date"
                        selected={formData.Expires}
                        onChange={date => handleFormData(date, "Expires")}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="form-control"
                        placeholderText="Never"
                        minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)} // Set minimum date to the next day
                        minTime={new Date().setHours(new Date().getHours(), new Date().getMinutes() >= 30 ? 30 : 0, 0, 0)} // Set minimum time to 30 minutes before the current time
                        maxTime={new Date().setHours(23, 30, 0, 0)} // Assuming the maximum time is set to 23:30
                        autoComplete="off" // Add this line to disable autocomplete
                    />
                </div>
                <div className="mt-2" style={{ color: "#ffffff", width: "100%", marginLeft: "10px" }}>
                    <h5>Notes:</h5>
                    <textarea
                        name="Notes"
                        rows="3"
                        style={{ width: "96%" }}
                        className='form-control'
                        value={formData?.Notes}
                        onChange={(e) => handleFormData(e.target.value, e.target.name)}
                    ></textarea>
                </div>

                <div className="d-flex align-items-center mt-5" style={{ color: "#ffffff", width: "100%", marginLeft: "10px" }}>
                    <h5>Share:</h5>
                    <div className="d-flex justify-content-start align-items-center" style={{ marginLeft: "10px" }}>
                        <div className="sm-2 mx-1 d-flex justify-content-start align-items-center ">
                            <div
                                className="d-flex justify-content-start align-items-center pointer"
                                onClick={(e) =>
                                    urlLink !== ""
                                        ? gmailHandler(e, "reuse again", "facebookShare")
                                        : gmailHandler(e, "facebookShare", "facebookShare")
                                }
                            >
                                <div
                                    style={{
                                        background: "#3B5998",
                                        padding: "5px 7px"
                                    }}
                                    className="rounded-circle"
                                >
                                    <FaFacebookF
                                        style={iconStyles}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm-2 mx-1 d-flex justify-content-start align-items-center ">
                            <div
                                className="d-flex justify-content-start align-items-center pointer"
                                onClick={(e) =>
                                    urlLink !== ""
                                        ? gmailHandler(e, "reuse again", "twitterShare")
                                        : gmailHandler(e, "twitterShare", "twitterShare")
                                }
                            >
                                <div
                                    style={{
                                        background: "#1da1f2",
                                        padding: "5px 7px"
                                    }}
                                    className="rounded-circle"
                                >
                                    <FaTwitter style={iconStyles} />
                                </div>
                            </div>
                        </div>

                        <div className="sm-2 mx-1 d-flex justify-content-start align-items-center ">
                            <div className="d-flex justify-content-start align-items-center pointer">
                                <div
                                    onClick={(e) =>
                                        urlLink !== ""
                                            ? gmailHandler(e, "reuse again", "gmail")
                                            : gmailHandler(e, "gmail", "gmail")
                                    }
                                    className="text-decoration-none d-flex justify-content-center align-items-center"
                                >
                                    <div
                                        style={{
                                            background: "#888888",
                                            padding: "5px 7px"
                                        }}
                                        className="rounded-circle"
                                    >
                                        <MdMail
                                            style={iconStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isClickAdd
                            ? <div className="sm-2 mx-1 d-flex justify-content-start align-items-center ">
                                <div className="d-flex justify-content-start align-items-center pointer">
                                    <div
                                        className="align-items-center pointer "
                                        onClick={
                                            (urlLink !== "" && selectedShareType === "") || selectedShareType === "facebookShare" || selectedShareType === "twitterShare" || selectedShareType === "gmail" || selectedShareType === "reuse again"
                                                || socialType === "facebookShare" || socialType === "twitterShare" || socialType === "gmail"
                                                ? (e) => { setSocialType("linkShare"); urlLink !== "" ? setSelectedShareType("reuse again") : setSelectedShareType("reuse link") }
                                                : (e) => { setSocialType("linkShare"); handleSubmit(e, "social"); urlLink !== "" ? setSelectedShareType("reuse again") : setSelectedShareType("reuse link") }
                                        }
                                    >
                                        <div className="manageLinkIcon">
                                            <i className="fs-6">
                                                <FaLink />
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
                {selectedShareType !== "" || socialType !== "" || isReuse
                    ? <div className="d-flex align-items-end justify-content-end mt-5">
                        <Button
                            className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                            data-bs-dismiss={!isClickAdd ? "modal" : ""}
                            aria-label={!isClickAdd ? "Close" : ""}
                            onClick={
                                origin === "EditVideoModal"
                                    ? () => { handleClearEditModalData(); handleClearAllData() }
                                    : () => { handleClearAllData() }
                            }
                        >
                            Done
                        </Button>
                    </div>
                    : <div className='d-flex align-items-end justify-content-end mt-5'>
                        <Button
                            className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                            data-bs-dismiss={!isClickAdd ? "modal" : ""}
                            aria-label={!isClickAdd ? "Close" : ""}
                            onClick={
                                isClickAdd && Object.keys(editLinkData)?.length && isDuplicate
                                    ? () => { setIsClickAdd(false); setEditLinkData({}); setIsDuplicate(false) }
                                    : isClickAdd && Object.keys(editLinkData)?.length && !isDuplicate
                                        ? () => { setIsClickAdd(false); setEditLinkData({}) }
                                        : isClickAdd
                                            ? () => setIsClickAdd(false)
                                            : Object.keys(editLinkData)?.length
                                                ? setEditLinkData({})
                                                : () => { setFormCreateChannel(defautFormData) }
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="btn eq_button my-2 mx-2 text-white pointer"
                        >
                            {Object.keys(editLinkData)?.length && !isDuplicate ? "Update" : "Save"}
                        </Button>
                    </div>
                }
            </form >
        }
    }

    if (isClickAdd) {
        return handleDisplayForm();
    }

    return (
        <div
            className="modal fade"
            id="manageLinkModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                style={{ maxWidth: "45rem", width: "45rem" }}
                className="modal-dialog modal-dialog-centered modal-md"
            >
                <div className="modal-content radius_none eq_grey">
                    <div className="modal-header">
                        <h5 className="modal-title  text-white" id="exampleModalLabel">
                            {selectedShareType === "reuse again"
                                ? "EQ Network"
                                : urlLink && (selectedShareType === "created" || selectedShareType === "reuse link" || selectedShareType === "create new")
                                    ? "Managed Link"
                                    : "Create Managed Link"}
                        </h5>
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Grey_close_x.svg/1024px-Grey_close_x.svg.png"
                            style={{ width: "20px", height: "20px", cursor: "pointer" }}
                            data-bs-dismiss={selectedShareType !== "reuse link" && selectedShareType !== "create new" && selectedShareType !== "reuse again" ? "modal" : ""}
                            aria-label={selectedShareType !== "reuse link" && selectedShareType !== "create new" && selectedShareType !== "reuse again" ? "Close" : ""}
                            onClick={
                                (selectedShareType === "create new" || selectedShareType === "reuse again") && urlLink
                                    ? () => { setSelectedShareType(""); setUrlLink("") }
                                    : selectedShareType === "reuse link" && urlLink && isReuse
                                        ? () => setSelectedShareType("")
                                        : selectedShareType === "reuse link" && urlLink && !isReuse
                                            ? () => setSelectedShareType("")
                                            : urlLink
                                                ? () => handleClearAllData()
                                                : formData && Object.keys(formData)?.length
                                                    ? () => setFormCreateChannel(defautFormData)
                                                    : () => handleClearAllData()
                            }
                        />
                    </div>
                    <div className="modal-body">
                        {selectedShareType === "reuse again"
                            ? <div>
                                <div style={{ color: "#ffffff" }}>Would you like to reuse the current link or create a new one?</div>
                                <div className='d-flex align-items-end justify-content-end mt-5'>
                                    <Button
                                        className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                        onClick={
                                            socialType === "facebookShare" || socialType === "twitterShare" || socialType === "gmail"
                                                ? (e) => { gmailHandler(e, "", socialType); setIsClickedAfterSocial(true) }
                                                : () => setSelectedShareType("reuse link")
                                        }
                                    >
                                        Reuse Link
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="btn eq_button my-2 mx-2 text-white pointer"
                                        onClick={
                                            socialType === "facebookShare" || socialType === "twitterShare" || socialType === "gmail"
                                                ? (e) => { gmailHandler(e, "", socialType); handleSubmit(e, "social"); setSelectedShareType(socialType); setUrlLink("") }
                                                : (e) => { gmailHandler(e, "create new"); setIsToast(false) }
                                        }
                                    >
                                        Create New
                                    </Button>
                                </div>
                            </div>
                            : selectedShareType === "reuse link" || (urlLink && (selectedShareType === "created" || selectedShareType === "reuse link" || selectedShareType === "create new"))
                                ? <div>
                                    {!urlLink
                                        ? <>
                                            <div class="text-center d-flex justify-content-center align-items-center mt-2">
                                                <div
                                                    class="spinner-border text-white"
                                                    role="status"
                                                >
                                                    <span class="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                        : <textarea rows="1" cols="50" style={{ width: "100%" }}>
                                            {`${urlLink?.split(":")?.[0]}s:${urlLink?.split(":")?.[1]}`}
                                        </textarea>
                                    }
                                    <div className="modal-footer">
                                        <Button
                                            className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                            data-bs-dismiss={selectedShareType !== "reuse link" && selectedShareType !== "create new" ? "modal" : ""}
                                            aria-label={selectedShareType !== "reuse link" && selectedShareType !== "create new" ? "Close" : ""}
                                            onClick={
                                                selectedShareType === "created" && urlLink
                                                    ? () => handleClearAllData()
                                                    : selectedShareType === "create new" && urlLink
                                                        ? () => { setSelectedShareType(""); }
                                                        : selectedShareType === "reuse link" && urlLink && isReuse
                                                            ? () => { setSelectedShareType(""); }
                                                            : selectedShareType === "reuse link" && urlLink && !isReuse
                                                                ? () => { setSelectedShareType(""); }
                                                                : urlLink
                                                                    ? () => { setSelectedShareType(""); setFormCreateChannel({}) }
                                                                    : () => { }
                                            }
                                        >
                                            Ok
                                        </Button>
                                    </div>
                                </div>
                                : <div className="manage-link-modal">
                                    <div>{handleDisplayForm()}</div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManagedLinksModal;
