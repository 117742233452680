export const endPoint = {
  LOGIN: "CreateSession.aspx",
  SIGINUP: "CreateUser.aspx",
  USER_GET_NODE: "Api2.0/User_GetNode.aspx",
  USER_LIST_COLLECTION: "Api2.0/User_ListCollections.aspx",
  USER_EMAIL_ACCOUNTS: "User_EmailAccounts.aspx",
  USER_REQUEST_PASSWORD_RESET: "User_RequestPasswordReset.aspx",
  SEARCH_CONTENT_FILES: "Api2.0/Search_ContentFiles.aspx",
  HASHTAG_TRENDING: "Api2.0/Hashtag_Trending.aspx",
  USER_UPDATE: "Api2.0/User_Update.aspx",
  COLLECTION_LISTCONTENT_FILES: "Api2.0/Collection_ListContentFiles.aspx",
  FOLLOW_COLLECTION: "/FollowCollection.aspx",
  REMOVE_COLLECTION: "RemoveCollection.aspx",
  COLLECTION_GETNODE: "Api2.0/Collection_GetNode.aspx",
  CONTENT_FILE_GETNODE: "Api2.0/ContentFile_GetNode.aspx",
  CONTENT_FILE_SOCIAL_ADD: "Api2.0/ContentFile_SocialAdd.aspx",
  CONTENT_FILE_SOCIAL_REMOVE: "Api2.0/ContentFile_SocialRemove.aspx",
  POST_LIST_FOR_CONTENTFILE: "Post_ListForContentFile.aspx",
  POST_CREATE: "Post_Create.aspx",
  POST_DELETE: "Post_Delete.aspx",
  POST_LIST_CATEGORIES: "ListCategories.aspx",
  SEARCH_COLLECTION_CONTENT_FILES:
    "Api2.0/Search_CollectionsAndContentFiles.aspx",
  USER_DELETE: "Api2.0/User_Delete.aspx",
  COLLECTION_CREATE: "/Api2.0/Collection_Create.aspx",
  CREATE_CONTENT_FILE: "CreateContentFile.aspx",
  UPDATE_CONTENT_FILE: "UpdateContentFile.aspx",
  DELETE_CONTENT_FILE: "/Api2.0/ContentFile_Delete2.aspx",
  USER_ADDRELATIONSHIP: "Api2.0/User_AddRelationship.aspx",
  CONTENT_FILE_VIEWED: "ContentFileViewed.aspx",
  COLLECTION_UPDATE: "Api2.0/Collection_Update.aspx",
  COLLECTION_DELETE: "Api2.0/Collection_Delete.aspx",
  SHARE_LINK: "eqntv/CreateUrl.aspx",
  CREATE_FROM_FACEBOOK: "Api2.0/Session_CreateFromFacebook.aspx",
  CHANGE_CONTENT_FILE_COLLECTION: "ChangeContentFileCollection.aspx",
  RESEND_CONFIRMATION_EMAIL: "ResendConfirmationEmail.aspx",
  USER_LIST_USERS: "/Api2.0/User_ListUsers.aspx",
  INVITAION_CREATE: "/Api2.0/Invitation_Create.aspx",
  INVITAION_RESEND: "/Api2.0/Invitation_Resend.aspx",
  INVITAION_DELETE: "/Api2.0/Invitation_Delete.aspx",
  UPLOAD_VIDEO_CANCEL: "/Api2.0/Upload2_CancelUpload.aspx",
  UPLOAD_VIDEO_BEGIN: "/Api2.0/Upload2_Begin.aspx",
  COLLECTION_SOCIAL_ADD: "/Api2.0/Collection_SocialAdd.aspx",
  FLAG_AS_INAPPROPRIATE: "/sd/ContentFile_FlagAsInappropriate.aspx",
  USER_ACCEPT_AGREEMENT: "/Api2.0/User_AcceptAgreement.aspx",
  REQUEST_GET_COLLECTION: "/Api2.0/Request_GetCollectionRequestGuid.aspx",
  REQUEST_LIST: "/Api2.0/Request_ListRequests.aspx",
  REQUEST_DECLINE: "/Api2.0/Request_Decline.aspx",
  REQUEST_ACCEPT: "/Api2.0/Request_Accept.aspx",
  REQUEST_REMOVE: "/Api2.0/Request_Delete.aspx",
  INVITE_LIST: "/Api2.0/Invitation_ListInvitations.aspx",
  INVITE_UPDATE: "/Api2.0/Invitation_Update.aspx",
  INVITATION_LIST_TO_ME: "/Api2.0/Invitation_ListInvitationsToMe.aspx",
  INVITATION_CONFIRM: "/Api2.0/Invitation_Confirm.aspx",
  INVITATION_ACCEPT: "/Api2.0/Invitation_Accept.aspx",
  INVITATION_DECLINE: "/Api2.0/Invitation_Decline.aspx",
  REQUEST_CREATE: "/Api2.0/Request_Create.aspx",
  CONTENT_FILE_LINK_CREATE: "/Api2.0/ContentFileLink_Create.aspx",
  CONTENT_FILE_LINKS: "/Api2.0/ContentFile_ListContentFileLinks.aspx",
  CONTENT_FILE_LINKS_UPDATE: "/Api2.0/ContentFileLink_Update.aspx",
  CONTENT_FILE_LINKS_DELETE: "/Api2.0/ContentFileLink_Delete.aspx",
  COLLECTION_ADD_RELATIONSHIP: "/Api2.0/Collection_AddRelationship.aspx",
  CREATE_FROM_APPLE: "Api2.0/Session_CreateFromApple.aspx",
};
