import { icons } from "react-icons";

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const SIGNIN_RESET = "SIGNIN_RESET";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_RESET = "SIGNUP_RESET";
export const SET_LOGGED_IN = "SET_LOGGED_IN";

// social Login

export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAIL = "SOCIAL_LOGIN_FAIL";

export const USER_LIST_COLLECTION_REQUEST = "USER_LIST_COLLECTION_REQUEST";
export const USER_LIST_COLLECTION_SUCCESS = "USER_LIST_COLLECTION_SUCCESS";
export const USER_LIST_COLLECTION_FAIL = "USER_LIST_COLLECTION_FAIL";
export const USER_LIST_COLLECTION_RESET = "USER_LIST_COLLECTION_RESET";
export const USER_LIST_COLLECTION_RESPONSE = "USER_LIST_COLLECTION_RESPONSE";

export const COLLECTION_CREATE_REQUEST = "COLLECTION_CREATE_REQUEST";
export const COLLECTION_CREATE_SUCCESS = "COLLECTION_CREATE_SUCCESS";
export const COLLECTION_CREATE_FAIL = "COLLECTION_CREATE_FAIL";

// FORGOT USER NAME & PASSWORD
export const HOME_SEARCH_CONTENT_FILE_REQUEST =
  "HOME_SEARCH_CONTENT_FILE_REQUEST";
export const HOME_SEARCH_CONTENT_FILE_SUCCESS =
  "HOME_SEARCH_CONTENT_FILE_SUCCESS";
export const HOME_SEARCH_CONTENT_FILE_FAIL = "HOME_SEARCH_CONTENT_FILE_FAIL";
export const HOME_SEARCH_CONTENT_FILE_RESET = "HOME_SEARCH_CONTENT_FILE_RESET";

// FORGOT USER NAME & PASSWORD
export const USER_EMAIL_ACCOUNTS_REQUEST = "USER_EMAIL_ACCOUNTS_REQUEST";
export const USER_EMAIL_ACCOUNTS_SUCCESS = "USER_EMAIL_ACCOUNTS_SUCCESS";
export const USER_EMAIL_ACCOUNTS_FAIL = "USER_EMAIL_ACCOUNTS_FAIL";
export const FORGOT_USERNAME_RESPONSE_CLEARE =
  "FORGOT_USERNAME_RESPONSE_CLEARE";

export const USER_REQUEST_PASSWORD_REQUEST = "USER_REQUEST_PASSWORD_REQUEST";
export const USER_REQUEST_PASSWORD_SUCCESS = "USER_REQUEST_PASSWORD_SUCCESS";
export const USER_REQUEST_PASSWORD_FAIL = "USER_REQUEST_PASSWORD_FAIL";
export const FORGOT_PASSWORD_RESPONSE_CLEARE =
  "FORGOT_PASSWORD_RESPONSE_CLEARE";

// SEARCH_CONTENT_FILES_REQUEST
export const SEARCH_CONTENT_FILES_REQUEST = "SEARCH_CONTENT_FILES_REQUEST";
export const SEARCH_CONTENT_FILES_SUCCESS = "SEARCH_CONTENT_FILES_SUCCESS";
export const SEARCH_CONTENT_FILES_FAIL = "SEARCH_CONTENT_FILES_FAIL";
export const SEARCH_CONTENT_FILES_RESPONSE_CLEARE =
  "SEARCH_CONTENT_FILES_RESPONSE_CLEARE ";

export const ADD_REMOVE_RELATIONSHIP_REQUEST =
  "ADD_REMOVE_RELATIONSHIP_REQUEST";
export const ADD_REMOVE_RELATIONSHIP_SUCCESS =
  "ADD_REMOVE_RELATIONSHIP_SUCCESS";
export const ADD_REMOVE_RELATIONSHIP_FAIL = "ADD_REMOVE_RELATIONSHIP_FAIL";

export const SEARCH_CONTENT_FILES_CONDITION = "SEARCH_CONTENT_FILES_CONDITION";

// TRENDING_LIST
export const HASHTAG_TRENDING_REQUEST = "HASHTAG_TRENDING_REQUEST";
export const HASHTAG_TRENDING_SUCCESS = "HASHTAG_TRENDING_SUCCESS";
export const HASHTAG_TRENDING_FAIL = "HASHTAG_TRENDING_FAIL";

// USER_GET_NODE
export const USER_GET_NODE_REQUEST = "USER_GET_NODE_REQUEST";
export const USER_GET_NODE_SUCCESS = "USER_GET_NODE_SUCCESS";
export const USER_GET_NODE_FAIL = "USER_GET_NODE_FAIL";
export const USER_GET_NODE_CLEAR = "USER_GET_NODE_CLEAR";

// video details GET NODE
export const CONTENT_FILE_GET_NODE_REQUEST = "CONTENT_FILE_GET_NODE_REQUEST";
export const CONTENT_FILE_GET_NODE_SUCCESS = "CONTENT_FILE_GET_NODE_SUCCESS";
export const CONTENT_FILE_GET_NODE_FAIL = "CONTENT_FILE_GET_NODE_FAIL";
export const CONTENT_FILE_CREATE_REQUEST = "CONTENT_FILE_CREATE_REQUEST";
export const CONTENT_FILE_CREATE_SUCCESS = "CONTENT_FILE_CREATE_SUCCESS";
export const CONTENT_FILE_CREATE_FAIL = "CONTENT_FILE_CREATE_FAIL";
export const CONTENT_FILE_UPDATE_REQUEST = "CONTENT_FILE_UPDATE_REQUEST";
export const CONTENT_FILE_UPDATE_SUCCESS = "CONTENT_FILE_UPDATE_SUCCESS";
export const CONTENT_FILE_UPDATE_FAIL = "CONTENT_FILE_UPDATE_FAIL";
export const CONTENT_FILE_DELETE_REQUEST = "CONTENT_FILE_DELETE_REQUEST";
export const CONTENT_FILE_DELETE_SUCCESS = "CONTENT_FILE_DELETE_SUCCESS";
export const CONTENT_FILE_DELETE_FAIL = "CONTENT_FILE_DELETE_FAIL";
export const CONTENT_FILE_DELETE_CLEAR = "CONTENT_FILE_DELETE_CLEAR";

export const CONTENT_FILE_VIEWED_REQUEST = "CONTENT_FILE_VIEWED_REQUEST";
export const CONTENT_FILE_VIEWED_SUCCESS = "CONTENT_FILE_VIEWED_SUCCESS";
export const CONTENT_FILE_VIEWED_FAIL = "CONTENT_FILE_VIEWED_FAIL";

export const CHANGE_CONTENT_FILE_REQUEST = "CONTENT_FILE_VIEWED_REQUEST";
export const CHANGE_CONTENT_FILE_SUCCESS = "CHANGE_CONTENT_FILE_SUCCESS";
export const CHANGE_CONTENT_FILE_FAIL = "CHANGE_CONTENT_FILE_FAIL";

// USER_UPDATE profile / DELETE PROFILE
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESPONSE_REMOVE = "USER_UPDATE_RESPONSE_REMOVE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

// channel Home
export const COLLECTION_LISTCONTENT_FILES_REQUEST =
  "COLLECTION_LISTCONTENT_FILES_REQUEST";
export const COLLECTION_LISTCONTENT_FILES_SUCCESS =
  "COLLECTION_LISTCONTENT_FILES_SUCCESS";
export const COLLECTION_LISTCONTENT_FILES_FAIL =
  "COLLECTION_LISTCONTENT_FILES_FAIL";
export const COLLECTION_LISTCONTENT_RESPONSE_CLEARE =
  "COLLECTION_LISTCONTENT_RESPONSE_CLEARE";

export const COLLECTION_GETNODE_REQUEST = "COLLECTION_GETNODE_REQUEST";
export const COLLECTION_GETNODE_SUCCESS = "COLLECTION_GETNODE_SUCCESS";
export const COLLECTION_GETNODE_FAIL = "COLLECTION_GETNODE_FAIL";

// Subscribe
export const FOLLOW_COLLECTION_REQUEST = "FOLLOW_COLLECTION_REQUEST";
export const FOLLOW_COLLECTION_SUCCESS = "FOLLOW_COLLECTION_SUCCESS";
export const FOLLOW_COLLECTION_FAIL = "FOLLOW_COLLECTION_FAIL";
export const COLLECTION_CREATE_RESPONSE = "COLLECTION_CREATE_RESPONSE";

export const REMOVE_COLLECTION_REQUEST = "REMOVE_COLLECTION_REQUEST";
export const REMOVE_COLLECTION_SUCCESS = "REMOVE_COLLECTION_SUCCESS";
export const REMOVE_COLLECTION_FAIL = "REMOVE_COLLECTION_FAIL";

// social icons

export const CONTENT_FILE_SOCIAL_ADD_REQUEST =
  "CONTENT_FILE_SOCIAL_ADD_REQUEST";
export const CONTENT_FILE_SOCIAL_ADD_SUCCESS =
  "CONTENT_FILE_SOCIAL_ADD_SUCCESS";
export const CONTENT_FILE_SOCIAL_ADD_FAIL = "CONTENT_FILE_SOCIAL_ADD_FAIL";

export const CONTENT_FILE_SOCIAL_REMOVE_REQUEST =
  "CONTENT_FILE_SOCIAL_REMOVE_REQUEST";
export const CONTENT_FILE_SOCIAL_REMOVE_SUCCESS =
  "CONTENT_FILE_SOCIAL_REMOVE_SUCCESS";
export const CONTENT_FILE_SOCIAL_REMOVE_FAIL =
  "CONTENT_FILE_SOCIAL_REMOVE_FAIL";

// comment section

export const POST_LIST_FOR_CONTENTFILE_REQUEST =
  "POST_LIST_FOR_CONTENTFILE_REQUEST";
export const POST_LIST_FOR_CONTENTFILE_SUCCESS =
  "POST_LIST_FOR_CONTENTFILE_SUCCESS";
export const POST_LIST_FOR_CONTENTFILE_FAIL = "POST_LIST_FOR_CONTENTFILE_FAIL";
export const POST_LIST_SORT = "POST_LIST_SORT";
// add Comment & Delete

export const POST_CREATE_REQUEST = "POST_CREATE_REQUEST";
export const POST_CREATE_SUCCESS = "POST_CREATE_SUCCESS";
export const POST_CREATE_FAIL = "POST_CREATE_FAIL";

export const POST_DELETE_REQUEST = "POST_DELETE_REQUEST";
export const POST_DELETE_SUCCESS = "POST_DELETE_SUCCESS";
export const POST_DELETE_FAIL = "POST_DELETE_FAIL";
//Filter Categaries
export const LIST_CATEGORIES_REQUEST = "LIST_CATEGORIES_REQUEST";
export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";
export const LIST_CATEGORIES_FAIL = "LIST_CATEGORIES_SUCCESS";
//search  collection conttent files
export const SEARCH_COLLECTION_FILES_REQUEST =
  "SEARCH_COLLECTION_FILES_REQUEST";
export const SEARCH_COLLECTION_FILES_SUCCESS =
  "SEARCH_COLLECTION_FILES_SUCCESS";
export const SEARCH_COLLECTION_FILES_FAIL = "SEARCH_COLLECTION_FILES_SUCCESS";

export const COLLECTION_UPDATE_REQUEST = "COLLECTION_UPDATE_REQUEST";
export const COLLECTION_UPDATE_SUCCESS = "COLLECTION_UPDATE_SUCCESS";
export const COLLECTION_UPDATE_FAIL = "COLLECTION_UPDATE_FAIL";
export const COLLECTION_UPDATE_RESPONSE = "COLLECTION_UPDATE_RESPONSE";

// channelDelete
export const COLLECTION_DELETE_REQUEST = "COLLECTION_UPDATE_REQUEST";
export const COLLECTION_DELETE_SUCCESS = "COLLECTION_UPDATE_SUCCESS";
export const COLLECTION_DELETE_FAIL = "COLLECTION_UPDATE_FAIL";
export const COLLECTION_DELETE_RESPONSE = "COLLECTION_DELETE_RESPONSE";

//  link shortner
export const SHARE_LINK_REQUEST = "SHARE_LINK_REQUEST";
export const SHARE_LINK_SUCCESS = "SHARE_LINK_SUCCESS";
export const SHARE_LINK_FAIL = "SHARE_LINK_FAIL";
export const SHARE_LINK_CLEAR = "SHARE_LINK_CLEAR";

// Resend Validation Email
export const RESEND_CONFIRMATION_EMAIL_REQUEST = "RESEND_CONFIRMATION_EMAIL_REQUEST";
export const RESEND_CONFIRMATION_EMAIL_SUCCESS = "RESEND_CONFIRMATION_EMAIL_SUCCESS";
export const RESEND_CONFIRMATION_EMAIL_FAIL = "RESEND_CONFIRMATION_EMAIL_FAIL";

// User List Users
export const USER_LIST_USERS_REQUEST = "USER_LIST_USERS_REQUEST";
export const USER_LIST_USERS_SUCCESS = "USER_LIST_USERS_SUCCESS";
export const USER_LIST_USERS_FAIL = "USER_LIST_USERS_FAIL";

// Invitation create
export const INVITAION_CREATE_REQUEST = "INVITAION_CREATE_REQUEST";
export const INVITAION_CREATE_SUCCESS = "INVITAION_CREATE_SUCCESS";
export const INVITAION_CREATE_FAIL = "INVITAION_CREATE_FAIL";
export const INVITAION_CREATE_CLEAR = "INVITAION_CREATE_CLEAR";

// Invitation Resend
export const INVITAION_RESEND_REQUEST = "INVITAION_RESEND_REQUEST";
export const INVITAION_RESEND_SUCCESS = "INVITAION_RESEND_SUCCESS";
export const INVITAION_RESEND_FAIL = "INVITAION_RESEND_FAIL";
export const INVITAION_RESEND_CLEAR = "INVITAION_RESEND_CLEAR";

// Invitation Delete
export const INVITAION_DELETE_REQUEST = "INVITAION_DELETE_REQUEST";
export const INVITAION_DELETE_SUCCESS = "INVITAION_DELETE_SUCCESS";
export const INVITAION_DELETE_FAIL = "INVITAION_DELETE_FAIL";
export const INVITAION_DELETE_CLEAR = "INVITAION_DELETE_CLEAR";

// UPLOAD CANCEL
export const UPLOAD_VIDEO_CANCEL_REQUEST = "UPLOAD_VIDEO_CANCEL_REQUEST";
export const UPLOAD_VIDEO_CANCEL_SUCCESS = "UPLOAD_VIDEO_CANCEL_SUCCESS";
export const UPLOAD_VIDEO_CANCEL_FAIL = "UPLOAD_VIDEO_CANCEL_FAIL";

export const UPLOAD_VIDEO_COMPLETE_RESPONSE_CLEAR = "UPLOAD_VIDEO_COMPLETE_RESPONSE_CLEAR";
export const UPLOAD_VIDEO_CANCEL_RESPONSE_CLEAR = "UPLOAD_VIDEO_CANCEL_RESPONSE_CLEAR";

// SHARE CHANNEL
export const COLLECTION_SOCIAL_ADD_REQUEST = "COLLECTION_SOCIAL_ADD_REQUEST";
export const COLLECTION_SOCIAL_ADD_SUCCESS = "COLLECTION_SOCIAL_ADD_SUCCESS";
export const COLLECTION_SOCIAL_ADD_FAIL = "COLLECTION_SOCIAL_ADD_FAIL";

// Flag video
export const FLAG_VIDEO_REQUEST = "FLAG_VIDEO_REQUEST";
export const FLAG_VIDEO_SUCCESS = "FLAG_VIDEO_SUCCESS";
export const FLAG_VIDEO_FAIL = "FLAG_VIDEO_FAIL";
export const FLAG_VIDEO_CLEAR = "FLAG_VIDEO_CLEAR";

// Flag video
export const LIST_UPLOAD_REQUEST = "LIST_UPLOAD_REQUEST";
export const LIST_UPLOAD_SUCCESS = "LIST_UPLOAD_SUCCESS";
export const LIST_UPLOAD_FAIL = "LIST_UPLOAD_FAIL";
export const LIST_UPLOAD_CLEAR = "LIST_UPLOAD_CLEAR";

// User Accept Agreement
export const USER_ACCEPT_AGREEMENT_REQUEST = "USER_ACCEPT_AGREEMENT_REQUEST";
export const USER_ACCEPT_AGREEMENT_SUCCESS = "USER_ACCEPT_AGREEMENT_SUCCESS";
export const USER_ACCEPT_AGREEMENT_FAIL = "USER_ACCEPT_AGREEMENT_FAIL";

// Request to Follow/Contribute link
export const REQUEST_GET_COLLECTION_REQUEST = "REQUEST_GET_COLLECTION_REQUEST";
export const REQUEST_GET_COLLECTION_SUCCESS = "REQUEST_GET_COLLECTION_SUCCESS";
export const REQUEST_GET_COLLECTION_FAIL = "REQUEST_GET_COLLECTION_FAIL";
export const REQUEST_GET_COLLECTION_CLEAR = "REQUEST_GET_COLLECTION_CLEAR";

// Request List
export const REQUEST_LIST_REQUEST = "REQUEST_LIST_REQUEST";
export const REQUEST_LIST_SUCCESS = "REQUEST_LIST_SUCCESS";
export const REQUEST_LIST_FAIL = "REQUEST_LIST_FAIL";
export const REQUEST_LIST_CLEAR = "REQUEST_LIST_CLEAR";

// Invite List
export const INVITE_LIST_REQUEST = "INVITE_LIST_REQUEST";
export const INVITE_LIST_SUCCESS = "INVITE_LIST_SUCCESS";
export const INVITE_LIST_FAIL = "INVITE_LIST_FAIL";
export const INVITE_LIST_CLEAR = "INVITE_LIST_CLEAR";

// Invite Update
export const INVITE_UPDATE_REQUEST = "INVITE_UPDATE_REQUEST";
export const INVITE_UPDATE_SUCCESS = "INVITE_UPDATE_SUCCESS";
export const INVITE_UPDATE_FAIL = "INVITE_UPDATE_FAIL";
export const INVITE_UPDATE_CLEAR = "INVITE_UPDATE_CLEAR";

// Request Decline
export const REQUEST_DECLINE_REQUEST = "REQUEST_DECLINE_REQUEST";
export const REQUEST_DECLINE_SUCCESS = "REQUEST_DECLINE_SUCCESS";
export const REQUEST_DECLINE_FAIL = "REQUEST_DECLINE_FAIL";
export const REQUEST_DECLINE_CLEAR = "REQUEST_DECLINE_CLEAR";

// Request Accept
export const REQUEST_ACCEPT_REQUEST = "REQUEST_ACCEPT_REQUEST";
export const REQUEST_ACCEPT_SUCCESS = "REQUEST_ACCEPT_SUCCESS";
export const REQUEST_ACCEPT_FAIL = "REQUEST_ACCEPT_FAIL";
export const REQUEST_ACCEPT_CLEAR = "REQUEST_ACCEPT_CLEAR";

// Request Remove
export const REQUEST_REMOVE_REQUEST = "REQUEST_REMOVE_REQUEST";
export const REQUEST_REMOVE_SUCCESS = "REQUEST_REMOVE_SUCCESS";
export const REQUEST_REMOVE_FAIL = "REQUEST_REMOVE_FAIL";
export const REQUEST_REMOVE_CLEAR = "REQUEST_REMOVE_CLEAR";

// Invitation for the current user
export const INVITATION_LIST_TO_ME_REQUEST = "INVITATION_LIST_TO_ME_REQUEST";
export const INVITATION_LIST_TO_ME_SUCCESS = "INVITATION_LIST_TO_ME_SUCCESS";
export const INVITATION_LIST_TO_ME_FAIL = "INVITATION_LIST_TO_ME_FAIL";
export const INVITATION_LIST_TO_ME_CLEAR = "INVITATION_LIST_TO_ME_CLEAR";

// Invitation Confirmation
export const INVITATION_CONFIRM_REQUEST = "INVITATION_CONFIRM_REQUEST";
export const INVITATION_CONFIRM_SUCCESS = "INVITATION_CONFIRM_SUCCESS";
export const INVITATION_CONFIRM_FAIL = "INVITATION_CONFIRM_FAIL";

// Invitation Accept
export const INVITATION_ACCEPT_REQUEST = "INVITATION_ACCEPT_REQUEST";
export const INVITATION_ACCEPT_SUCCESS = "INVITATION_ACCEPT_SUCCESS";
export const INVITATION_ACCEPT_FAIL = "INVITATION_ACCEPT_FAIL";
export const INVITATION_ACCEPT_CLEAR = "INVITATION_ACCEPT_CLEAR";

// Invitation Decline
export const INVITATION_DECLINE_REQUEST = "INVITATION_DECLINE_REQUEST";
export const INVITATION_DECLINE_SUCCESS = "INVITATION_DECLINE_SUCCESS";
export const INVITATION_DECLINE_FAIL = "INVITATION_DECLINE_FAIL";
export const INVITATION_DECLINE_CLEAR = "INVITATION_DECLINE_CLEAR";

// Invitation Decline
export const REQUEST_CREATE_REQUEST = "REQUEST_CREATE_REQUEST";
export const REQUEST_CREATE_SUCCESS = "REQUEST_CREATE_SUCCESS";
export const REQUEST_CREATE_FAIL = "REQUEST_CREATE_FAIL";
export const REQUEST_CREATE_CLEAR = "REQUEST_CREATE_CLEAR";

// Content file link create
export const CONTENT_FILE_LINK_CREATE_REQUEST = "CONTENT_FILE_LINK_CREATE_REQUEST";
export const CONTENT_FILE_LINK_CREATE_SUCCESS = "CONTENT_FILE_LINK_CREATE_SUCCESS";
export const CONTENT_FILE_LINK_CREATE_FAIL = "CONTENT_FILE_LINK_CREATE_FAIL";
export const CONTENT_FILE_LINK_CREATE_CLEAR = "CONTENT_FILE_LINK_CREATE_CLEAR";

// Content file link list
export const CONTENT_FILE_LINKS_REQUEST = "CONTENT_FILE_LINKS_REQUEST";
export const CONTENT_FILE_LINKS_SUCCESS = "CONTENT_FILE_LINKS_SUCCESS";
export const CONTENT_FILE_LINKS_FAIL = "CONTENT_FILE_LINKS_FAIL";
export const CONTENT_FILE_LINKS_CLEAR = "CONTENT_FILE_LINKS_CLEAR";

// Content file link list update
export const CONTENT_FILE_LINKS_UPDATE_REQUEST = "CONTENT_FILE_LINKS_UPDATE_REQUEST";
export const CONTENT_FILE_LINKS_UPDATE_SUCCESS = "CONTENT_FILE_LINKS_UPDATE_SUCCESS";
export const CONTENT_FILE_LINKS_UPDATE_FAIL = "CONTENT_FILE_LINKS_UPDATE_FAIL";
export const CONTENT_FILE_LINKS_UPDATE_CLEAR = "CONTENT_FILE_LINKS_UPDATE_CLEAR";

// Content file link list delete
export const CONTENT_FILE_LINKS_DELETE_REQUEST = "CONTENT_FILE_LINKS_DELETE_REQUEST";
export const CONTENT_FILE_LINKS_DELETE_SUCCESS = "CONTENT_FILE_LINKS_DELETE_SUCCESS";
export const CONTENT_FILE_LINKS_DELETE_FAIL = "CONTENT_FILE_LINKS_DELETE_FAIL";
export const CONTENT_FILE_LINKS_DELETE_CLEAR = "CONTENT_FILE_LINKS_DELETE_CLEAR";

// Collection add relationship
export const COLLECTION_ADD_RELATIONSHIP_REQUEST = "COLLECTION_ADD_RELATIONSHIP_REQUEST";
export const COLLECTION_ADD_RELATIONSHIP_SUCCESS = "COLLECTION_ADD_RELATIONSHIP_SUCCESS";
export const COLLECTION_ADD_RELATIONSHIP_FAIL = "COLLECTION_ADD_RELATIONSHIP_FAIL";
export const COLLECTION_ADD_RELATIONSHIP_CLEAR = "COLLECTION_ADD_RELATIONSHIP_CLEAR";

// Apple Login
export const APPLE_LOGIN_REQUEST = "APPLE_LOGIN_REQUEST";
export const APPLE_LOGIN_SUCCESS = "APPLE_LOGIN_SUCCESS";
export const APPLE_LOGIN_FAIL = "APPLE_LOGIN_FAIL";