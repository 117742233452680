import {
  FOLLOW_COLLECTION_REQUEST,
  FOLLOW_COLLECTION_SUCCESS,
  FOLLOW_COLLECTION_FAIL,
  REMOVE_COLLECTION_REQUEST,
  REMOVE_COLLECTION_SUCCESS,
  REMOVE_COLLECTION_FAIL,
  ADD_REMOVE_RELATIONSHIP_REQUEST,
  ADD_REMOVE_RELATIONSHIP_SUCCESS,
  ADD_REMOVE_RELATIONSHIP_FAIL,
} from "../types";

export const SubscribeReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_COLLECTION_REQUEST:
      return { loading: true };
    case FOLLOW_COLLECTION_SUCCESS:
      return { loading: false, Subscribe: action.payload };
    case FOLLOW_COLLECTION_FAIL:
      return { loading: false, Subscribe: action.payload };
    default:
      return state;
  }
};
export const UnSubscribeReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_COLLECTION_REQUEST:
      return { loading: true };
    case REMOVE_COLLECTION_SUCCESS:
      return { loading: false, UnSubscribe: action.payload };
    case REMOVE_COLLECTION_FAIL:
      return { loading: false, UnSubscribe: action.payload };
    default:
      return state;
  }
};
export const UserAddRemoveRelationshipReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_REMOVE_RELATIONSHIP_REQUEST:
      return { loading: true };
    case ADD_REMOVE_RELATIONSHIP_SUCCESS:
      return { loading: false, UserAddRemoveRelationship: action.payload };
    case ADD_REMOVE_RELATIONSHIP_FAIL:
      return { loading: false, UserAddRemoveRelationship: action.payload };
    default:
      return state;
  }
};
