import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  SIGNIN_RESET,
  SOCIAL_LOGIN_REQUEST,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  APPLE_LOGIN_REQUEST,
  APPLE_LOGIN_SUCCESS,
  APPLE_LOGIN_FAIL,
  SET_LOGGED_IN,
} from "../types";

export const LoginReducer = (state = {}, action) => {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return { loading: true };
    case SIGNIN_SUCCESS:
      return { loading: false, loginData: action.payload };
    case SIGNIN_FAIL:
      return { loading: false, loginData: action.payload };
    case SIGNIN_RESET:
      return { ...state, loginData: "" };
    case SET_LOGGED_IN:
      return { ...state, loggedIn: action.payload };
    default:
      return state;
  }
};
export const SocailLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_LOGIN_REQUEST:
      return { loading: true };
    case SOCIAL_LOGIN_SUCCESS:
      return { loading: false, socialLoginData: action.payload };
    case SOCIAL_LOGIN_FAIL:
      return { loading: false, socialLoginData: action.payload };
    case SET_LOGGED_IN:
        return { ...state, loggedIn: action.payload };
    default:
      return state;
  }
};

export const AppleLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLE_LOGIN_REQUEST:
      return { loading: true };
    case APPLE_LOGIN_SUCCESS:
      return { loading: false, appleLoginData: action.payload };
    case APPLE_LOGIN_FAIL:
      return { loading: false, appleLoginData: action.payload };
    case SET_LOGGED_IN:
        return { ...state, loggedIn: action.payload };
    default:
      return state;
  }
};
