import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
// import "../Assets/css/navigation.css";
// import { nav_routes } from "../routes";
import { useLocation } from "react-router-dom";
import { locale } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Search_ContentFilesResponse } from "../Redux/Actions/Search_ContentFilesAction";
import { CollectionListContentResponse } from "../Redux/Actions/CollectionListContentFilesAction";
import {
  UserListCollectionAction,
  UserListCollectionResponse,
} from "../Redux/Actions/UserLIstCollectionAction";
import { UserListUsersAction } from "../Redux/Actions/UserListUsersAction.js";
import { isSignIn, jsonToFormData, wrapper } from "../utils";
import {
  SubscribeAction,
  UnSubscribeAction,
  UserAddRemoveRelationshipAction,
} from "../Redux/Actions/Subscribe_Action";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SubscriptionModal from "./Children/SubscriptionIndicateModal";
import { invitationsToMeAction } from "../Redux/Actions/InvitationAction";

const Navigation = ({ navigate, active, loc, isVideoPlaying = false }) => {
  let Client = localStorage.getItem("Client");
  let SessionGuid = isSignIn()
    ? JSON.parse(localStorage.getItem("SessionGuid"))
    : null;

  const { userListCollection } = useSelector(
    (state) => state.UserListCollection
  );

  const { UserListUsers } = useSelector((state) => state.UserListUsers);

  const { UserDetailsGetNode } = useSelector(
    (state) => state.UserDetailsGetNode
  );

  const { CollectionListContentFiles } = useSelector(
    (state) => state.CollectionListContentFiles
  );

  const { ContentFileGetNode } = useSelector(
    (state) => state.ContentFileGetNode
  );

  const { UserAddRemoveRelationship } = useSelector(
    (state) => state.UserAddRemoveRelationship
  );

  const { invitationsToMe } = useSelector(
    (state) => state.invitationsToMe
  );

  const ContentFileGetNodeData = ContentFileGetNode?.length
    ? ContentFileGetNode?.[ContentFileGetNode?.length - 1]
    : ContentFileGetNode;

  const [collectionListContentFilesData, setCollectionListContentFilesData] =
    useState(CollectionListContentFiles?.individualchannel?.[0]);

  const location = useLocation();
  let encodeUrl;
  const dispatch = useDispatch();
  const history = useHistory();

  const responseClearedHandler = () => {
    if (location.pathname !== "/mychannel") {
      dispatch(UserListCollectionResponse());
    }
    localStorage.removeItem("searchingData");
    localStorage.removeItem("selectedChannel");
    localStorage.removeItem("selectedChannelName");
    if (!localStorage.getItem("isWatchOpen")) {
      localStorage.removeItem("selectedPath");
    }
    dispatch(Search_ContentFilesResponse());
    dispatch(CollectionListContentResponse());
  };

  let Vid =
    location?.pathname?.includes("/individualchannel") &&
    location?.pathname?.split("/individualchannel/")?.[1];
  // Get the selectedPath value from local storage.
  const getPath = localStorage.getItem("selectedPath");

  // Create a new URLSearchParams object using the current location's search string.
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the userId parameter from the searchParams object.
  const userId = searchParams.get("userId");

  const [subscribe, setSubscribe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const selectedChannelName = localStorage.getItem("selectedChannelName");

  const fetchData = () => {
    if (
      location.pathname?.includes("/mychannel") ||
      localStorage.getItem("selectedPath") !== "/mychannel"
    ) {
      let wrapped = {
        Client: Client,
        SessionGuid: SessionGuid,
        User_CollectionTypeId: "2",
        IncludeEmpty: "N",
        IncludeAdult: "N",
        NotificationCountInSort: "Y",
        Start: 1,
        End: 30,
        OrderBy: "Alpha",
      };

      dispatch(UserListCollectionAction(jsonToFormData(wrapped)));
    }
  };

  const fetchUserList = () => {
    let wrapped = {
      Client: Client,
      SessionGuid: SessionGuid,
      Following: "Y",
      Blocked: "N",
      ShowInverse: "N",
      Start: 1,
      End: 30,
      OrderBy: "Alpha",
    };

    dispatch(UserListUsersAction(jsonToFormData(wrapped)));
  };

  const fetchInvitationList = () => {
    let wrapped = {
      Client: Client,
      SessionGuid: SessionGuid,
      All: "N",
      InvitationTypeIds: "1, 2"
    };

    dispatch(invitationsToMeAction(jsonToFormData(wrapped)));
  };

  useEffect(() => {
    if ((!location.pathname?.includes("/home/request/") && isSignIn()) || isSignIn()) {
      fetchData();
      fetchUserList();
      fetchInvitationList();
    }
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/watch")) {
      localStorage.removeItem("filterData");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !location?.pathname === "/mychannel" ||
      !location?.pathname === "/following"
    ) {
      fetchData();
      fetchUserList();
    }
  }, [location]);

  useEffect(() => {
    setIsLoading(true);
    setCollectionListContentFilesData(
      CollectionListContentFiles?.individualchannel?.[0]
    );
    // Check if the user is subscribed to the current content file/channel.
    if (
      userListCollection &&
      !new URLSearchParams(location.search).has("userId")
    ) {
      let data = userListCollection?.find(
        ({ CollectionId }) => CollectionId === Vid
      );
      if (data && data.CollectionId === Vid) {
        setSubscribe(true);
      } else {
        setSubscribe(false);
      }
    }
    setIsLoading(false);
  }, [userListCollection, CollectionListContentFiles, Vid]);

  useEffect(() => {
    setIsLoading(true);
    const followingUsersList = Array.isArray(
      UserListUsers?.User_ListUsers?.Users?.User
    )
      ? UserListUsers?.User_ListUsers?.Users?.User?.filter(
        (item) => item?.Active === "True"
      )
      : UserListUsers?.User_ListUsers?.Users?.User?.Active === "True"
        ? [UserListUsers?.User_ListUsers?.Users?.User]
        : [];

    // Check if the user is following the current content file's author.
    if (userId) {
      let listUsersData = followingUsersList?.find(
        ({ UserId }) => UserId === Vid
      );
      if (listUsersData) {
        if (listUsersData.Following === "True") setSubscribe(true);
        else if (listUsersData.Following === "False") {
          setSubscribe(false);
        }
      }
    }
    setIsLoading(false);
  }, [userId, UserListUsers]);

  // Handle user add/remove relationship.
  const UserAddRemoveRelationshipHandler = (userFollowing) => {
    if (userFollowing === "N") {
      let defaultValue = {
        Client: Client,
        SessionGuid: SessionGuid,
        Other_UserId: Vid,
        Following: "N",
      };
      let wrapped = wrapper(defaultValue);
      dispatch(UserAddRemoveRelationshipAction(wrapped));
    }
    if (userFollowing === "Y") {
      let defaultValue = {
        Client: Client,
        SessionGuid: SessionGuid,
        Other_UserId: Vid,
        Following: "Y",
      };
      let wrapped = wrapper(defaultValue);
      dispatch(UserAddRemoveRelationshipAction(wrapped));
    }
  };

  const subscriptionHandler = (type) => {
    // setSubscribe(!subscribe);
    // Create a default object containing client and session data
    let defaultValue = {
      Client: Client,
      SessionGuid: SessionGuid,
      CollectionId: Vid,
    };

    // Wrap the default object with additional data required for the API call
    let wrapped = wrapper(defaultValue);
    // If the user wants to subscribe, dispatch a subscribe action and update the subscription state to true
    if (type === "subscribe" && subscribe !== true) {
      if (new URLSearchParams(location.search).has("userId")) {
        UserAddRemoveRelationshipHandler("Y"); // Update the user relationship to indicate they are following the user
      } else {
        dispatch(SubscribeAction(wrapped)); // Subscribe the collection
      }
      setSubscribe(true);
    }
    // If the user wants to unsubscribe, dispatch an unsubscribe action and update the subscription state to false
    else if (type === "unSubscribe" && subscribe !== false) {
      if (new URLSearchParams(location.search).has("userId")) {
        UserAddRemoveRelationshipHandler("N"); // Update the user relationship to indicate they are not following the user
      } else {
        dispatch(UnSubscribeAction(wrapped)); // Unsubscribe the collection
      }
      setSubscribe(false);
    }

    // Call the fetchData function to update the component with the latest data
    fetchData();
    fetchUserList();
  };

  const SubHandler = ({ subscribe, isLoading }) => {
    if (isLoading) {
      return (
        <Button className="btn eq_button text-white pointer" disabled>
          Loading...
        </Button>
      );
    }

    if (subscribe === true) {
      return (
        <>
          <Button
            className="btn eq_outline_button text-white pointer"
            onClick={() => subscriptionHandler("unSubscribe")}
            data-bs-toggle="modal"
            data-bs-whatever="@mdo"
            data-bs-target={`#subscriptionModal`}
          >
            SUBSCRIBED
          </Button>

          {new URLSearchParams(location.search).has("userId") ? (
            <SubscriptionModal
              channel={
                UserDetailsGetNode?.User_GetNode?.User?.Company
                  ? UserDetailsGetNode?.User_GetNode?.User?.Company
                  : `${UserDetailsGetNode?.User_GetNode?.User?.FirstName} ${UserDetailsGetNode?.User_GetNode?.User?.LastName}`
              }
              type={!subscribe ? "follow" : "unFollow"}
              UserAddRemoveRelationship={UserAddRemoveRelationship}
            />
          ) : (
            <SubscriptionModal
              channel={selectedChannelName}
              type={!subscribe ? "follow" : "unFollow"}
              UserAddRemoveRelationship={UserAddRemoveRelationship}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {isSignIn()
            && <Button
              className="btn eq_button text-white pointer"
              onClick={() => subscriptionHandler("subscribe")}
              data-bs-toggle="modal"
              data-bs-whatever="@mdo"
              data-bs-target={`#subscriptionModal`}
            >
              SUBSCRIBE
            </Button>
          }

          {new URLSearchParams(location.search).has("userId") ? (
            <SubscriptionModal
              channel={
                UserDetailsGetNode?.User_GetNode?.User?.Company
                  ? UserDetailsGetNode?.User_GetNode?.User?.Company
                  : `${UserDetailsGetNode?.User_GetNode?.User?.FirstName} ${UserDetailsGetNode?.User_GetNode?.User?.LastName}`
              }
              type={!subscribe ? "follow" : "unFollow"}
              UserAddRemoveRelationship={UserAddRemoveRelationship}
            />
          ) : (
            <SubscriptionModal
              channel={selectedChannelName}
              type={!subscribe ? "follow" : "unFollow"}
              UserAddRemoveRelationship={UserAddRemoveRelationship}
            />
          )}
        </>
      );
    }
  };

  let inviteCount = invitationsToMe?.Invitation_ListInvitationsToMe?.Invitations?.Invitation;

  if (inviteCount && !Array.isArray(inviteCount)) {
    inviteCount = [inviteCount]; // Convert the object to an array with a single element
  }

  if (!Array.isArray(inviteCount)) {
    inviteCount = []; // Set it to an empty array if it's still not an array
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={isVideoPlaying ? "videoPlaying p-0 d-flex justify-content-between" : "p-0 d-flex justify-content-between"}
        variant="dark"
      >
        <Container
          fluid
          className="d-flex justify-content-end"
          style={{ marginRight: "35px" }}
        >
          <div className="d-flex justify-content-end">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              {navigate.map(
                (item, i) =>
                  item.navShow && (
                    <NavLink
                      onClick={() => responseClearedHandler()}
                      key={i}
                      to={
                        active
                          ? `${item.path}?${encodeURIComponent(
                            `channel=${loc.channel}&collectionId=${loc.collectionId}&status=${loc.status}`
                          )}`
                          : localStorage.getItem("filterData") &&
                            localStorage.getItem("isWatchOpen") &&
                            getPath === item.path
                            ? localStorage.getItem("filterData")
                            : item.path
                      }
                      className={
                        location.pathname === item.path || getPath === item.path
                          ? "selected"
                          : "unselected"
                      }
                    >
                      {item.name}
                      {item.name === "My Channels" && inviteCount?.length > 0 &&
                        <span className="notify_badge">{inviteCount?.length}</span>
                      }
                    </NavLink>
                  )
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
        {location?.pathname?.includes("/individualchannel") ? (
          <div style={{ margin: "0 15px 15px 15px" }}>
            <SubHandler subscribe={subscribe} isLoading={isLoading} />
          </div>
        ) : null}
      </Navbar>
    </>
  );
};

export default Navigation;
