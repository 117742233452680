import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Nav,
  Navbar,
} from "react-bootstrap";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsBell, BsSearch, BsFilter } from "react-icons/bs";
import { BiVideoPlus } from "react-icons/bi";

import { RemoveUpdateProfileResponse } from "../Redux/Actions/UpdateProfile";
import { EQButton } from "./common/Button";
import { UserDetailsGetNodeAction, UserDetailsGetNodeResponse } from "../Redux/Actions/UserDetailsgetNodeAction";
import { REACT_APP_BASEURL_MAIN, getBrowserInfo, getOsInfo, getParameters, isSignIn, jsonToFormData } from "../utils";
import logo from "../Assets/img/eqLogo.png";
import appleLogo from "../Assets/img/apple-icon.png";
import defaultPic from "../Assets/img/default_profile_pic.png";

const Header = ({ ClearHandler, isVideoPlaying = false, isMyFiles = false }) => {
  const { UpdateProfile } = useSelector((state) => state.UpdateProfile);
  let channelPath;
  const { UserDetailsGetNode } = useSelector(
    (state) => state.UserDetailsGetNode
  );

  let User = UserDetailsGetNode?.User_GetNode?.User;

  let browser = getBrowserInfo();
  let osInfo = getOsInfo();
  let releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
  let buildVersion = process.env.REACT_APP_BUILD_VERSION;
  let Client = localStorage.getItem("Client") || `Web+[${browser.name}+${browser.version}]-[${osInfo.name}+(${buildVersion})]+v${releaseVersion}+(v${buildVersion})`;;

  let SessionGuid = isSignIn()
    ? JSON.parse(localStorage.getItem("SessionGuid"))
    : "";

  const [searching, setSearching] = useState("");
  const [count, setCount] = useState(0);
  const [channel, setChannel] = useState("");
  const [showAppLink, setShowAppLink] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const channelUrl = location.pathname.includes("individualchannel");
  const searchedValue = decodeURIComponent(searching);
  let channelSearch = getParameters(decodeURIComponent(location.search));
  console.log("channelSearch===>>>>>>>>>", channelSearch.channel);

  useEffect(() => {
    // Check if the device is iPhone or Mac with Apple Silicon
    const userAgent = navigator.userAgent;
    const isIPhone = /iPhone/.test(userAgent);

    if (isIPhone) {
      setShowAppLink(true);

      // Set a timer to hide the app link after 2 hours (7,200,000 milliseconds)
      const hideTimer = setTimeout(() => {
        setShowAppLink(false);
      }, 7200000);

      // Clean up the timer when the component is unmounted
      return () => clearTimeout(hideTimer);
    }
  }, []);

  const profileHandler = () => {
    dispatch(UserDetailsGetNodeResponse());
    // Selectively remove values from localStorage
    const keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key !== 'uploadVideoData') {
        localStorage.removeItem(key);
      }
    }
    // localStorage.clear();
    history.push("/signin");
  };

  // const debounce = (func) => {
  //   let timer;

  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 300);
  //   };
  // };

  // const handleChange = (value) => {
  //   let trimed = value.trim();
  //   getDropDown(trimed);
  // };
  // const onInputChangeHandler = useCallback(debounce(handleChange), []);

  const getUserDetails = () => {
    let data = {
      Client: Client,
      SessionGuid: SessionGuid,
    };
    let wrapped = jsonToFormData(data);
    dispatch(UserDetailsGetNodeAction(wrapped));
  };

  useEffect(() => {
    let searchInput = "";

    if (location.search.includes("&")) {
      let a = location.search.split("&");
      searchInput = a[0].substring(a[0].indexOf("=") + 1);
    } else {
      if (location.pathname === "/results") {
        searchInput = location.search.substring(
          location.search.indexOf("=") + 1
        );
      }
    }
    let decodeUrl = decodeURIComponent(searchInput);
    setSearching(decodeUrl);
    if ((!location.pathname?.includes("/home/request/") && isSignIn()) || isSignIn()) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("userData") && User) {
      localStorage.setItem("userData", JSON.stringify(User));
    }
  }, [User]);

  useEffect(() => {
    if (localStorage.getItem("search")) {
      setSearching(localStorage.getItem("search"));
    }
    if (localStorage.getItem("search")
      && !searching
      && localStorage.getItem("selectedChannel")
      && !location.pathname?.includes("/watch")
      && !location.pathname?.includes("/result")) {
      history.push(localStorage.getItem("selectedChannel"));
    }
  }, [localStorage.getItem("search")])

  useEffect(() => {
    if (UpdateProfile?.User_Update?.Result) {
      getUserDetails();
    }
  }, [UpdateProfile?.User_Update?.Result]);

  useEffect(() => {
    if (location.pathname.includes("channel")) {
      let a = location.pathname.split("-");
      channelPath = a[0].substring(a[0].indexOf("") + 1);
      setChannel(channelPath);
    } else {
      setChannel("/results");
    }

    if (location.pathname.includes("individualchannel")) {
      setSearching("");
    }
  }, [location]);

  useEffect(() => {
    // location.search.substring(location.pathname.indexOf("/") + 1);
    // let searchFilter = location?.search?.substring(
    //   location.search.indexOf("&sf") + 3
    // );
    let searchFilter;
    if (location.search.includes("&sf")) {
      let a = location.search.split("&sf");
      searchFilter = a[1];
    }

    if (searchFilter) {
      let filter = JSON.parse(decodeURIComponent(searchFilter));
      let filtered = 0;
      let data = Object.values(filter);

      data.map((item, i) => {
        if (item !== "" && item != false) {
          filtered = filtered + 1;
        }
      });
      setCount(filtered);
    } else {
      setCount(0);
    }
  }, [location.search]);

  useEffect(() => {
    const filterData = localStorage.getItem("filterData");
    const selectedChannel = localStorage.getItem("selectedChannel");

    if (filterData && selectedChannel) {
      const searchQuery = filterData.split("?search_query=")[1];
      const params = searchQuery.split("&").slice(1);

      // Check if any value is present after search_query=****&
      const hasValue = params.some((param) => param !== "");

      if (hasValue) {
        setCount(1);
      } else {
        setCount(0);
      }
    }
  }, []);

  // const channelInputChangeHandler = (e) => {
  //   e.preventDefault();
  //   let channelFilter;

  //   if (searching != "") {
  //     let searched = searching.trim();
  //     if (searched.length >= 3) {
  //       let encodeUrl = encodeURIComponent(searched);
  //       localStorage.setItem("search", encodeUrl);
  //       let data = getParameters(location.search);
  //       if (
  //         data.channel !== "" &&
  //         location.pathname.includes("/search_query")
  //       ) {
  //         let a = location.pathname.split("=");
  //         channelFilter = a[1].substring(a[1].indexOf("=") + 1);

  //         if (channelFilter) {
  //           history.replace({
  //             pathname: `/search_query=${encodeUrl}`,
  //             search: `${location.search}`,
  //           });
  //         }
  //       } else {
  //         history.push({
  //           pathname: `/search_query=${encodeUrl}`,
  //           search: `${location.search}`,
  //         });
  //       }
  //     }
  //   }
  // };

  const channelInputChangeHandler = (e) => {
    if (localStorage.getItem("selectedPath") === null || !["/", "/mychannel", "/mostrecent", "/trending", "/featured", "/following"].includes(localStorage.getItem("selectedPath")) && !location?.pathname?.includes("/results")) {
      localStorage.setItem("selectedPath", location?.pathname)
    }
    e.preventDefault();
    let channelFilter;

    if (searching != "") {
      let searched = searching.trim();
      if (searched.length >= 3) {
        let encodeUrl = encodeURIComponent(searched);
        localStorage.setItem("search", encodeUrl);
        let data = getParameters(location.search);
        if (!location.pathname?.includes("individualchannel")) {
          if (
            data.channel !== "" &&
            location.pathname.includes("/search_query")
          ) {
            let a = location.pathname.split("=");
            channelFilter = a[1].substring(a[1].indexOf("=") + 1);

            if (channelFilter) {
              history.replace({
                pathname: `/search_query=${encodeUrl}`,
                search: `${location.search}`,
              });
            }
          } else {
            history.push({
              pathname: location.pathname,
              search: `search_query=${encodeUrl}`,
            });
          }
        } else {
          localStorage.setItem("searchBeforePath", JSON.stringify({ pathName: location.pathname?.split("/")?.[1], selectedChannelId: location.pathname?.split("/")?.[2] }))
          history.push({
            pathname: "/results",
            search: `search_query=${encodeUrl}`,
          });
        }
      }
    }
  };

  const onInputChangeHandler = (e) => {
    e.preventDefault();
    if (localStorage.getItem("selectedPath") === null || !["/", "/mychannel", "/mostrecent", "/trending", "/featured", "/following"].includes(localStorage.getItem("selectedPath")) && !location?.pathname?.includes("/results")) {
      localStorage.setItem("selectedPath", location?.pathname)
    }
    let searchFilter;

    if (searching != "") {
      let searched = searching.trim();
      if (searched.length >= 3) {
        let encodeUrl = encodeURIComponent(searched);
        localStorage.setItem("search", encodeUrl);

        if (location.search.includes("&")) {
          let a = location.search.split("&");
          searchFilter = a[0].substring(a[0].indexOf("=") + 1);
          let sort = a[1].substring(a[1].indexOf("sf") + 2);
          let compo = JSON.parse(decodeURIComponent(sort));
          if (searchFilter) {
            history.replace({
              pathname: `/results`,
              search: `search_query=${encodeUrl}&sf${JSON.stringify(compo)}`,
            });
          } else {
            history.push({
              pathname: `/results`,
              search: `search_query=${encodeUrl}&sf${JSON.stringify(compo)}`,
            });
          }
        } else {
          history.push({
            pathname: `/results`,
            search: `search_query=${encodeUrl}`,
          });
        }
      }
    }
  };

  const onclickHandlerClick = (val) => {
    const url = `${location.pathname}${location.search}`;
    localStorage.setItem("path", url);
    if (url) {
      history.push(val);
    }
  };

  const logoHandler = () => {
    setSearching("");
    localStorage.removeItem("search");
    localStorage.removeItem("searchedElement");
    // localStorage.removeItem("filter");
  };

  const userData = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"));

  return (
    <>
      {showAppLink && (
        <a
          target="_blank"
          href="https://apps.apple.com/ng/app/eq-network/id492783788"
          className="btn apple_store_btn text-white"
        >
          <img src={appleLogo} className="apple_logo" />
          <div className="storeLabel">
            <div>Available on the App Store</div>
          </div>
          <div className="app_notification_close" onClick={(e) => { e.preventDefault(); setShowAppLink(false) }}>
            X
          </div>
        </a>
      )}
      <Navbar collapseOnSelect expand="lg" className={isVideoPlaying ? "videoPlayingHeader navbar" : "navbar"} variant="dark" style={{ paddingTop: "10px", height: "60px" }}>
        <Container
          fluid
          className="px-5 d-flex justify-content-between align-items-center"
        >
          <Navbar.Brand className="pointer" style={{ width: "160px" }}>
            {isMyFiles
              ? <img src={logo} onClick={logoHandler} style={{ height: "26px", paddingBottom: "6px" }} />
              : <NavLink to="/" >
                <img src={logo} onClick={logoHandler} style={{ height: "26px", paddingBottom: "6px" }} />
              </NavLink>
            }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav ">
            <div className="d-lg-flex w-100 justify-content-start align-items-center align-items-md-center">
              <div className="flex-grow-1">
                {location.pathname.substring(
                  location.pathname.indexOf("/") + 1
                ) !== "joinus" ? (
                  <div className="w-100 px-lg-5">
                    <Nav>
                      <Form
                        className="w-100"
                        onSubmit={
                          channelUrl
                            ? (e) => channelInputChangeHandler(e)
                            : (e) => onInputChangeHandler(e)
                        }
                      >
                        <InputGroup>
                          <FormControl
                            type="search"
                            value={decodeURIComponent(searchedValue)}
                            className="search-bar py-1"
                            aria-label="Example text with button addon"
                            aria-describedby="basic-addon1"
                            placeholder="Search"
                            onChange={(e) => setSearching(e.target.value)}
                          />

                          <Button
                            className="search_btn "
                            variant="outline-secondary"
                          >
                            <span
                              className="pointer "
                              data-bs-toggle="modal"
                              data-bs-whatever="@mdo"
                              data-bs-target="#filter"
                            >
                              <BsFilter
                                style={{ fontSize: "18px" }}
                                className={` ${count > 0 ? "text-danger" : "text-white"
                                  } `}
                              />
                              {count > 0 ? (
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                  {count}
                                </span>
                              ) : null}
                            </span>
                          </Button>

                          <Button
                            variant="outline-secondary"
                            style={{ zIndex: 0 }}
                            className="search_btn px-4"
                            id="button-addon1"
                            type="submit"
                          >
                            <BsSearch />
                          </Button>
                        </InputGroup>
                      </Form>
                    </Nav>
                  </div>
                ) : null}
              </div>
              <div className="">
                <div>
                  {isSignIn() ? (
                    <Nav className="me-auto d-lg-flex">
                      <div className="d-flex justify-content-around align-items-center">
                        <Nav.Link>
                          {userData?.Verified !== "True"
                            ? <span
                              data-bs-toggle="modal"
                              data-bs-whatever="@mdo"
                              data-bs-target="#resendConfirmationEmail"
                              data-bs-dismiss="modal">
                              <BiVideoPlus className="fs-3 text-white" />
                            </span>
                            : <NavLink to="/upload-videos">
                              <BiVideoPlus className="fs-3 text-white" />
                            </NavLink>
                          }
                        </Nav.Link>
                        <Nav.Link>
                          <NavLink to="/notification">
                            <BsBell className="fs-3 text-white" />
                          </NavLink>
                        </Nav.Link>
                        <Nav.Link className="ms-3">
                          <Dropdown>
                            <Dropdown.Toggle id="profile_toggle" variant="none">
                              <img
                                src={
                                  userData
                                    ?.IconFilename
                                    ? `${REACT_APP_BASEURL_MAIN}icons/mgen/scaleImage.ms?file=UserIcons/${userData?.IconFilename}&w=240&h=240&c=1`
                                    : defaultPic
                                }
                                className="rounded-circle border"
                                width="45"
                                height="45"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" variant="dark">
                              {/* <Dropdown.Item>
                                <NavLink
                                  // onClick={() =>
                                  //   dispatch(RemoveUpdateProfileResponse())
                                  // }
                                  to="/my-profile"
                                  className="text-decoration-none text-white"
                                >
                                  <div> My Profile </div>
                                </NavLink>
                              </Dropdown.Item> */}
                              <Dropdown.Item>
                                <NavLink
                                  onClick={() =>
                                    dispatch(RemoveUpdateProfileResponse())
                                  }
                                  to="/profile"
                                  className="text-decoration-none text-white"
                                >
                                  <div> Account</div>
                                </NavLink>
                              </Dropdown.Item>
                              <Dropdown.Item className="text-decoration-none text-white">
                                <div onClick={profileHandler}> Logout</div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Nav.Link>
                      </div>
                    </Nav>
                  ) : (
                    <Nav className="m-auto">
                      <div className="d-md-flex d-sm-flex">
                        <Nav.Link>
                          <Link onClick={() => onclickHandlerClick("/signin")}>
                            {/* <Button
                            className="outlined_button"
                            variant="outline-primary"
                          >
                            SIGN IN
                          </Button> */}
                            <EQButton
                              className="btn eq_outline_button text-white"
                              label="SIGN IN"
                            />
                          </Link>
                        </Nav.Link>
                        <Nav.Link>
                          <Link onClick={() => onclickHandlerClick("/signup")}>
                            {/* <Button variant="primary" className="eq_button">
                            SIGN UP
                          </Button> */}
                            <EQButton
                              className="btn eq_button text-white"
                              label="SIGN UP"
                            />
                          </Link>
                        </Nav.Link>
                      </div>
                    </Nav>
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
