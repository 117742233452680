import {
  COLLECTION_LISTCONTENT_FILES_REQUEST,
  COLLECTION_LISTCONTENT_FILES_SUCCESS,
  COLLECTION_LISTCONTENT_FILES_FAIL,
  COLLECTION_LISTCONTENT_RESPONSE_CLEARE,
  COLLECTION_GETNODE_REQUEST,
  COLLECTION_GETNODE_SUCCESS,
  COLLECTION_GETNODE_FAIL,
} from "../types";

export const CollectionListContentFilesReducer = (
  state = {
    CollectionListContentFiles: {
      channelhome: [],
      channelvideos: [],
      search: [],
    },
  },
  action
) => {
  switch (action.type) {
    case COLLECTION_LISTCONTENT_FILES_REQUEST:
      return { loading: true };
    case COLLECTION_LISTCONTENT_FILES_SUCCESS:
      return {
        loading: false,
        ...state,
        CollectionListContentFiles: {
          ...state.CollectionListContentFiles,
          [action.payload.category]: action.payload.data,
        },
      };
    case COLLECTION_LISTCONTENT_FILES_FAIL:
      return { loading: false, CollectionListContentFiles: action.payload };
    case COLLECTION_LISTCONTENT_RESPONSE_CLEARE:
      return { loading: false, CollectionListContentFiles: [] };
    default:
      return state;
  }
};

export const AboutCollectionGetNodeReducer = (state = {}, action) => {
  switch (action.type) {
    case COLLECTION_GETNODE_REQUEST:
      return { loading: true };
    case COLLECTION_GETNODE_SUCCESS:
      return { loading: false, AboutCollectionGetNode: action.payload };
    case COLLECTION_GETNODE_FAIL:
      return { loading: false, AboutCollectionGetNode: action.payload };
    default:
      return state;
  }
};
