import { xml2json } from "../../utils";
import {
  USER_LIST_COLLECTION_REQUEST,
  USER_LIST_COLLECTION_SUCCESS,
  USER_LIST_COLLECTION_FAIL,
  USER_LIST_COLLECTION_RESPONSE,

  COLLECTION_CREATE_REQUEST,
  COLLECTION_CREATE_SUCCESS,
  COLLECTION_CREATE_FAIL,
  COLLECTION_CREATE_RESPONSE,
  COLLECTION_UPDATE_REQUEST,
  COLLECTION_UPDATE_SUCCESS,
  COLLECTION_UPDATE_FAIL,
  COLLECTION_UPDATE_RESPONSE,

  COLLECTION_DELETE_REQUEST,
  COLLECTION_DELETE_SUCCESS,
  COLLECTION_DELETE_FAIL,
  COLLECTION_DELETE_RESPONSE
} from "../types";
import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

//userlogin
export const UserListCollectionAction = (formData) => async (dispatch) => {
  let data;
  let allChannels = [];
  try {
    dispatch({
      type: USER_LIST_COLLECTION_REQUEST,
    });
    data = await axiosInstance.post(endPoint.USER_LIST_COLLECTION, formData);

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    let channels = data?.Response?.Collections?.Collection;

    if (!channels) return allChannels;

    if (channels.length > 0) {
      allChannels = allChannels.concat(channels);
    } else {
      allChannels.push(channels);
    }

    dispatch({
      type: USER_LIST_COLLECTION_SUCCESS,
      payload: allChannels,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_COLLECTION_FAIL,
      payload: data,
    });
  }
};
export const UserListCollectionResponse = () => {
  return { type: USER_LIST_COLLECTION_RESPONSE };
};

export const CollectionCreateAction = (reqData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: COLLECTION_CREATE_REQUEST,
    });
    let formData = new FormData();
    formData.append("SessionGuid", reqData.SessionGuid);
    formData.append("Name", reqData.Name);
    formData.append("Categories", reqData.Categories);
    formData.append("Private", reqData.Private);
    formData.append("Description", reqData.Description);
    formData.append("Icon", reqData.Icon);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    data = await axiosInstance.post(
      endPoint.COLLECTION_CREATE,
      formData,
      config
    );

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: COLLECTION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_CREATE_FAIL,
      payload: data,
    });
  }
};

export const CollectionCreateResponse = () => {
  return { type: COLLECTION_CREATE_RESPONSE };
};

export const CollectionUpdateAction = (reqData) => async (dispatch) => {
  let data;

  try {
    dispatch({
      type: COLLECTION_UPDATE_REQUEST,
    });
    let formData = new FormData();
    formData.append("SessionGuid", reqData.SessionGuid);
    formData.append("Name", reqData.Name);
    formData.append("Categories", reqData.Categories);
    formData.append("Private", reqData.Private);
    formData.append("Description", reqData.Description);
    formData.append("Icon", reqData.Icon);
    formData.append("CollectionId", reqData.CollectionId);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    data = await axiosInstance.post(
      endPoint.COLLECTION_UPDATE,
      formData,
      config
    );

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: COLLECTION_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_UPDATE_FAIL,
      payload: data,
    });
  }
};

export const CollectionUpdateResponse = () => {
  return { type: COLLECTION_UPDATE_RESPONSE };
};


export const CollectionDeleteAction = (reqData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: COLLECTION_DELETE_REQUEST,
    });

    data = await axiosInstance.post(
      endPoint.COLLECTION_DELETE,
      reqData,

    );

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: COLLECTION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_DELETE_FAIL,
      payload: data,
    });
  }
};

export const CollectionDeleteFailResponse = () => {
  return { type: COLLECTION_DELETE_RESPONSE };
};