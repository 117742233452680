import { xml2json } from "../../utils";
import {
  SEARCH_CONTENT_FILES_REQUEST,
  SEARCH_CONTENT_FILES_SUCCESS,
  SEARCH_CONTENT_FILES_FAIL,
  SEARCH_CONTENT_FILES_RESPONSE_CLEARE,
  SEARCH_CONTENT_FILES_CONDITION,
} from "../types";

import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

//home page videos

export const Search_ContentFilesAction =
  (reqData, category) => async (dispatch, getState) => {
    let data;
    let allContentFile = [];

    // let storeValues;
    // let { SearchContentFiles } = getState();
    // if (SearchContentFiles?.SearchContentFiles?.length > 0) {
    //   storeValues = [...SearchContentFiles?.SearchContentFiles];
    // }

    try {
      dispatch({
        type: SEARCH_CONTENT_FILES_REQUEST,
      });

      let formData = new URLSearchParams(reqData);

      data = await axiosInstance.post(endPoint.SEARCH_CONTENT_FILES, formData);

      const res = `${data.data}`;
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(res, "text/xml");
      data = xml2json(xmlDoc);

      let contentfile = data?.Response?.ContentFiles?.ContentFile;

      //  for retun null
      if (!contentfile) {
        dispatch({
          type: SEARCH_CONTENT_FILES_SUCCESS,
          payload: { data: allContentFile, category: category },
        });
        return allContentFile;
      }

      // for when return single object
      if (contentfile.length > 0) {
        allContentFile = allContentFile.concat(contentfile);
      } else {
        allContentFile.push(contentfile);
      }

      dispatch({
        type: SEARCH_CONTENT_FILES_SUCCESS,
        payload: { data: allContentFile, category: category },
      });
    } catch (error) {
      dispatch({
        type: SEARCH_CONTENT_FILES_FAIL,
        payload: data,
      });
    }
  };

// export const Search_ContentFilesCondition = (data, category) => {
//   return {
//     type: SEARCH_CONTENT_FILES_CONDITION,
//     payload: { data: data, category: category },
//   };
// };

export const Search_ContentFilesResponse = () => {
  return { type: SEARCH_CONTENT_FILES_RESPONSE_CLEARE };
};
