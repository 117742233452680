import {
  SEARCH_COLLECTION_FILES_REQUEST,
  SEARCH_COLLECTION_FILES_SUCCESS,
  SEARCH_COLLECTION_FILES_FAIL,
  //   USER_REQUEST_PASSWORD_REQUEST,
  //   USER_REQUEST_PASSWORD_SUCCESS,
  //   USER_REQUEST_PASSWORD_FAIL,
} from "../types";
export const SearchCollectionContentReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_COLLECTION_FILES_REQUEST:
      return { loading: true };
    case SEARCH_COLLECTION_FILES_SUCCESS:
      return { loading: false, SearchCollectionFiles: action.payload };
    case SEARCH_COLLECTION_FILES_FAIL:
      return { loading: false, SearchCollectionFiles: action.payload };
    default:
      return state;
  }
};
