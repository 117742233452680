import React from "react";
import { FaEdit } from "react-icons/fa";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import defaultPic from "../../Assets/img/default_profile_pic.png";
import icError from "../../Assets/img/icError.png";
import { convertToInternationalCurrencySystem } from "../../helpers/helper";
import { REACT_APP_BASEURL_MAIN, isSignIn, jsonToFormData, secondsToHms, wrapper } from "../../utils";
import EditVideoModal from "../Children/EditVideoModal";
import { UserListCollectionAction } from "../../Redux/Actions/UserLIstCollectionAction";
import { ContentFileGetNodeAction, ContentFileLinkListAction } from "../../Redux/Actions/ContentFileGetNodeAction";

const Cards = React.memo(({
  item,
  key,
  date,
  thumbnailSrc,
  setSelectedItem = () => { },
  selectedItem = {},
  isEdit = false,
  setIsLoader = () => { },
  setConfirmedData = () => { },
  list = [],
  type = "",
  setIsCardClicked = () => { },
  setSelectedVideoId = () => { },
  isRequest = false
}) => {
  const {
    Name,
    ContentFileId,
    ViewCount,
    OwnerUserTitle,
    OwnerUserIconFilename,
    Duration,
    Thumbnail,
    OwnerUserId
  } = item;
  const handleOnError = (e) => {
    e.target.src = icError;
  };

  const location = useLocation();
  const history = useHistory();

  if (localStorage.getItem("selectedPath") === null || !["/", "/mychannel", "/mostrecent", "/trending", "/featured", "/following"].includes(localStorage.getItem("selectedPath")) && (!location?.pathname?.includes("/results") && !location?.pathname?.includes("/watch"))) {
    localStorage.setItem("selectedPath", location?.pathname)
  }

  const dispatch = useDispatch();

  let Client = localStorage.getItem("Client");
  let SessionGuid = isSignIn()
    ? JSON.parse(localStorage.getItem("SessionGuid"))
    : null;

  const fetchData = () => {
    let data = {
      Client: Client,
      SessionGuid: SessionGuid,
      IncludeEmpty: "Y",
      User_CollectionTypeId: "1,2",
      AsOfDate: "1/11/2011",
    };

    let wrapped = wrapper(data);
    dispatch(UserListCollectionAction(wrapped));
  }

  const handleSelectedVideo = (ContentFileId = "") => {
    if (location?.pathname?.includes("/results") && location.search) {
      localStorage.setItem("searchedElement", `${location?.pathname}${location?.search}`);
    }
    if (localStorage.getItem("selectedPath") === "/following") {
      localStorage.setItem("selctedChannelList", JSON.stringify(list));
      localStorage.setItem("prevSelectedLocation", `${location.pathname}${location.search}`);
    }
    if (type && type === "userProfile") {
      setIsCardClicked(true);
      setSelectedVideoId(ContentFileId);
    }
  };

  const handleRedirectToUserProfile = () => {
    history.replace({
      pathname: `/individualchannel/${OwnerUserId}`,
      search: `userId=${OwnerUserId}`,
    });
  };

  const fetchContentFileLinkList = (type = "", videoId = "") => {
    let body = {
      Client,
      SessionGuid,
      ContentFileId: videoId ? videoId : ContentFileId,
      OnlyActive: "Y",
      OnlyInactive: "N",
      Start: 0,
      End: 1000,
      OrderBy: "Recent"
    }
    if (type === "inactive") {
      body = {
        ...body,
        OnlyActive: "N",
        OnlyInactive: "Y"
      }
    }
    dispatch(ContentFileLinkListAction(jsonToFormData(body), type));
  };

  const fetchSelectedData = (Vid) => {
    if (Vid) {
      let defaultValue = {
        Client,
        SessionGuid,
        ContentFileId: Vid,
      };

      let wrapped = wrapper(defaultValue);
      dispatch(ContentFileGetNodeAction(wrapped));
    }
  };

  return (
    <>
      {/* card for globel ////// home, most popular, most recent, trending, featured  ///// */}
      <div key={key}>
        <div className="video" id="screen-grid" style={isRequest ? { marginBottom: "unset", marginRight: "10px", width: "220px" } : {}}>
          <div className="eq_card">
            <div className="thumbnail" style={isRequest ? { height: "125px" } : {}}>
              <div className="eq_blur">
                <div
                  className={isRequest ? "eq_blur_bg w-100 h-100" : "eq_blur_bg"}
                  style={{
                    backgroundImage: `url(
                      ${REACT_APP_BASEURL_MAIN}icons/mgen/overplayThumbnail.ms?drid=${thumbnailSrc}&subType=ljpg&o=0&w=800&h=800
                    )`,
                  }}
                ></div>
              </div>
              <div className="thumbnail_img">
                <div className="thumb">
                  {type && type === "userProfile"
                    ? <span onClick={() => handleSelectedVideo(ContentFileId)} className="pointer">
                      <img
                        width={isRequest ? "100px" : "300px"}
                        className="image_thumb"
                        src={`${REACT_APP_BASEURL_MAIN}icons/mgen/overplayThumbnail.ms?drid=${thumbnailSrc}&subType=ljpg&o=0&w=800&h=800&thumbnail=${Thumbnail}`}
                        onError={handleOnError}
                      />
                    </span>
                    : <Link to={`/watch?id=${ContentFileId}`} onClick={handleSelectedVideo}>
                      <img
                        width={isRequest ? "100px" : "300px"}
                        className="image_thumb"
                        src={`${REACT_APP_BASEURL_MAIN}icons/mgen/overplayThumbnail.ms?drid=${thumbnailSrc}&subType=ljpg&o=0&w=800&h=800&thumbnail=${Thumbnail}`}
                        onError={handleOnError}
                      />
                    </Link>
                  }
                </div>
              </div>
              <div className="time text-white  px-2 rounded-1 ">
                {Duration === "0" || Duration === ""
                  ? null
                  : secondsToHms(Duration)}
              </div>
            </div>
            <div className="details mx-2 py-2" style={isRequest ? { height: "100%" } : {}}>
              <div className="author mx-1">
                <img
                  src={
                    OwnerUserIconFilename
                      ? `${REACT_APP_BASEURL_MAIN}icons/mgen/scaleImage.ms?file=UserIcons/${OwnerUserIconFilename}`
                      : defaultPic
                  }
                  className="rounded-circle"
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "1px solid black",
                  }}
                />
              </div>
              <div className="title">
                <div className="video_title mb-1">{Name}</div>
                <span className="channel_text_color pointer" onClick={handleRedirectToUserProfile}>{OwnerUserTitle}</span>
                <span className="channel_text_color">
                  {convertToInternationalCurrencySystem(ViewCount)} Views •{" "}
                  {date}
                </span>
              </div>
              {isEdit &&
                <div
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Edit the video"
                  data-bs-toggle="modal"
                  data-bs-whatever="@mdo"
                  data-bs-target="#editModal"
                  onClick={() => { setSelectedItem(item); fetchData(); fetchContentFileLinkList("active"); fetchContentFileLinkList("inactive"); fetchSelectedData(item?.ContentFileId) }}
                >
                  <FaEdit className="text-white" />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <EditVideoModal
        selectedItem={selectedItem}
        setIsLoader={setIsLoader}
        setConfirmedData={setConfirmedData}
        fetchContentFileLinkList={fetchContentFileLinkList}
      />
    </>
  );
});

export default Cards;
