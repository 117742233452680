import React from "react";

export const EQButton = ({
  className,
  style,
  children,
  type,
  onClick,
  label,
  model,
  disabled = false,
  ...rest
}) => {
  return (
    <>
      <button
        style={style}
        className={className ? className : "btn"}
        type={type ? type : "button"}
        onClick={onClick}
        data-bs-dismiss={model && "modal"}
        disabled={disabled}
      >
        {label ? label : children}

        {/* <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span> */}
      </button>
    </>
  );
};
