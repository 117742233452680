import axios from "axios";
import REACT_APP_BASEURL from "../../utils";

// Add a request interceptor
export const axiosInstance = axios.create({
  baseURL: REACT_APP_BASEURL,
  timeout: 10000,

  // headers: {
  //   // "Content-Type": "application/x-www-form-urlencoded",
  //   "Access-Control-Allow-Origin":"*",
  //   // "Access-Control-Allow-Methods":"GET, OPTIONS, POST, PUT"

  // },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // let SessionGuid = JSON.parse(localStorage.SessionGuid);
    // if (SessionGuid) {
    // config.headers = {
    //   "Content-Type": "application/x-www-form-urlencoded",
    //   // "content-type": "multipart/form-data",
    // };

    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
