import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import defaultPic from "../Assets/img/default_profile_pic.png";
import {
    REACT_APP_BASEURL_MAIN,
    getFormatedStringFromDays,
    isSignIn,
    monthDiff,
    wrapper,
} from "../utils";
import { RequestCreateAction } from "../Redux/Actions/InvitationAction";
import FollowContributeReqModal from "../Components/Children/FollowContributeReqModal";
import Card from "../Components/common/card";

const FollowContributePage = ({
    currentRequestType = "",
    channelData = {},
    User = {}
}) => {

    let Client = localStorage.getItem("Client");

    let SessionGuid = isSignIn()
        ? JSON.parse(localStorage.getItem("SessionGuid"))
        : null;

    const { RequestCreate } = useSelector((state) => state.RequestCreate);
    const { CollectionListContentFiles } = useSelector(
        (state) => state.CollectionListContentFiles
    );

    const [modalMsg, setModalMsg] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    const requestGuid = urlParams.get('requestGuid');

    let encode = encodeURIComponent(channelData.Categories);
    let encodeReplace = encode.replaceAll("%20", "-");
    encodeReplace = encodeReplace.replace(".", "");

    useEffect(() => {
        if (RequestCreate?.Request_Create?.RequestMessage) {
            setModalMsg("Your request has been sent. The channel owner will need to approve your request");
        } else if (RequestCreate?.Response?.Error) {
            setModalMsg(RequestCreate?.Response?.Error?.["#text"])
        }
    }, [RequestCreate])

    const handleRequestCreate = (User_CollectionTypeId) => {
        if (isSignIn()) {
            let defaultValue = {
                Client: Client,
                SessionGuid: SessionGuid,
                CollectionRequestGuid: requestGuid,
                User_CollectionTypeId,
                Message: ""
            };

            let wrapped = wrapper(defaultValue);
            dispatch(RequestCreateAction(wrapped));
        }
    }

    return (
        <>
            <div
                className="d-flex justify-content-between mx-5"
                style={{ rowGap: "15px" }}
            >
                {/* left grid */}
                <div
                    className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-3"
                    style={{ width: "50%" }}
                >

                    <div style={{ borderColor: "#ffffff" }} className="invitation_section mt-3">
                        <div className="invitation_header d-flex">
                            <img
                                src={
                                    channelData?.Categories
                                        ? `${REACT_APP_BASEURL_MAIN}icons/mgen/scaleImage.ms?file=/App/CategoryIcons/${encodeReplace}.png&w=240&h=240&c=1`
                                        : defaultPic
                                }
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    border: "1px solid black",
                                    marginTop: "3px"
                                }}
                                alt="Avatar"
                            />
                            <h4
                                style={{
                                    color: "#ffffff",
                                    textAlign: "center",
                                    marginLeft: "5px"
                                }}
                                className="d-flex justify-content-center align-items-center"
                            >
                                {channelData?.Name}
                            </h4>
                        </div>
                        <div className="invitation_body pb-none">
                            {/* Displaying the videos under the selected channel */}
                            {CollectionListContentFiles?.Request?.length > 0
                                && <div className="d-flex justify-content-between mt-2" style={{ overflowX: "scroll", marginLeft: "5px" }}>
                                    {CollectionListContentFiles?.Request?.length > 0 && (
                                        CollectionListContentFiles?.Request?.map((item, i) => {
                                            const thumbnailSrc = item.MuxKey.substring(
                                                item.MuxKey.indexOf(",") + 1,
                                                item.MuxKey.indexOf("~")
                                            );
                                            const date = getFormatedStringFromDays(monthDiff(item.Created));
                                            if (User?.UserId === item?.OwnerUserId) {
                                                return (
                                                    <>
                                                        <Card
                                                            item={item}
                                                            key={i}
                                                            date={date}
                                                            thumbnailSrc={thumbnailSrc}
                                                            isDeleteIcon={true}
                                                            isRequest={true}
                                                        />
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <>
                                                        <Card
                                                            item={item}
                                                            key={i}
                                                            date={date}
                                                            thumbnailSrc={thumbnailSrc}
                                                            isRequest={true}
                                                        />
                                                    </>
                                                );
                                            }
                                        })
                                    )}
                                </div>
                            }
                            <div className="mt-4 mx-2 pt-1">
                                <div>Viewed: {channelData?.ViewCount}</div>
                                <div>Total Videos: {channelData?.ContentFileCount}</div>
                                <div>Created: {channelData?.Created?.split(" ")[0]}</div>
                                <div>Modified: {channelData?.Modified?.split(" ")[0]}</div>
                                <div className="mt-3">{channelData?.Description}</div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <Button
                                    type="button"
                                    className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                    onClick={() => history.push("/")}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                    data-bs-toggle="modal"
                                    data-bs-whatever="@mdo"
                                    data-bs-target="#FollowContributeReqModal"
                                    onClick={() => handleRequestCreate(currentRequestType === "follow" ? "2" : currentRequestType === "contribute" ? "3" : "")}
                                >
                                    Request to {currentRequestType}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="flex-md-row justify-content-end align-items-end"
                    style={{ width: "40%" }}
                >
                    <div className="py-0 my-2 d-flex">
                        <div className="d-flex flex-row user-section" style={{ maxWidth: "unset" }}>
                            <img
                                src={
                                    channelData?.OwnerUserIconFilename
                                        ? `https://eqnetwork.com/icons/mgen/scaleImage.ms?file=UserIcons/${channelData?.OwnerUserIconFilename}&w=128&h=128&c=1`
                                        : defaultPic
                                }
                                alt="userIcon"
                                width="100"
                                height="100"
                                className="rounded-1"
                            />
                            <div className="mx-2 w-100">
                                <h5 className="my-1 text-white">
                                    {channelData?.OwnerUserTitle}
                                </h5>

                                <div className="follower-info">
                                    <h6 className="my-0  text-white ">
                                        User Followers:{" "}{channelData?.FollowersUser}
                                    </h6>
                                    <h6 className="my-0  text-white">
                                        Channel Followers:{" "}{channelData?.followersCollection}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FollowContributeReqModal modalMsg={modalMsg} />
        </>
    );
};

export default FollowContributePage;
