import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { AppleLoginReducer, LoginReducer, SocailLoginReducer } from "./Reducers/LoginReducer";
import { SignUpReducer } from "./Reducers/SignUpReducer";
import {
  CollectionCreateReducer,
  CollectionUpdateReducer,
  UserListCollectionReducer,
  CollectioDeleteReducer,
} from "./Reducers/UserListCollectionReducer";
import { HomeSearchContentFilesReducer } from "./Reducers/HomeSearchContentFileReducer";
import { UserDetailsGetNodeReducer } from "./Reducers/UserDetailsgetNodeReducer";
import {
  ForgotUsernameReducer,
  ForgotPasswordReducer,
} from "./Reducers/ForgotReducer";
import { TrendingListReducer } from "./Reducers/TrendingListReducer";
import { Search_ContentFilesReducer } from "./Reducers/Search_ContentFilesReducer";
import {
  DeleteProfileReducer,
  UpdateProfileReducer,
} from "./Reducers/UpdateProfileReducer";
import {
  CollectionListContentFilesReducer,
  AboutCollectionGetNodeReducer,
} from "./Reducers/CollectionListContentFilesReducer";
import {
  SubscribeReducer,
  UnSubscribeReducer,
  UserAddRemoveRelationshipReducer,
} from "./Reducers/SubscribeReducer";
import {
  ChangeContentFileReducer,
  ContentFileGetNodeReducer,
  ContentFileLinkCreateReducer,
  ContentFileLinkDeleteReducer,
  ContentFileLinkListReducer,
  ContentFileLinkUpdateReducer,
  ContentFileViewedReducer,
  CreateContentFileReducer,
  DeleteContentFileReducer,
  UpdateContentFileReducer
} from "./Reducers/ContentFileGetNodeReducer";
import {
  CollectionSocialAddReducer,
  FlagVideoReducer,
  LikeReducer,
  ListUploadCallReducer,
  RemoveLikeReducer,
  ShareLinkReducer,
} from "./Reducers/SocialReducer";
import {
  PostCreateReducer,
  PostDeleteReducer,
  PostListForContentFileReducer,
} from "./Reducers/CommentReducer";
import { ListCategoriesReducer } from "./Reducers/FilterReducer";
import { SearchCollectionContentReducer } from "./Reducers/SearchCollectionFilesReducer";
import { ResendConfirmationEmailReducer } from "./Reducers/ResendConfirmationEmailReducer";
import { UserListUsersReducer } from "./Reducers/UserListUsersReducer";
import { InvitationCreateReducer, InvitationDeleteReducer, InvitationResendReducer, RequestCreateReducer, collectionAddRelationshipReducer, invitationAcceptReducer, invitationConfirmReducer, invitationDeclineReducer, invitationsToMeReducer, inviteListReducer, inviteUpdateReducer, requestAcceptReducer, requestDeclineReducer, requestGetCollectionReducer, requestListReducer, requestRemoveReducer } from "./Reducers/InvitationReducer";
import { UploadVideoCancelReducer } from "./Reducers/UploadVideosReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  Login: LoginReducer,
  SignUp: SignUpReducer,
  UserListCollection: UserListCollectionReducer,
  HomeSearchContentFiles: HomeSearchContentFilesReducer,
  ForgotUsername: ForgotUsernameReducer,
  ForgotPassword: ForgotPasswordReducer,
  SearchContentFiles: Search_ContentFilesReducer,
  TrendingList: TrendingListReducer,
  UpdateProfile: UpdateProfileReducer,
  CollectionListContentFiles: CollectionListContentFilesReducer,
  SubscribeReducer: SubscribeReducer,
  UnSubscribeReducer: UnSubscribeReducer,
  AboutCollectionGetNode: AboutCollectionGetNodeReducer,
  UserDetailsGetNode: UserDetailsGetNodeReducer,
  ContentFileGetNode: ContentFileGetNodeReducer,
  Like: LikeReducer,
  RemoveLike: RemoveLikeReducer,
  PostListForContentFile: PostListForContentFileReducer,
  PostCreate: PostCreateReducer,
  PostDelete: PostDeleteReducer,
  ListCategories: ListCategoriesReducer,
  SearchCollectionFiles: SearchCollectionContentReducer,
  DeleteProfile: DeleteProfileReducer,
  CollectionCreate: CollectionCreateReducer,
  CreateContentFile: CreateContentFileReducer,
  UserAddRemoveRelationship: UserAddRemoveRelationshipReducer,
  ContentFileViewed: ContentFileViewedReducer,
  CollectionUpdate: CollectionUpdateReducer,
  CollectionDelete: CollectioDeleteReducer,
  ShareLink: ShareLinkReducer,
  SocailLoginReducer: SocailLoginReducer,
  UpdateContentFile: UpdateContentFileReducer,
  DeleteContentFile: DeleteContentFileReducer,
  ChangeContentFile: ChangeContentFileReducer,
  ResendConfirmationEmail: ResendConfirmationEmailReducer,
  UserListUsers: UserListUsersReducer,
  InvitationCreate: InvitationCreateReducer,
  InvitationResend: InvitationResendReducer,
  InvitationDelete: InvitationDeleteReducer,
  UploadVideoCancel: UploadVideoCancelReducer,
  CollectionSocialAdd: CollectionSocialAddReducer,
  flagVideo: FlagVideoReducer,
  ListUpload: ListUploadCallReducer,
  requestGetCollection: requestGetCollectionReducer,
  requestList: requestListReducer,
  inviteList: inviteListReducer,
  inviteUpdate: inviteUpdateReducer,
  requestDecline: requestDeclineReducer,
  requestAccept: requestAcceptReducer,
  requestRemove: requestRemoveReducer,
  invitationsToMe: invitationsToMeReducer,
  invitationConfirm: invitationConfirmReducer,
  invitationAccept: invitationAcceptReducer,
  invitationDecline: invitationDeclineReducer,
  RequestCreate: RequestCreateReducer,
  ContentFileLinkCreate: ContentFileLinkCreateReducer,
  ContentFileLinkList: ContentFileLinkListReducer,
  ContentFileLinkUpdate: ContentFileLinkUpdateReducer,
  ContentFileLinkDelete: ContentFileLinkDeleteReducer,
  collectionAddRelationship: collectionAddRelationshipReducer,
  appleLoginData: AppleLoginReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
// UserName=JohnQPublic&Password=f00tball
export default store;
