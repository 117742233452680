import React from "react";
import { EQButton } from "../common/Button";

const SubscriptionModal = ({ channel, type, UserAddRemoveRelationship }) => {
    return (
        <>
            <div
                className="modal fade"
                id="subscriptionModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content radius_none eq_grey">
                        <div className="modal-header">
                            <h5 className="modal-title  text-white" id="exampleModalLabel">
                                EQ Network
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {UserAddRemoveRelationship?.Response?.Error
                                ? <div style={{ color: "#ffffff", height: "100px" }}>Something went wrong while trying to {type}ing the channel</div>
                                : <div style={{ color: "#ffffff", height: "100px" }}>You are now {type}ing {channel}</div>
                            }
                        </div>
                        <div className="modal-footer">
                            <EQButton
                                className="btn eq_button text-white "
                                model={"true"}
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </EQButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionModal;
