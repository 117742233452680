import {
    INVITAION_CREATE_FAIL,
    INVITAION_CREATE_REQUEST,
    INVITAION_CREATE_SUCCESS,
    INVITAION_CREATE_CLEAR,
    INVITAION_DELETE_FAIL,
    INVITAION_DELETE_REQUEST,
    INVITAION_DELETE_SUCCESS,
    INVITAION_DELETE_CLEAR,
    INVITAION_RESEND_FAIL,
    INVITAION_RESEND_REQUEST,
    INVITAION_RESEND_SUCCESS,
    INVITAION_RESEND_CLEAR,
    REQUEST_GET_COLLECTION_FAIL,
    REQUEST_GET_COLLECTION_REQUEST,
    REQUEST_GET_COLLECTION_SUCCESS,
    REQUEST_GET_COLLECTION_CLEAR,
    REQUEST_LIST_FAIL,
    REQUEST_LIST_REQUEST,
    REQUEST_LIST_SUCCESS,
    REQUEST_LIST_CLEAR,
    INVITE_LIST_FAIL,
    INVITE_LIST_REQUEST,
    INVITE_LIST_SUCCESS,
    INVITE_LIST_CLEAR,
    INVITE_UPDATE_FAIL,
    INVITE_UPDATE_REQUEST,
    INVITE_UPDATE_SUCCESS,
    INVITE_UPDATE_CLEAR,
    REQUEST_DECLINE_FAIL,
    REQUEST_DECLINE_REQUEST,
    REQUEST_DECLINE_SUCCESS,
    REQUEST_DECLINE_CLEAR,
    REQUEST_ACCEPT_FAIL,
    REQUEST_ACCEPT_REQUEST,
    REQUEST_ACCEPT_SUCCESS,
    REQUEST_ACCEPT_CLEAR,
    REQUEST_REMOVE_FAIL,
    REQUEST_REMOVE_REQUEST,
    REQUEST_REMOVE_SUCCESS,
    REQUEST_REMOVE_CLEAR,
    INVITATION_LIST_TO_ME_FAIL,
    INVITATION_LIST_TO_ME_REQUEST,
    INVITATION_LIST_TO_ME_SUCCESS,
    INVITATION_LIST_TO_ME_CLEAR,
    INVITATION_CONFIRM_FAIL,
    INVITATION_CONFIRM_REQUEST,
    INVITATION_CONFIRM_SUCCESS,
    INVITATION_ACCEPT_FAIL,
    INVITATION_ACCEPT_REQUEST,
    INVITATION_ACCEPT_SUCCESS,
    INVITATION_ACCEPT_CLEAR,
    INVITATION_DECLINE_FAIL,
    INVITATION_DECLINE_REQUEST,
    INVITATION_DECLINE_SUCCESS,
    INVITATION_DECLINE_CLEAR,
    REQUEST_CREATE_FAIL,
    REQUEST_CREATE_REQUEST,
    REQUEST_CREATE_SUCCESS,
    REQUEST_CREATE_CLEAR,
    COLLECTION_ADD_RELATIONSHIP_FAIL,
    COLLECTION_ADD_RELATIONSHIP_REQUEST,
    COLLECTION_ADD_RELATIONSHIP_SUCCESS,
    COLLECTION_ADD_RELATIONSHIP_CLEAR,
} from "../types";

export const InvitationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITAION_CREATE_REQUEST:
            return { loading: true };
        case INVITAION_CREATE_SUCCESS:
            return { loading: false, InvitationCreate: action.payload };
        case INVITAION_CREATE_FAIL:
            return { loading: false, InvitationCreate: action.payload };
        case INVITAION_CREATE_CLEAR:
            return { loading: false, InvitationCreate: {} };
        default:
            return state;
    }
};

export const InvitationResendReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITAION_RESEND_REQUEST:
            return { loading: true };
        case INVITAION_RESEND_SUCCESS:
            return { loading: false, InvitationResend: action.payload };
        case INVITAION_RESEND_FAIL:
            return { loading: false, InvitationResend: action.payload };
        case INVITAION_RESEND_CLEAR:
            return { loading: false, InvitationResend: [] };
        default:
            return state;
    }
};

export const InvitationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITAION_DELETE_REQUEST:
            return { loading: true };
        case INVITAION_DELETE_SUCCESS:
            return { loading: false, InvitationDelete: action.payload };
        case INVITAION_DELETE_FAIL:
            return { loading: false, InvitationDelete: action.payload };
        case INVITAION_DELETE_CLEAR:
            return { loading: false, InvitationDelete: [] };
        default:
            return state;
    }
};

export const requestGetCollectionReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_GET_COLLECTION_REQUEST:
            return { loading: true };
        case REQUEST_GET_COLLECTION_SUCCESS:
            return { loading: false, requestGetCollection: action.payload };
        case REQUEST_GET_COLLECTION_FAIL:
            return { loading: false, requestGetCollection: action.payload };
        case REQUEST_GET_COLLECTION_CLEAR:
            return { loading: false, requestGetCollection: {} };
        default:
            return state;
    }
};

export const requestListReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_LIST_REQUEST:
            return { loading: true };
        case REQUEST_LIST_SUCCESS:
            return { loading: false, requestList: action.payload };
        case REQUEST_LIST_FAIL:
            return { loading: false, requestList: action.payload };
        case REQUEST_LIST_CLEAR:
            return { loading: false, requestList: {} };
        default:
            return state;
    }
};

export const inviteListReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITE_LIST_REQUEST:
            return { loading: true };
        case INVITE_LIST_SUCCESS:
            return { loading: false, inviteList: action.payload };
        case INVITE_LIST_FAIL:
            return { loading: false, inviteList: action.payload };
        case INVITE_LIST_CLEAR:
            return { loading: false, inviteList: {} };
        default:
            return state;
    }
};

export const inviteUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITE_UPDATE_REQUEST:
            return { loading: true };
        case INVITE_UPDATE_SUCCESS:
            return { loading: false, inviteUpdate: action.payload };
        case INVITE_UPDATE_FAIL:
            return { loading: false, inviteUpdate: action.payload };
        case INVITE_UPDATE_CLEAR:
            return { loading: false, inviteUpdate: [] };
        default:
            return state;
    }
};

export const requestDeclineReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_DECLINE_REQUEST:
            return { loading: true };
        case REQUEST_DECLINE_SUCCESS:
            return { loading: false, requestDecline: action.payload };
        case REQUEST_DECLINE_FAIL:
            return { loading: false, requestDecline: action.payload };
        case REQUEST_DECLINE_CLEAR:
            return { loading: false, requestDecline: {} };
        default:
            return state;
    }
};

export const requestAcceptReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_ACCEPT_REQUEST:
            return { loading: true };
        case REQUEST_ACCEPT_SUCCESS:
            return { loading: false, requestAccept: action.payload };
        case REQUEST_ACCEPT_FAIL:
            return { loading: false, requestAccept: action.payload };
        case REQUEST_ACCEPT_CLEAR:
            return { loading: false, requestAccept: {} };
        default:
            return state;
    }
};

export const requestRemoveReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_REMOVE_REQUEST:
            return { loading: true };
        case REQUEST_REMOVE_SUCCESS:
            return { loading: false, requestRemove: action.payload };
        case REQUEST_REMOVE_FAIL:
            return { loading: false, requestRemove: action.payload };
        case REQUEST_REMOVE_CLEAR:
            return { loading: false, requestRemove: {} };
        default:
            return state;
    }
};

export const invitationsToMeReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITATION_LIST_TO_ME_REQUEST:
            return { loading: true };
        case INVITATION_LIST_TO_ME_SUCCESS:
            return { loading: false, invitationsToMe: action.payload };
        case INVITATION_LIST_TO_ME_FAIL:
            return { loading: false, invitationsToMe: action.payload };
        case INVITATION_LIST_TO_ME_CLEAR:
            return { loading: false, invitationsToMe: {} };
        default:
            return state;
    }
};

export const invitationConfirmReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITATION_CONFIRM_REQUEST:
            return { loading: true };
        case INVITATION_CONFIRM_SUCCESS:
            return { loading: false, invitationConfirm: action.payload };
        case INVITATION_CONFIRM_FAIL:
            return { loading: false, invitationConfirm: action.payload };
        default:
            return state;
    }
};

export const invitationAcceptReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITATION_ACCEPT_REQUEST:
            return { loading: true };
        case INVITATION_ACCEPT_SUCCESS:
            return { loading: false, invitationAccept: action.payload };
        case INVITATION_ACCEPT_FAIL:
            return { loading: false, invitationAccept: action.payload };
        case INVITATION_ACCEPT_CLEAR:
            return { loading: false, invitationAccept: {} };
        default:
            return state;
    }
};

export const invitationDeclineReducer = (state = {}, action) => {
    switch (action.type) {
        case INVITATION_DECLINE_REQUEST:
            return { loading: true };
        case INVITATION_DECLINE_SUCCESS:
            return { loading: false, invitationDecline: action.payload };
        case INVITATION_DECLINE_FAIL:
            return { loading: false, invitationDecline: action.payload };
        case INVITATION_DECLINE_CLEAR:
            return { loading: false, invitationDecline: {} };
        default:
            return state;
    }
};

export const RequestCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_CREATE_REQUEST:
            return { loading: true };
        case REQUEST_CREATE_SUCCESS:
            return { loading: false, RequestCreate: action.payload };
        case REQUEST_CREATE_FAIL:
            return { loading: false, RequestCreate: action.payload };
        case REQUEST_CREATE_CLEAR:
            return { loading: false, RequestCreate: {} };
        default:
            return state;
    }
};

export const collectionAddRelationshipReducer = (state = {}, action) => {
    switch (action.type) {
        case COLLECTION_ADD_RELATIONSHIP_REQUEST:
            return { loading: true };
        case COLLECTION_ADD_RELATIONSHIP_SUCCESS:
            return { loading: false, collectionAddRelationship: action.payload };
        case COLLECTION_ADD_RELATIONSHIP_FAIL:
            return { loading: false, collectionAddRelationship: action.payload };
        case COLLECTION_ADD_RELATIONSHIP_CLEAR:
            return { loading: false, collectionAddRelationship: {} };
        default:
            return state;
    }
};