import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "react-bootstrap";

import { Header } from '../Components';
import { nav_routes } from '../routes';
import Navigation from '../Components/Navigation';
import { getBrowserInfo, getOsInfo, isSignIn, jsonToFormData } from '../utils';
import FollowContributePage from './FollowContributePage';
import { requestAcceptAction, requestDeclineAction, requestListAction, requestRemoveAction } from '../Redux/Actions/InvitationAction';
import FollowContributeReqModal from '../Components/Children/FollowContributeReqModal';
import defaultPic from "../Assets/img/default_profile_pic.png";
import { AboutCollectionGetNodeAction, CollectionListContentFilesAction } from '../Redux/Actions/CollectionListContentFilesAction';
import { UserDetailsGetNodeAction } from '../Redux/Actions/UserDetailsgetNodeAction';

const RequestFollowContribute = () => {
    const location = useLocation();
    const history = useHistory();

    let browser = getBrowserInfo();
    let osInfo = getOsInfo();
    let releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
    let buildVersion = process.env.REACT_APP_BUILD_VERSION;
    let Client = localStorage.getItem("Client") || `Web+[${browser.name}+${browser.version}]-[${osInfo.name}+(${buildVersion})]+v${releaseVersion}+(v${buildVersion})`;;
    let SessionGuid = isSignIn()
        ? JSON.parse(localStorage.getItem("SessionGuid"))
        : "";

    const dispatch = useDispatch();

    const { requestRemove } = useSelector((state) => state.requestRemove);
    const { requestDecline } = useSelector((state) => state.requestDecline);
    const { requestAccept } = useSelector((state) => state.requestAccept);
    const { UserDetailsGetNode } = useSelector(
        (state) => state.UserDetailsGetNode
    );
    const { AboutCollectionGetNode } = useSelector(
        (state) => state.AboutCollectionGetNode
    );
    const { CollectionListContentFiles } = useSelector(
        (state) => state.CollectionListContentFiles
    );

    const channelData = AboutCollectionGetNode?.Collection_GetNode?.Collection;
    let User = UserDetailsGetNode?.RequestedUser;
    const urlParams = new URLSearchParams(window.location.search);
    const RequestGuid = urlParams.get('requestGuid');

    let currentRequestType = location?.pathname?.split("/");
    currentRequestType = currentRequestType[currentRequestType?.length - 1];

    const [modalMsg, setModalMsg] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (window.location.href && RequestGuid) {
            dispatch(
                AboutCollectionGetNodeAction(
                    jsonToFormData({
                        CollectionRequestGuid: RequestGuid,
                        Client,
                    })
                )
            );
        }
    }, [RequestGuid, currentRequestType]);

    useEffect(() => {
        if (AboutCollectionGetNode?.Collection_GetNode?.Collection) {
            const collectionData = AboutCollectionGetNode?.Collection_GetNode?.Collection;
            let data = {
                Client: Client,
                UserId: collectionData?.UserId,
            };
            let wrapped = jsonToFormData(data);
            dispatch(UserDetailsGetNodeAction(wrapped, "RequestedUser"));
            if (collectionData && collectionData?.CollectionTypeId === "1") {
                fetchData(collectionData?.CollectionId);
            }
        }
    }, [AboutCollectionGetNode]);

    useEffect(() => {
        if (requestDecline?.Response?.Error) {
            setModalMsg(requestDecline?.Response?.Error?.["#text"])
        } else if (requestAccept?.Response?.Error) {
            setModalMsg(requestAccept?.Response?.Error?.["#text"])
        } else if (requestRemove?.Response?.Error) {
            setModalMsg(requestRemove?.Response?.Error?.["#text"])
        } else if (requestAccept?.Request_Accept?.Request) {
            setModalMsg(`The request for ${User ? User?.Company : ""} to contribute the channel, '${channelData?.Name}', has been accepted`);
            handleRequest();
        } else if (requestDecline?.Request_Decline?.Request) {
            setModalMsg(`The request for ${User ? User?.Company : ""} to contribute the channel, '${channelData?.Name}', has been declined`);
            handleRequest();
        } else if (requestRemove?.Request_Delete?.["#text"]) {
            setModalMsg(`The request for ${User ? User?.Company : ""} to contribute the channel, '${channelData?.Name}', has been removed`);
            handleRequest();
        }
    }, [requestDecline, requestAccept, requestRemove]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const action = params.get('action');

        if (action) {
            setIsOpen(true);
            // Action is present in the URL, trigger the corresponding button click
            switch (action) {
                case 'accept':
                    handleRequestUpdate('Accept');
                    break;
                case 'decline':
                    handleRequestUpdate('Decline');
                    break;
                default:
                    break;
            }
            // Clear the action parameter from the URL to prevent re-triggering on subsequent renders
            params.delete('action');
            history.replace({ search: params.toString() });
        }
    }, [history, location.search]);

    const handleRequest = () => {
        let requestData = {
            Client: Client,
            SessionGuid: SessionGuid,
            CollectionId: localStorage.getItem("selectedEditId"),
            RequestStateIds: "1,3,2",
            RequestTypeIds: 1
        };
        let wrapped = jsonToFormData(requestData);
        dispatch(requestListAction(wrapped));
    }

    const handleRequestUpdate = (type = "") => {
        let data = {
            Client: Client,
            SessionGuid: SessionGuid,
            RequestGuid,
        };
        let wrapped = jsonToFormData(data);
        if (type === "Accept") {
            dispatch(requestAcceptAction(wrapped));
        } else if (type === "Decline") {
            dispatch(requestDeclineAction(wrapped));
        } else if (type === "Remove") {
            dispatch(requestRemoveAction(wrapped));
        }
    }

    const fetchData = (CollectionId) => {
        let wrapped = {
            Client,
            SessionGuid,
            CollectionId,
            Start: 0,
            End: 80,
            OrderBy: "Recent"
        };

        dispatch(CollectionListContentFilesAction(jsonToFormData(wrapped), "Request"));
    };

    return (
        <div className="content_container">
            <div className="eq_contain">
                <section className="eq_section">
                    <div id="main_content">
                        <div className="header"></div>
                        <div className="eq_header_section">
                            <Header />
                            <Navigation navigate={nav_routes} />
                        </div>
                        <main className="eq_main">
                            {currentRequestType === "view"
                                ? (channelData && User
                                    ? <div style={{ padding: "120px 100px 0 100px" }} className="eq_content">
                                        <h4 className="text-white">View Request</h4>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div style={{ borderColor: "#ffffff" }} className="invitation_section mt-3 w-50">
                                                <div className="invitation_header d-flex">
                                                    <img
                                                        src={
                                                            channelData?.OwnerUserIconFilename
                                                                ? `https://eqnetwork.com/icons/mgen/scaleImage.ms?file=UserIcons/${channelData?.OwnerUserIconFilename}&w=128&h=128&c=1`
                                                                : defaultPic
                                                        }
                                                        alt="userIcon"
                                                        width="45"
                                                        height="45"
                                                        className="rounded-1 mt-1"
                                                    />
                                                    <h4
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            marginLeft: "5px"
                                                        }}
                                                        className="d-flex justify-content-center align-items-center"
                                                    >
                                                        {`${User?.Company} (${User?.UserName})`}
                                                    </h4>
                                                </div>
                                                <div className="invitation_body pb-none">
                                                    <div className="mt-4 mx-2 pt-1">
                                                        <div>{User?.Company} would like to contribute to your channel, "{channelData?.Name}".</div>
                                                        <div className="mt-4 mx-2 pt-1">
                                                            <div>Created: {channelData?.Created?.split(" ")[0]}</div>
                                                            <div>Modified: {channelData?.Modified?.split(" ")[0]}</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                                        <Button
                                                            type="button"
                                                            className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-whatever="@mdo"
                                                            data-bs-target="#FollowContributeReqModal"
                                                            onClick={() => handleRequestUpdate("Remove")}
                                                        >
                                                            Remove
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-whatever="@mdo"
                                                            data-bs-target="#FollowContributeReqModal"
                                                            onClick={() => handleRequestUpdate("Decline")}
                                                        >
                                                            Decline
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-whatever="@mdo"
                                                            data-bs-target="#FollowContributeReqModal"
                                                            onClick={() => handleRequestUpdate("Accept")}
                                                        >
                                                            Accept
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="eq_content" style={{ paddingTop: "unset" }}>
                                        <div class="text-center d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                                            <div
                                                class="spinner-border text-white"
                                                style={{ height: "10rem", width: "10rem" }}
                                                role="status"
                                            >
                                                <span class="visually-hidden">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : (channelData && CollectionListContentFiles
                                    ? <div style={{ padding: "120px 100px 0 100px" }} className="eq_content">
                                        <h4 className="text-white">On this page, you can create a request to {currentRequestType} the {channelData?.CollectionTypeId === "2" ? "private" : "public"} channel, '{channelData?.Name}', by {User?.UserName}.</h4>
                                        <div>
                                            <FollowContributePage
                                                currentRequestType={currentRequestType}
                                                channelData={channelData}
                                                User={User}
                                            />
                                        </div>
                                    </div>
                                    : <div className="eq_content" style={{ paddingTop: "unset" }}>
                                        <div class="text-center d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                                            <div
                                                class="spinner-border text-white"
                                                style={{ height: "10rem", width: "10rem" }}
                                                role="status"
                                            >
                                                <span class="visually-hidden">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </main>
                    </div>
                </section>
            </div>
            <FollowContributeReqModal
                modalMsg={modalMsg}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </div>
    );
}

export default RequestFollowContribute;
