import { xml2json } from "../../utils";
import {
  USER_GET_NODE_REQUEST,
  USER_GET_NODE_SUCCESS,
  USER_GET_NODE_FAIL,
  USER_GET_NODE_CLEAR,
} from "../types";

import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

// UserDetailsgetNodeAction
export const UserDetailsGetNodeAction = (formData, category = "") => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: USER_GET_NODE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.USER_GET_NODE, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: USER_GET_NODE_SUCCESS,
      payload: category !== "" ? {...data, category } : data,
    });
  } catch (error) {
    dispatch({
      type: USER_GET_NODE_FAIL,
      payload: data,
    });
  }
};

export const UserDetailsGetNodeResponse = () => {
  return { type: USER_GET_NODE_CLEAR };
};
