import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import { ChangeContentFileAction, ContentFileDeleteClear, ContentFileLinkUpdateAction, ContentFileLinkUpdateClear, DeleteContentFileAction, UpdateContentFileAction } from "../../Redux/Actions/ContentFileGetNodeAction";
import { isSignIn, jsonToFormData, wrapper } from "../../utils";
import { EQButton } from "../common/Button";
import more_carrat from "../../Assets/img/more_carrat.png";
import ManagedLinksModal from "./ManagedLinksModal";

const EditVideoModal = ({
    selectedItem = {},
    setModalClose = () => { },
    setIsLoader = () => { },
    fetchContentFileLinkList = () => { }
}) => {
    const defautFormData = {
        SessionGuid: "",
        Name: "",
        Description: "",
        Categories: "",
        Private: "",
        Icon: "",
    };

    const defautFormData_1 = {
        Keywords: "",
        SessionGuid: "",
        IsFlashVideo: "",
        vradio: "file",
        Video: "",
        Url: "",
        Categories: "",
        CollectionId: "",
        Name: "",
        Description: "",
        Thumbnail: "",
    };

    const { userListCollection } = useSelector(
        (state) => state.UserListCollection
    );

    const { ListCategories } = useSelector((state) => state.ListCategories);
    let categoryList = ListCategories?.Response?.Categories?.Category;

    const { DeleteContentFile } = useSelector((state) => state.DeleteContentFile);
    const { ContentFileLinkList } = useSelector((state) => state.ContentFileLinkList);
    const { ContentFileLinkUpdate } = useSelector((state) => state.ContentFileLinkUpdate);

    const [formCreateChannel, setFormCreateChannel] = useState(defautFormData);
    const [formContentData, setFormData] = useState(defautFormData_1);
    const [option, setOption] = useState([]);
    const [selected, setSelected] = useState([]);
    const [channel, setChannel] = useState("");
    const [isMore, setIsMore] = useState({});
    const [isClickAdd, setIsClickAdd] = useState(false);
    const [isClickUpdate, setIsClickUpdate] = useState(false);
    const [editLinkData, setEditLinkData] = useState({});
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDeleteVideo, setIsDeleteVideo] = useState(false);

    const dispatch = useDispatch();
    const imageRef = useRef();
    const videoRef = useRef();

    let Client = localStorage.getItem("Client");
    let SessionGuid = isSignIn()
        ? JSON.parse(localStorage.getItem("SessionGuid"))
        : null;

    const handleClearEditModalData = () => {
        setIsDelete(false);
        setIsDuplicate(false);
        setEditLinkData({});
        setIsClickUpdate(false);
        setIsClickAdd(false);
    }

    useEffect(() => {
        if (categoryList) {
            let List = [];
            categoryList?.forEach((category) => {
                let obj = { label: category.Name, value: category.Name };
                List.push(obj);
            });
            setOption(List);
        }
    }, [categoryList]);

    useEffect(() => {
        if (Object.keys(selectedItem)?.length && userListCollection?.length) {
            const tempData = selectedItem?.Categories?.split(",");

            let categoryListData = [];

            tempData?.forEach(item => {
                categoryListData = [
                    ...categoryListData,
                    { label: item?.trim(), value: item?.trim() }
                ]
            })
            setFormCreateChannel(selectedItem);
            setFormData({
                Keywords: "",
                SessionGuid: SessionGuid,
                IsFlashVideo: "",
                vradio: "file",
                Video: "",
                Url: "",
                Categories: selectedItem?.Categories,
                CollectionId: selectedItem?.CollectionId,
                Channel: selectedItem?.CollectionId,
                Name: selectedItem?.Name,
                Description: selectedItem?.Description,
                Thumbnail: selectedItem?.Thumbnail,
                ContentFileId: selectedItem?.ContentFileId
            });
            setSelected(categoryListData);
            setChannel(userListCollection?.filter(item => item?.CollectionId === selectedItem?.CollectionId)?.[0]?.Name);
        }
    }, [selectedItem, SessionGuid, userListCollection]);

    useEffect(() => {
        if (selected?.length) {
            let Categories = "";
            selected.forEach(item => {
                Categories = Categories ? `${Categories}, ${item?.value}` : item?.value;
            })
            setFormData({
                ...formContentData,
                Categories,
            });
        }
    }, [selected])

    useEffect(() => {
        if (DeleteContentFile?.ContentFile_Delete2?.Result?.["#text"] === "Ok" && localStorage.getItem("isClickDelete") === "true") {
            toast.success("Selected video was deleted successfully");
            dispatch(ContentFileDeleteClear());
            localStorage.removeItem("isClickDelete");
        }
    }, [DeleteContentFile]);

    useEffect(() => {
        if (isClickUpdate) {
            if (ContentFileLinkUpdate?.ContentFileLink_Update?.ContentFileLink) {
                const val = ContentFileLinkUpdate?.ContentFileLink_Update?.ContentFileLink;
                dispatch(ContentFileLinkUpdateClear());
                fetchContentFileLinkList("active", val?.ContentFileId);
                fetchContentFileLinkList("inactive", val?.ContentFileId);
                setIsClickUpdate(false);
                if (Object.keys(editLinkData)?.length) {
                    toast.success("Selected managed link was edited successfully");
                } else {
                    toast.success(`Selected managed link was ${val?.Active === "False" ? "disabled" : "enabled"} successfully`);
                }
                setIsClickAdd(false);
                setEditLinkData({});
            } else if (ContentFileLinkUpdate?.Response?.Error) {
                toast.error(ContentFileLinkUpdate?.Response?.Error?.["#text"]);
                setIsClickUpdate(false);
                if (Object.keys(editLinkData)?.length) {
                    setIsClickAdd(false);
                    setEditLinkData({});
                }
            }
        }
    }, [ContentFileLinkUpdate, isClickUpdate]);

    useEffect(() => {
        if (selectedIndex !== null) {
            const element = document.getElementById(`linkItem_${selectedIndex}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                setSelectedIndex(null);
            }
        }
    }, [selectedIndex]); // Include any dependencies needed to re-run the effect   

    const handleFormData = (e, type = "") => {
        setFormCreateChannel({
            ...formCreateChannel,
            [e.target.name]: e.target.value,
        });
        setFormData({
            ...formContentData,
            [e.target.name]: e.target.value,
        });
        if (type === "channel") {
            setChannel(e.target.value);
            const New_CollectionId = userListCollection?.filter(item => item?.Name === e.target.value)?.[0]?.CollectionId;
            let data = {
                Client,
                SessionGuid,
                ContentFileId: selectedItem?.ContentFileId,
                Old_CollectionId: selectedItem?.CollectionId,
                New_CollectionId
            };

            let wrapped = wrapper(data);
            dispatch(ChangeContentFileAction(wrapped));
        }
    };

    const createChannelHandler = (e) => {
        e.preventDefault();

        if (
            formContentData?.Name !== "" &&
            formContentData?.Categories !== "" &&
            formContentData?.Channel !== ""
        ) {
            dispatch(
                UpdateContentFileAction(
                    jsonToFormData({
                        ...formContentData,
                        SessionGuid: SessionGuid,
                    })
                )
            );
            setIsLoader(true);
        } else {
            toast.warning(`Something went wrong`);
        }
    };

    const handleDeleteVideo = (e) => {
        e.preventDefault();

        let body = {
            Client,
            SessionGuid,
            ContentFileId: formCreateChannel?.ContentFileId,
            CollectionId: formCreateChannel?.CollectionId
        }
        dispatch(
            DeleteContentFileAction(
                jsonToFormData({
                    ...body,
                    SessionGuid: SessionGuid,
                })
            )
        );
        localStorage.setItem("isClickDelete", "true");
    };

    const photoUpload = (e) => {
        e.preventDefault();
        setFormCreateChannel({ ...formCreateChannel, Icon: e.target.files[0] });
        setFormData({
            ...formContentData,
            Thumbnail: e.target.files[0],
        })
    };

    const videoUpload = (e) => {
        e.preventDefault();
        setFormData({
            ...formContentData,
            Video: e.target.files[0],
            Url: e.target.value,
        })
        setFormCreateChannel({
            ...formCreateChannel,
            Video: e.target.files[0],
            Url: e.target.value,
        });
    };

    const handleContentFileListUpdate = (item = {}, Active = "") => {
        setIsClickUpdate(true);
        let Expires = "";
        if (item?.Expires !== "") {
            const inputDate = moment(item?.Expires);
            Expires = inputDate.format('YYYY-MM-DDTHH:mm:ss.SSS');
        }
        let body = {
            Client,
            SessionGuid,
            Active,
            MaxViews: item?.MaxViews,
            Notes: item?.Notes,
            Expires,
            ContentFileLinkId: item?.ContentFileLinkId
        }
        dispatch(ContentFileLinkUpdateAction(jsonToFormData(body)));
    };

    const handleLinkContents = (type = "", isOppositeLength = false) => {
        let linkData = ContentFileLinkList?.active?.ContentFile_ListContentFileLinks?.ArrayOfContentFileLink?.ContentFileLink || [];
        if (type === "inactive") {
            linkData = ContentFileLinkList?.inactive?.ContentFile_ListContentFileLinks?.ArrayOfContentFileLink?.ContentFileLink || [];
        }

        if (linkData && !Array.isArray(linkData)) {
            linkData = [linkData]; // Convert the object to an array with a single element
        }

        if (!Array.isArray(linkData)) {
            linkData = []; // Set it to an empty array if it's still not an array
        }
        return (
            <div className={(linkData?.length === 3 || linkData?.length === 4) && isOppositeLength
                ? ""
                : linkData?.length > 2 && isOppositeLength
                    ? "managed_link_wrapper h-inc"
                    : linkData?.length > 2 && !isOppositeLength
                        ? "managed_link_wrapper"
                        : ""}>
                {linkData?.length
                    ? linkData?.map((item, index) => {
                        let formattedDate = "Never";
                        if (item?.Expires) {
                            // Parse the input date using Moment.js
                            const parsedDate = moment(item?.Expires, 'MM/DD/YYYY HH:mm:ss.SSS');

                            // Format the date in the desired format
                            formattedDate = parsedDate.format('MMM DD, YYYY - hh:mm A');
                        }
                        return <div
                            id={`linkItem_${type}_${index}`} // Unique id for each div
                            onClick={() => {
                                setSelectedIndex(`${type}_${index}`);
                                setIsMore((prevState) => ({
                                    ...prevState,
                                    [`${type}_${index}`]: !isMore?.[`${type}_${index}`],
                                }));
                            }}
                        >
                            <div className="w-100">
                                <div className="d-flex justify-content-between">
                                    <div className={linkData?.length > 2 && isOppositeLength ? "mx-3 my-1 d-flex" : "mx-3 my-2 d-flex"}>
                                        <div
                                            className="more_img_wrapper"
                                        >
                                            <img
                                                src={more_carrat}
                                                className={isMore?.[`${type}_${index}`] ? "more_img transform" : "more_img"}
                                            />
                                        </div>
                                        <div>
                                            <a
                                                title="managed_link"
                                                target="_blank"
                                                href={`${item?.ShortUrl?.split(":")?.[0]}s:${item?.ShortUrl?.split(":")?.[1]}`}
                                                style={{ color: "#c2c2c2", marginLeft: "8px" }}
                                            >
                                                {`${item?.ShortUrl?.split(":")?.[0]}s:${item?.ShortUrl?.split(":")?.[1]}`}
                                            </a> - {item?.Notes}
                                            <div>View Count: {item?.ViewCount} of {item?.MaxViews ? item?.MaxViews : "Unlimited"}</div>
                                            <div>Expires: {formattedDate}</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {isMore?.[`${type}_${index}`] &&
                                        <div className="d-flex mb-2">
                                            {item?.Active === "True"
                                                ? <EQButton
                                                    className="btn mx-1 rounded-0 remove_btn eq_button"
                                                    label="Edit"
                                                    onClick={() => { setIsClickAdd(true); setEditLinkData(item); setIsDuplicate(false); }}
                                                />
                                                : <EQButton
                                                    className="btn mx-1 rounded-0 remove_btn eq_button"
                                                    label="Enable"
                                                    onClick={() => handleContentFileListUpdate(item, "Y")}
                                                />
                                            }
                                            <EQButton
                                                className="btn mx-1 rounded-0 remove_btn eq_button"
                                                label="Duplicate"
                                                onClick={() => { setIsClickAdd(true); setEditLinkData(item); setIsDuplicate(true); }}
                                            />
                                            {item?.Active === "True"
                                                ? <EQButton
                                                    className="btn mx-1 rounded-0 remove_btn eq_button"
                                                    label="Disable"
                                                    onClick={() => handleContentFileListUpdate(item, "N")}
                                                />
                                                : <EQButton
                                                    className="btn mx-1 rounded-0 remove_btn eq_button"
                                                    label="Delete"
                                                    onClick={() => { setIsClickAdd(true); setIsDelete(true); setEditLinkData(item); }}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    })
                    : <div className="mx-1 mb-3">No Links</div>
                }
            </div >
        )
    }


    let activeLinkData = ContentFileLinkList?.active?.ContentFile_ListContentFileLinks?.ArrayOfContentFileLink?.ContentFileLink || [];
    let inactiveLinkData = ContentFileLinkList?.inactive?.ContentFile_ListContentFileLinks?.ArrayOfContentFileLink?.ContentFileLink || [];

    if (activeLinkData && !Array.isArray(activeLinkData)) {
        activeLinkData = [activeLinkData]; // Convert the object to an array with a single element
    }

    if (!Array.isArray(activeLinkData)) {
        activeLinkData = []; // Set it to an empty array if it's still not an array
    }
    if (inactiveLinkData && !Array.isArray(inactiveLinkData)) {
        inactiveLinkData = [inactiveLinkData]; // Convert the object to an array with a single element
    }

    if (!Array.isArray(inactiveLinkData)) {
        inactiveLinkData = []; // Set it to an empty array if it's still not an array
    }
    return (
        <>
            {/* modal Popup */}
            <div
                className="modal fade edit-video-modal"
                id="editModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div
                    className={isClickAdd || isDeleteVideo ? "modal-dialog modal-dialog-centered modal-xl w-40r" : "modal-dialog modal-dialog-centered modal-xl"}
                    style={{ zIndex: "initial" }}
                >
                    <div className="modal-content radius_none eq_grey">
                        <div className="modal-header">
                            <h5 className="modal-title text-white" id="exampleModalLabel">
                                {isDeleteVideo
                                    ? "Delete Video?"
                                    : isDelete
                                        ? "Delete Link"
                                        : isClickAdd && Object.keys(editLinkData)?.length && !isDuplicate
                                            ? "Edit Managed Link"
                                            : (isClickAdd || isDuplicate)
                                                ? "Create Managed Link"
                                                : "Edit Video Information"
                                }
                            </h5>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Grey_close_x.svg/1024px-Grey_close_x.svg.png"
                                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                data-bs-dismiss={!isDeleteVideo && !isClickAdd ? "modal" : ""}
                                aria-label={!isDeleteVideo && !isClickAdd ? "Close" : ""}
                                onClick={
                                    isDeleteVideo
                                        ? () => setIsDeleteVideo(false)
                                        : isDelete
                                            ? () => { setIsDelete(false); setIsClickAdd(false); }
                                            : isClickAdd && Object.keys(editLinkData)?.length && isDuplicate
                                                ? () => { setIsClickAdd(false); setEditLinkData({}); setIsDuplicate(false) }
                                                : isClickAdd && Object.keys(editLinkData)?.length && !isDuplicate
                                                    ? () => { setIsClickAdd(false); setEditLinkData({}) }
                                                    : isClickAdd
                                                        ? () => setIsClickAdd(false)
                                                        : Object.keys(editLinkData)?.length
                                                            ? setEditLinkData({})
                                                            : () => { }
                                }
                            />
                        </div>
                        {isDeleteVideo
                            ? <div class="modal-body">
                                <div className="row h-100 row-cols-1 text-white">
                                    <h6 style={{ fontSize: "17px" }}>This video will be permanently deleted.</h6>
                                    <h6 style={{ fontSize: "17px" }} className="mt-1 mb-5">Are you sure?</h6>
                                </div>
                            </div>
                            : isClickAdd
                                ? <div className="modal-body manage-link-modal">
                                    <ManagedLinksModal
                                        videoId={selectedItem?.ContentFileId}
                                        isClickAdd={isClickAdd}
                                        setIsClickAdd={setIsClickAdd}
                                        fetchContentFileLinkList={fetchContentFileLinkList}
                                        editLinkData={editLinkData}
                                        setEditLinkData={setEditLinkData}
                                        setIsClickUpdate={setIsClickUpdate}
                                        isDuplicate={isDuplicate}
                                        setIsDuplicate={setIsDuplicate}
                                        isDelete={isDelete}
                                        setIsDelete={setIsDelete}
                                        origin={"EditVideoModal"}
                                        handleClearEditModalData={handleClearEditModalData}
                                    />
                                </div>
                                : <div className="modal-body">
                                    {!formCreateChannel?.Name || !userListCollection?.length
                                        ? <div class="text-center d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                                            <div
                                                class="spinner-border text-white"
                                                role="status"
                                            >
                                                <span class="visually-hidden">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                        : <div className="d-flex justify-content-between">
                                            <div className="row h-100 row-cols-1" style={{ width: "60%" }}>
                                                < div className="d-flex flex-column justify-content-center align-items-center">
                                                    <form
                                                        className="needs-validation editVideoForm"
                                                        // method="post"
                                                        encType="multipart/form-data"
                                                        onSubmit={(e) => createChannelHandler(e)}
                                                        novalidate
                                                    >
                                                        <div className="my-3 position-relative">
                                                            <div className="d-flex w-100">
                                                                <div className="label w-50">Name:</div>
                                                                <div className="w-100">
                                                                    <input
                                                                        id="inputID"
                                                                        name="Name"
                                                                        value={formCreateChannel?.Name}
                                                                        onChange={handleFormData}
                                                                        type="text"
                                                                        className="form-control element w-50"
                                                                        placeholder="Channel Name"
                                                                        required
                                                                    />
                                                                    <div className="mt-2">
                                                                        {!formCreateChannel?.Name
                                                                            && <div style={{ color: "red" }}>Name field is required</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="text-danger fs-5"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: -8,
                                                                        right: -15,
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="my-3 position-relative">
                                                            <div className="d-flex w-100">
                                                                <div className="label w-50">Description:</div>
                                                                <div className="w-100">
                                                                    <textarea
                                                                        id="inputID"
                                                                        rows="4"
                                                                        cols="50"
                                                                        name="Description"
                                                                        value={formCreateChannel?.Description}
                                                                        onChange={handleFormData}
                                                                        type="text"
                                                                        className="textarea-control"
                                                                        placeholder="Add Channel Description"
                                                                    >
                                                                    </textarea>
                                                                    <div className="text-white">
                                                                        To include keywords just enter hashtags (e.g. #Olympics #GoldMedal #Swimming) as part of your description or as part of the name of the video.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="my-3 position-relative">
                                                            <div className="d-flex w-100">
                                                                <div className="label w-50">Categories:</div>
                                                                <div className="w-100">
                                                                    <MultiSelect
                                                                        options={option}
                                                                        value={selected}
                                                                        onChange={setSelected}
                                                                        labelledBy="Select"
                                                                        className="w-100 text-white"
                                                                    />
                                                                    <div className="mt-2">
                                                                        {!selected?.length
                                                                            && <div style={{ color: "red" }}>Categories field is required</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="text-danger fs-5"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: -8,
                                                                        right: -15,
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="my-3 position-relative">
                                                            <div className="d-flex w-100">
                                                                <div className="label w-50">Channel:</div>
                                                                <div className="w-100">
                                                                    <select
                                                                        name="Channel"
                                                                        className=" form-select form-select-sm form-control option element w-50"
                                                                        aria-label=".form-select-lg example"
                                                                        value={channel}
                                                                        onChange={(e) => handleFormData(e, "channel")}
                                                                        required
                                                                    >
                                                                        <option selected value="">
                                                                            Select Channel
                                                                        </option>
                                                                        {userListCollection?.map((item, i) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={item.Name}>{item.Name}</option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    <div className="mt-2">
                                                                        {!channel
                                                                            && <div style={{ color: "red" }}>Channel field is required</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="text-danger fs-5"
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: -8,
                                                                        right: -15,
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                                            <div className="d-flex w-100">
                                                                <div className="label w-50">Replace Video:</div>
                                                                <input
                                                                    ref={videoRef}
                                                                    onChange={videoUpload}
                                                                    accept="video/mp4,video/x-m4v,video/*"
                                                                    id="contained-button-file"
                                                                    // hidden
                                                                    multiple
                                                                    type="file"
                                                                    required
                                                                    className="element text-white mb-3 w-50"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                                            <div className="d-flex w-100">
                                                                <div className="label w-50">Thumbnail:</div>
                                                                <input
                                                                    ref={imageRef}
                                                                    onChange={photoUpload}
                                                                    accept="image/*"
                                                                    id="contained-button-file"
                                                                    // hidden
                                                                    multiple
                                                                    type="file"
                                                                    required
                                                                    className="element text-white mb-3 w-50"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                display: "flex ",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <EQButton
                                                                className="btn my-2 mb-5 btn eq_button text-white w-50 mr-1"
                                                                type="button"
                                                                onClick={(e) => createChannelHandler(e)}
                                                                disabled={formContentData?.Name === "" || !selected?.length || formContentData?.Channel === ""}
                                                                label="SUBMIT"
                                                                data-bs-dismiss="modal"
                                                                model="true"
                                                            />
                                                            <EQButton
                                                                type="button"
                                                                className="btn my-2 mb-5 btn eq_button text-white w-50 mr-1"
                                                                label="CLOSE"
                                                                model="true"
                                                                data-bs-dismiss="modal"
                                                                onClick={() => setModalClose(false)}
                                                            />
                                                            <Button
                                                                type="button"
                                                                className="btn my-2 mb-5 btn eq_button text-white w-50 mr-1"
                                                                onClick={() => setIsDeleteVideo(!isDeleteVideo)}
                                                            >
                                                                DELETE
                                                            </Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="invitation_section mt-3 h-100" style={{ width: "37%", paddingBottom: "10px" }}>
                                                <div className="invitation_header d-flex justify-content-between">
                                                    <h4 style={{
                                                        color: "#ffffff",
                                                        marginTop: "0.5rem"
                                                    }}>
                                                        Managed Links
                                                    </h4>
                                                    <Button
                                                        className="btn eq_button my-2 mx-2 text-white pointer add_invite_btn"
                                                        onClick={() => setIsClickAdd(true)}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                                <div className="px-3 mt-2">
                                                    <h5>Active</h5>
                                                    <div className={activeLinkData?.length >= 3 && inactiveLinkData?.length < 2 ? "expand" : ""}>
                                                        {handleLinkContents("active", !inactiveLinkData?.length)}
                                                    </div>
                                                    <h5>Inactive</h5>
                                                    <div className={inactiveLinkData?.length >= 3 && activeLinkData?.length < 2 ? "expand" : ""}>
                                                        {handleLinkContents("inactive", !activeLinkData?.length)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                        }
                        {isDeleteVideo
                            ? <div className="modal-footer">
                                <Button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    style={{
                                        margin: "10px",
                                        height: "40px"
                                    }}
                                    onClick={(e) => { handleDeleteVideo(e); setIsDeleteVideo(false) }}
                                >
                                    Delete Video
                                </Button>
                                <Button
                                    type="button"
                                    style={{
                                        margin: "10px",
                                        height: "40px"
                                    }}
                                    onClick={() => { setModalClose(false); setIsDeleteVideo(false) }}
                                >
                                    Cancel
                                </Button>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditVideoModal;
