import {
  SEARCH_CONTENT_FILES_REQUEST,
  SEARCH_CONTENT_FILES_SUCCESS,
  SEARCH_CONTENT_FILES_FAIL,
  SEARCH_CONTENT_FILES_CONDITION,
  SEARCH_CONTENT_FILES_RESPONSE_CLEARE,
} from "../types";

export const Search_ContentFilesReducer = (
  state = {
    SearchContentFiles: {
      home: [],
      trending: [],
      featured: [],
      mostpopular: [],
      mostrecent: [],
      channelhome: [],
      channelvideos: [],
      searched: [],
      filter: [],
    },
  },
  action
) => {
  switch (action.type) {
    case SEARCH_CONTENT_FILES_REQUEST:
      return { ...state, loading: true };
    case SEARCH_CONTENT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        SearchContentFiles: {
          ...state.SearchContentFiles,
          [action.payload.category]: action.payload.data,
        },
      };
    case SEARCH_CONTENT_FILES_FAIL:
      return { ...state, loading: false, SearchContentFiles: action.payload };

    case SEARCH_CONTENT_FILES_RESPONSE_CLEARE:
      return { ...state, loading: false, SearchContentFiles: "" };

    case SEARCH_CONTENT_FILES_CONDITION:
      return {
        loading: false,
        ...state,
        SearchContentFiles: {
          ...state.SearchContentFiles,
          [action.payload.category]: action.payload.data,
        },
      };
    default:
      return state;
  }
};
