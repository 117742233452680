import { xml2json } from "../../utils";
import {
  COLLECTION_LISTCONTENT_FILES_REQUEST,
  COLLECTION_LISTCONTENT_FILES_SUCCESS,
  COLLECTION_LISTCONTENT_FILES_FAIL,
  COLLECTION_LISTCONTENT_RESPONSE_CLEARE,
  COLLECTION_GETNODE_REQUEST,
  COLLECTION_GETNODE_SUCCESS,
  COLLECTION_GETNODE_FAIL,
} from "../types";

import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

// particular channel CollectionListContentFilesAction

export const CollectionListContentFilesAction =
  (formData, category) => async (dispatch) => {
    let data;
    let videoSec = [];
    try {
      dispatch({
        type: COLLECTION_LISTCONTENT_FILES_REQUEST,
      });
      data = await axiosInstance.post(
        endPoint.COLLECTION_LISTCONTENT_FILES,
        formData
      );
      const res = `${data.data}`;
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(res, "text/xml");
      data = xml2json(xmlDoc);
      if (data?.Response?.Error) {
        return dispatch({
          type: COLLECTION_LISTCONTENT_FILES_FAIL,
          payload: data,
        });
      } else {
        data = data?.Response?.ContentFiles?.ContentFile;
        if (!data) {
          dispatch({
            type: COLLECTION_LISTCONTENT_FILES_SUCCESS,
            payload: { data: videoSec, category: category },
          });
          return videoSec
        };

        if (data.length > 0) {
          videoSec = videoSec.concat(data);
        } else {
          videoSec.push(data);
        }
        dispatch({
          type: COLLECTION_LISTCONTENT_FILES_SUCCESS,
          payload: { data: videoSec, category: category },
        });
      }
    } catch (error) {
      dispatch({
        type: COLLECTION_LISTCONTENT_FILES_FAIL,
        payload: data,
      });
    }
  };

export const CollectionListContentResponse = () => {
  return { type: COLLECTION_LISTCONTENT_RESPONSE_CLEARE };
};

export const AboutCollectionGetNodeAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: COLLECTION_GETNODE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.COLLECTION_GETNODE, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: COLLECTION_GETNODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_GETNODE_FAIL,
      payload: data,
    });
  }
};
