import {
  USER_GET_NODE_REQUEST,
  USER_GET_NODE_SUCCESS,
  USER_GET_NODE_FAIL,
  USER_GET_NODE_CLEAR
} from "../types";
export const UserDetailsGetNodeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_GET_NODE_REQUEST:
      return { loading: true };
    case USER_GET_NODE_SUCCESS:
      if (action.payload?.hasOwnProperty("category")) {
        // Called at request to follow/contribute/view UI exist
        return { loading: false, UserDetailsGetNode: { [action.payload?.category]: action.payload?.User_GetNode?.User } };
      } else {
        return { loading: false, UserDetailsGetNode: action.payload };
      }
    case USER_GET_NODE_FAIL:
      return { loading: false, UserDetailsgetNode: action.payload };
    case USER_GET_NODE_CLEAR:
      return { loading: false, UserDetailsgetNode: {} };
    default:
      return state;
  }
};
