

import {
    LIST_CATEGORIES_REQUEST,
    LIST_CATEGORIES_SUCCESS,
    LIST_CATEGORIES_FAIL,
//   USER_REQUEST_PASSWORD_REQUEST,
//   USER_REQUEST_PASSWORD_SUCCESS,
//   USER_REQUEST_PASSWORD_FAIL,
} from "../types";
export const ListCategoriesReducer = (state = {}, action) => {
    switch (action.type) {
      case LIST_CATEGORIES_REQUEST:
        return { loading: true };
      case LIST_CATEGORIES_SUCCESS:
        return { loading: false,ListCategories: action.payload };
      case LIST_CATEGORIES_FAIL:
        return { loading: false,ListCategories: action.payload };
      default:
        return state;
    }
  };
  