import {
  HASHTAG_TRENDING_REQUEST,
  HASHTAG_TRENDING_SUCCESS,
  HASHTAG_TRENDING_FAIL,
} from "../types";

export const TrendingListReducer = (state = {}, action) => {
  switch (action.type) {
    case HASHTAG_TRENDING_REQUEST:
      return { loading: true };
    case HASHTAG_TRENDING_SUCCESS:
      return { loading: false, TrendingList: action.payload };
    case HASHTAG_TRENDING_FAIL:
      return { loading: false, TrendingList: action.payload };
    default:
      return state;
  }
};
