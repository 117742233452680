import { xml2json } from "../../utils";
import {
    INVITAION_CREATE_FAIL,
    INVITAION_CREATE_REQUEST,
    INVITAION_CREATE_SUCCESS,
    INVITAION_CREATE_CLEAR,
    INVITAION_DELETE_FAIL,
    INVITAION_DELETE_REQUEST,
    INVITAION_DELETE_SUCCESS,
    INVITAION_DELETE_CLEAR,
    INVITAION_RESEND_FAIL,
    INVITAION_RESEND_REQUEST,
    INVITAION_RESEND_SUCCESS,
    INVITAION_RESEND_CLEAR,
    REQUEST_GET_COLLECTION_FAIL,
    REQUEST_GET_COLLECTION_REQUEST,
    REQUEST_GET_COLLECTION_SUCCESS,
    REQUEST_LIST_FAIL,
    REQUEST_LIST_REQUEST,
    REQUEST_LIST_SUCCESS,
    REQUEST_LIST_CLEAR,
    INVITE_LIST_FAIL,
    INVITE_LIST_REQUEST,
    INVITE_LIST_SUCCESS,
    INVITE_LIST_CLEAR,
    INVITE_UPDATE_FAIL,
    INVITE_UPDATE_REQUEST,
    INVITE_UPDATE_SUCCESS,
    INVITE_UPDATE_CLEAR,
    REQUEST_DECLINE_FAIL,
    REQUEST_DECLINE_REQUEST,
    REQUEST_DECLINE_SUCCESS,
    REQUEST_DECLINE_CLEAR,
    REQUEST_ACCEPT_FAIL,
    REQUEST_ACCEPT_REQUEST,
    REQUEST_ACCEPT_SUCCESS,
    REQUEST_ACCEPT_CLEAR,
    REQUEST_REMOVE_FAIL,
    REQUEST_REMOVE_REQUEST,
    REQUEST_REMOVE_SUCCESS,
    REQUEST_REMOVE_CLEAR,
    INVITATION_LIST_TO_ME_FAIL,
    INVITATION_LIST_TO_ME_REQUEST,
    INVITATION_LIST_TO_ME_SUCCESS,
    INVITATION_LIST_TO_ME_CLEAR,
    INVITATION_CONFIRM_FAIL,
    INVITATION_CONFIRM_REQUEST,
    INVITATION_CONFIRM_SUCCESS,
    INVITATION_ACCEPT_FAIL,
    INVITATION_ACCEPT_REQUEST,
    INVITATION_ACCEPT_SUCCESS,
    INVITATION_ACCEPT_CLEAR,
    INVITATION_DECLINE_FAIL,
    INVITATION_DECLINE_REQUEST,
    INVITATION_DECLINE_SUCCESS,
    INVITATION_DECLINE_CLEAR,
    REQUEST_CREATE_FAIL,
    REQUEST_CREATE_REQUEST,
    REQUEST_CREATE_SUCCESS,
    REQUEST_CREATE_CLEAR,
    COLLECTION_ADD_RELATIONSHIP_FAIL,
    COLLECTION_ADD_RELATIONSHIP_REQUEST,
    COLLECTION_ADD_RELATIONSHIP_SUCCESS,
    COLLECTION_ADD_RELATIONSHIP_CLEAR,
} from "../types";

import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

export const InvitationCreateAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITAION_CREATE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITAION_CREATE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITAION_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITAION_CREATE_FAIL,
            payload: data,
        });
    }
};

export const InvitationResendAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITAION_RESEND_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITAION_RESEND, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITAION_RESEND_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITAION_RESEND_FAIL,
            payload: data,
        });
    }
};

export const InvitationDeleteAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITAION_DELETE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITAION_DELETE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITAION_DELETE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITAION_DELETE_FAIL,
            payload: data,
        });
    }
};

export const requestGetCollectionAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: REQUEST_GET_COLLECTION_REQUEST,
        });
        data = await axiosInstance.post(endPoint.REQUEST_GET_COLLECTION, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: REQUEST_GET_COLLECTION_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_GET_COLLECTION_FAIL,
            payload: data,
        });
    }
};

export const requestListAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: REQUEST_LIST_REQUEST,
        });
        data = await axiosInstance.post(endPoint.REQUEST_LIST, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: REQUEST_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_LIST_FAIL,
            payload: data,
        });
    }
};

export const inviteListAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITE_LIST_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITE_LIST, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITE_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITE_LIST_FAIL,
            payload: data,
        });
    }
};

export const invitationUpdateAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITE_UPDATE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITE_UPDATE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITE_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITE_UPDATE_FAIL,
            payload: data,
        });
    }
};

export const requestDeclineAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: REQUEST_DECLINE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.REQUEST_DECLINE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: REQUEST_DECLINE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_DECLINE_FAIL,
            payload: data,
        });
    }
};

export const requestAcceptAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: REQUEST_ACCEPT_REQUEST,
        });
        data = await axiosInstance.post(endPoint.REQUEST_ACCEPT, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: REQUEST_ACCEPT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_ACCEPT_FAIL,
            payload: data,
        });
    }
};

export const requestRemoveAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: REQUEST_REMOVE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.REQUEST_REMOVE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: REQUEST_REMOVE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_REMOVE_FAIL,
            payload: data,
        });
    }
};

export const invitationsToMeAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITATION_LIST_TO_ME_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITATION_LIST_TO_ME, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITATION_LIST_TO_ME_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITATION_LIST_TO_ME_FAIL,
            payload: data,
        });
    }
};

export const invitationConfirmAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITATION_CONFIRM_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITATION_CONFIRM, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITATION_CONFIRM_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITATION_CONFIRM_FAIL,
            payload: data,
        });
    }
};

export const invitationAcceptAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITATION_ACCEPT_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITATION_ACCEPT, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITATION_ACCEPT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITATION_ACCEPT_FAIL,
            payload: data,
        });
    }
};

export const invitationDeclineAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: INVITATION_DECLINE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.INVITATION_DECLINE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: INVITATION_DECLINE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: INVITATION_DECLINE_FAIL,
            payload: data,
        });
    }
};

export const RequestCreateAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: REQUEST_CREATE_REQUEST,
        });
        data = await axiosInstance.post(endPoint.REQUEST_CREATE, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: REQUEST_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_CREATE_FAIL,
            payload: data,
        });
    }
};

export const collectionAddRelationshipAction = (formData) => async (dispatch) => {
    let data;
    try {
        dispatch({
            type: COLLECTION_ADD_RELATIONSHIP_REQUEST,
        });
        data = await axiosInstance.post(endPoint.COLLECTION_ADD_RELATIONSHIP, formData);
        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: COLLECTION_ADD_RELATIONSHIP_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COLLECTION_ADD_RELATIONSHIP_FAIL,
            payload: data,
        });
    }
};

export const collectionAddRelationshipClear = () => {
    return { type: COLLECTION_ADD_RELATIONSHIP_CLEAR };
};

export const invitationResendClear = () => {
    return { type: INVITAION_RESEND_CLEAR };
};

export const invitationDeleteClear = () => {
    return { type: INVITAION_DELETE_CLEAR };
};

export const invitationUpdateClear = () => {
    return { type: INVITE_UPDATE_CLEAR };
};

export const inviteListClear = () => {
    return { type: INVITE_LIST_CLEAR };
};

export const inviteCreateClear = () => {
    return { type: INVITAION_CREATE_CLEAR };
};

export const invitationsToMeClear = () => {
    return { type: INVITATION_LIST_TO_ME_CLEAR };
};

export const invitationAcceptClear = () => {
    return { type: INVITATION_ACCEPT_CLEAR };
};

export const invitationDeclineClear = () => {
    return { type: INVITATION_DECLINE_CLEAR };
};

export const RequestCreateClear = () => {
    return { type: REQUEST_CREATE_CLEAR };
};

export const RequestListClear = () => {
    return { type: REQUEST_LIST_CLEAR };
};


export const requestDeclineClear = () => {
    return { type: REQUEST_DECLINE_CLEAR };
};

export const requestAcceptClear = () => {
    return { type: REQUEST_ACCEPT_CLEAR };
};

export const requestRemoveClear = () => {
    return { type: REQUEST_REMOVE_CLEAR };
};