import {
  USER_EMAIL_ACCOUNTS_REQUEST,
  USER_EMAIL_ACCOUNTS_SUCCESS,
  USER_EMAIL_ACCOUNTS_FAIL,
  USER_REQUEST_PASSWORD_REQUEST,
  USER_REQUEST_PASSWORD_SUCCESS,
  USER_REQUEST_PASSWORD_FAIL,
  FORGOT_USERNAME_RESPONSE_CLEARE,
  FORGOT_PASSWORD_RESPONSE_CLEARE,
} from "../types";

export const ForgotUsernameReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EMAIL_ACCOUNTS_REQUEST:
      return { loading: true };
    case USER_EMAIL_ACCOUNTS_SUCCESS:
      return { loading: false, forgotUsername: action.payload };
    case USER_EMAIL_ACCOUNTS_FAIL:
      return { loading: false, forgotUsername: action.payload };
    case FORGOT_USERNAME_RESPONSE_CLEARE:
      return { loading: false, forgotUsername: "" };
    default:
      return state;
  }
};

export const ForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REQUEST_PASSWORD_REQUEST:
      return { loading: true };
    case USER_REQUEST_PASSWORD_SUCCESS:
      return { loading: false, forgotPassword: action.payload };
    case USER_REQUEST_PASSWORD_FAIL:
      return { loading: false, forgotPassword: action.payload };
    case FORGOT_PASSWORD_RESPONSE_CLEARE:
      return { loading: false, forgotPassword: "" };
    default:
      return state;
  }
};
