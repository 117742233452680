import { xml2json } from "../../utils";
import {
  CONTENT_FILE_GET_NODE_REQUEST,
  CONTENT_FILE_GET_NODE_SUCCESS,
  CONTENT_FILE_GET_NODE_FAIL,
  CONTENT_FILE_CREATE_REQUEST,
  CONTENT_FILE_CREATE_SUCCESS,
  CONTENT_FILE_CREATE_FAIL,
  CONTENT_FILE_VIEWED_REQUEST,
  CONTENT_FILE_VIEWED_SUCCESS,
  CONTENT_FILE_VIEWED_FAIL,
  CONTENT_FILE_UPDATE_REQUEST,
  CONTENT_FILE_UPDATE_SUCCESS,
  CONTENT_FILE_UPDATE_FAIL,
  CONTENT_FILE_DELETE_REQUEST,
  CONTENT_FILE_DELETE_SUCCESS,
  CONTENT_FILE_DELETE_FAIL,
  CHANGE_CONTENT_FILE_REQUEST,
  CHANGE_CONTENT_FILE_SUCCESS,
  CHANGE_CONTENT_FILE_FAIL,
  CONTENT_FILE_LINK_CREATE_REQUEST,
  CONTENT_FILE_LINK_CREATE_SUCCESS,
  CONTENT_FILE_LINK_CREATE_FAIL,
  CONTENT_FILE_LINK_CREATE_CLEAR,
  CONTENT_FILE_LINKS_REQUEST,
  CONTENT_FILE_LINKS_SUCCESS,
  CONTENT_FILE_LINKS_FAIL,
  CONTENT_FILE_LINKS_UPDATE_REQUEST,
  CONTENT_FILE_LINKS_UPDATE_SUCCESS,
  CONTENT_FILE_LINKS_UPDATE_FAIL,
  CONTENT_FILE_LINKS_UPDATE_CLEAR,
  CONTENT_FILE_LINKS_DELETE_REQUEST,
  CONTENT_FILE_LINKS_DELETE_SUCCESS,
  CONTENT_FILE_LINKS_DELETE_FAIL,
  CONTENT_FILE_LINKS_DELETE_CLEAR,
  CONTENT_FILE_DELETE_CLEAR,
} from "../types";

import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

// UserDetailsgetNodeAction
export const ContentFileGetNodeAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_GET_NODE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CONTENT_FILE_GETNODE, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_GET_NODE_SUCCESS,
      payload: data?.ContentFile_GetNode?.ContentFile,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_GET_NODE_FAIL,
      payload: data,
    });
  }
};

// file viewed

export const ContentFileViewedAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_VIEWED_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CONTENT_FILE_VIEWED, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_VIEWED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_VIEWED_FAIL,
      payload: data,
    });
  }
};

// UserDetailsgetNodeAction
export const CreateContentFileAction = (reqData) => async (dispatch) => {
  let data;

  try {
    dispatch({
      type: CONTENT_FILE_CREATE_REQUEST,
    });

    data = await axiosInstance.post(endPoint.CREATE_CONTENT_FILE, reqData, {
      timeout: 86400000
    });
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_CREATE_FAIL,
      payload: error,
    });
  }
};

export const UpdateContentFileAction = (reqData) => async (dispatch) => {
  let data;

  try {
    dispatch({
      type: CONTENT_FILE_UPDATE_REQUEST,
    });

    data = await axiosInstance.post(endPoint.UPDATE_CONTENT_FILE, reqData, {
      timeout: 86400000,
    });
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_UPDATE_FAIL,
      payload: error,
    });
  }
};

export const DeleteContentFileAction = (formData) => async (dispatch) => {
  let data;

  try {
    dispatch({
      type: CONTENT_FILE_DELETE_REQUEST,
    });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    data = await axiosInstance.post(
      endPoint.DELETE_CONTENT_FILE,
      formData,
      config
    );

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_DELETE_FAIL,
      payload: data,
    });
  }
};

export const ChangeContentFileAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CHANGE_CONTENT_FILE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CHANGE_CONTENT_FILE_COLLECTION, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CHANGE_CONTENT_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHANGE_CONTENT_FILE_FAIL,
      payload: data,
    });
  }
};

// ContentFileLinkCreateAction
export const ContentFileLinkCreateAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_LINK_CREATE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CONTENT_FILE_LINK_CREATE, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_LINK_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_LINK_CREATE_FAIL,
      payload: data,
    });
  }
};

// Content File List Links
export const ContentFileLinkListAction = (formData, type) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_LINKS_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CONTENT_FILE_LINKS, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_LINKS_SUCCESS,
      payload: { data, type },
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_LINKS_FAIL,
      payload: data,
    });
  }
};

// Content File List Links Update
export const ContentFileLinkUpdateAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_LINKS_UPDATE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CONTENT_FILE_LINKS_UPDATE, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_LINKS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_LINKS_UPDATE_FAIL,
      payload: data,
    });
  }
};

// Content File List Links delete
export const ContentFileLinkDeleteAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_LINKS_DELETE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.CONTENT_FILE_LINKS_DELETE, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_LINKS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_LINKS_DELETE_FAIL,
      payload: data,
    });
  }
};


export const ContentFileDeleteClear = () => {
  return { type: CONTENT_FILE_DELETE_CLEAR };
};

export const ContentFileLinkCreateClear = () => {
  return { type: CONTENT_FILE_LINK_CREATE_CLEAR };
};

export const ContentFileLinkUpdateClear = () => {
  return { type: CONTENT_FILE_LINKS_UPDATE_CLEAR };
};

export const ContentFileLinkDeleteClear = () => {
  return { type: CONTENT_FILE_LINKS_DELETE_CLEAR };
};