import {
  HOME_SEARCH_CONTENT_FILE_REQUEST,
  HOME_SEARCH_CONTENT_FILE_SUCCESS,
  HOME_SEARCH_CONTENT_FILE_FAIL,
  HOME_SEARCH_CONTENT_FILE_RESET,
} from "../types";

export const HomeSearchContentFilesReducer = (state = {}, action) => {
  switch (action.type) {
    case HOME_SEARCH_CONTENT_FILE_REQUEST:
      return { loading: true };
    case HOME_SEARCH_CONTENT_FILE_SUCCESS:
      return { loading: false, homeSearchContentFiles: action.payload };
    case HOME_SEARCH_CONTENT_FILE_FAIL:
      return { loading: false, homeSearchContentFiles: action.payload };
    case HOME_SEARCH_CONTENT_FILE_RESET:
      return { ...state, homeSearchContentFiles: "" };
    default:
      return state;
  }
};
