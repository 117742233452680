import {
  USER_LIST_COLLECTION_REQUEST,
  USER_LIST_COLLECTION_SUCCESS,
  USER_LIST_COLLECTION_FAIL,
  USER_LIST_COLLECTION_RESPONSE,

  COLLECTION_CREATE_REQUEST,
  COLLECTION_CREATE_SUCCESS,
  COLLECTION_CREATE_FAIL,
  COLLECTION_CREATE_RESPONSE,
  COLLECTION_UPDATE_REQUEST,
  COLLECTION_UPDATE_SUCCESS,
  COLLECTION_UPDATE_FAIL,
  COLLECTION_UPDATE_RESPONSE,

  COLLECTION_DELETE_REQUEST,
  COLLECTION_DELETE_SUCCESS,
  COLLECTION_DELETE_FAIL,
  COLLECTION_DELETE_RESPONSE
} from "../types";

export const UserListCollectionReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_COLLECTION_REQUEST:
      return { loading: true };
    case USER_LIST_COLLECTION_SUCCESS:
      return { loading: false, userListCollection: action.payload };
    case USER_LIST_COLLECTION_FAIL:
      return { loading: false, userListCollection: action.payload };
      case USER_LIST_COLLECTION_RESPONSE:
      return { loading: false, userlistcollectionresponse: "" };
    default:
      return state;
  }
};
export const CollectionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COLLECTION_CREATE_REQUEST:
      return { loading: true };
    case COLLECTION_CREATE_SUCCESS:
      return { loading: false, collectionCreate: action.payload };
    case COLLECTION_CREATE_FAIL:
      return { loading: false, collectionCreate: action.payload };
    case COLLECTION_CREATE_RESPONSE:
      return { loading: false, collectionCreate: "" };
    default:
      return state;
  }
};
export const CollectionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COLLECTION_UPDATE_REQUEST:
      return { loading: true };
    case COLLECTION_UPDATE_SUCCESS:
      return { loading: false, collectionUpdate: action.payload };
    case COLLECTION_UPDATE_FAIL:
      return { loading: false, collectionUpdate: action.payload };
    case COLLECTION_UPDATE_RESPONSE:
      return { loading: false, collectionUpdate: "" };
    default:
      return state;
  }
};

export const CollectioDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COLLECTION_DELETE_REQUEST:
      return { loading: true };
    case COLLECTION_DELETE_SUCCESS:
      return { loading: false, collectioDelete: action.payload };
    case COLLECTION_DELETE_FAIL:
      return { loading: false, collectionDelete: action.payload };
    case COLLECTION_DELETE_RESPONSE:
      return { loading: false, collectionDelete: {} };
    
    default:
      return state;
  }
};
