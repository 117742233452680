import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_RESET,
} from "../types";

export const SignUpReducer = (state = {}, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return { loading: true };
    case SIGNUP_SUCCESS:
      return { loading: false, userCreated: action.payload };
    case SIGNUP_FAIL:
      return { loading: false, userCreated: action.payload };
    case SIGNUP_RESET:
      return { ...state, userCreated: "" };

    default:
      return state;
  }
};
