import { xml2json } from "../../utils";
import {
    USER_LIST_USERS_FAIL,
    USER_LIST_USERS_REQUEST,
    USER_LIST_USERS_SUCCESS
} from "../types";

import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

export const UserListUsersAction = (formData) => async (dispatch) => {
    let data;

    try {
        dispatch({
            type: USER_LIST_USERS_REQUEST,
        });

        const config = {
            headers: { "content-type": "multipart/form-data" },
        };
        data = await axiosInstance.post(
            endPoint.USER_LIST_USERS,
            formData,
            config
        );

        const res = `${data.data}`;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(res, "text/xml");
        data = xml2json(xmlDoc);

        dispatch({
            type: USER_LIST_USERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: USER_LIST_USERS_FAIL,
            payload: data,
        });
    }
};