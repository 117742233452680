import { xml2json } from "../../utils";
import {
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESPONSE_REMOVE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
} from "../types";
import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

//userlogin
export const UpdateProfileAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.USER_UPDATE, formData);

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: data,
    });
  }
};

export const RemoveUpdateProfileResponse = () => {
  return { type: USER_UPDATE_RESPONSE_REMOVE };
};

//userlogin
export const DeleteProfileAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    data = await axiosInstance.post(endPoint.USER_DELETE, formData);

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload: data,
    });
  }
};
