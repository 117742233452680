import {
  CONTENT_FILE_GET_NODE_REQUEST,
  CONTENT_FILE_GET_NODE_SUCCESS,
  CONTENT_FILE_GET_NODE_FAIL,
  CONTENT_FILE_CREATE_REQUEST,
  CONTENT_FILE_CREATE_SUCCESS,
  CONTENT_FILE_CREATE_FAIL,
  CONTENT_FILE_UPDATE_REQUEST,
  CONTENT_FILE_UPDATE_SUCCESS,
  CONTENT_FILE_UPDATE_FAIL,
  CONTENT_FILE_VIEWED_REQUEST,
  CONTENT_FILE_VIEWED_SUCCESS,
  CONTENT_FILE_VIEWED_FAIL,
  CONTENT_FILE_DELETE_REQUEST,
  CONTENT_FILE_DELETE_SUCCESS,
  CONTENT_FILE_DELETE_FAIL,
  CONTENT_FILE_DELETE_CLEAR,
  CHANGE_CONTENT_FILE_REQUEST,
  CHANGE_CONTENT_FILE_SUCCESS,
  CHANGE_CONTENT_FILE_FAIL,
  CONTENT_FILE_LINK_CREATE_REQUEST,
  CONTENT_FILE_LINK_CREATE_SUCCESS,
  CONTENT_FILE_LINK_CREATE_FAIL,
  CONTENT_FILE_LINK_CREATE_CLEAR,
  CONTENT_FILE_LINKS_REQUEST,
  CONTENT_FILE_LINKS_SUCCESS,
  CONTENT_FILE_LINKS_FAIL,
  CONTENT_FILE_LINKS_CLEAR,
  CONTENT_FILE_LINKS_UPDATE_REQUEST,
  CONTENT_FILE_LINKS_UPDATE_SUCCESS,
  CONTENT_FILE_LINKS_UPDATE_FAIL,
  CONTENT_FILE_LINKS_UPDATE_CLEAR,
  CONTENT_FILE_LINKS_DELETE_REQUEST,
  CONTENT_FILE_LINKS_DELETE_SUCCESS,
  CONTENT_FILE_LINKS_DELETE_FAIL,
  CONTENT_FILE_LINKS_DELETE_CLEAR,
} from "../types";
export const ContentFileGetNodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_GET_NODE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_GET_NODE_SUCCESS:
      return { loading: false, ContentFileGetNode: action.payload };
    case CONTENT_FILE_GET_NODE_FAIL:
      return { loading: false, ContentFileGetNode: action.payload };
    default:
      return state;
  }
};
export const CreateContentFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_CREATE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_CREATE_SUCCESS:
      return { loading: false, CreateContentFile: action.payload };
    case CONTENT_FILE_CREATE_FAIL:
      return { loading: false, CreateContentFile: action.payload };
    default:
      return state;
  }
};
export const ContentFileViewedReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_VIEWED_REQUEST:
      return { loading: true };
    case CONTENT_FILE_VIEWED_SUCCESS:
      return { loading: false, ContentFileViewed: action.payload };
    case CONTENT_FILE_VIEWED_FAIL:
      return { loading: false, ContentFileViewed: action.payload };
    default:
      return state;
  }
};

export const UpdateContentFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_UPDATE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_UPDATE_SUCCESS:
      return { loading: false, UpdateContentFile: action.payload };
    case CONTENT_FILE_UPDATE_FAIL:
      return { loading: false, UpdateContentFile: action.payload };
    default:
      return state;
  }
};

export const DeleteContentFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_DELETE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_DELETE_SUCCESS:
      return { loading: false, DeleteContentFile: action.payload };
    case CONTENT_FILE_DELETE_FAIL:
      return { loading: false, DeleteContentFile: action.payload };
    case CONTENT_FILE_DELETE_CLEAR:
      return { loading: false, DeleteContentFile: {} };
    default:
      return state;
  }
};

export const ChangeContentFileReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_CONTENT_FILE_REQUEST:
      return { loading: true };
    case CHANGE_CONTENT_FILE_SUCCESS:
      return { loading: false, ChangeContentFile: action.payload };
    case CHANGE_CONTENT_FILE_FAIL:
      return { loading: false, ChangeContentFile: action.payload };
    default:
      return state;
  }
};

export const ContentFileLinkCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_LINK_CREATE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_LINK_CREATE_SUCCESS:
      return { loading: false, ContentFileLinkCreate: action.payload };
    case CONTENT_FILE_LINK_CREATE_FAIL:
      return { loading: false, ContentFileLinkCreate: action.payload };
    case CONTENT_FILE_LINK_CREATE_CLEAR:
      return { loading: false, ContentFileLinkCreate: {} };
    default:
      return state;
  }
};

export const ContentFileLinkListReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_LINKS_REQUEST:
      return { loading: true };
    case CONTENT_FILE_LINKS_SUCCESS:
      return {
        loading: false,
        ...state,
        ContentFileLinkList: {
          ...state.ContentFileLinkList,
          [action.payload.type]: action.payload.data,
        },
      };
    case CONTENT_FILE_LINKS_FAIL:
      return { loading: false, ContentFileLinkList: action.payload };
    case CONTENT_FILE_LINKS_CLEAR:
      return { loading: false, ContentFileLinkList: {} };
    default:
      return state;
  }
};

export const ContentFileLinkUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_LINKS_UPDATE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_LINKS_UPDATE_SUCCESS:
      return { loading: false, ContentFileLinkUpdate: action.payload };
    case CONTENT_FILE_LINKS_UPDATE_FAIL:
      return { loading: false, ContentFileLinkUpdate: action.payload };
    case CONTENT_FILE_LINKS_UPDATE_CLEAR:
      return { loading: false, ContentFileLinkUpdate: {} };
    default:
      return state;
  }
};

export const ContentFileLinkDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTENT_FILE_LINKS_DELETE_REQUEST:
      return { loading: true };
    case CONTENT_FILE_LINKS_DELETE_SUCCESS:
      return { loading: false, ContentFileLinkDelete: action.payload };
    case CONTENT_FILE_LINKS_DELETE_FAIL:
      return { loading: false, ContentFileLinkDelete: action.payload };
    case CONTENT_FILE_LINKS_DELETE_CLEAR:
      return { loading: false, ContentFileLinkDelete: {} };
    default:
      return state;
  }
};
