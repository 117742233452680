import React from "react";

const Home = React.lazy(() => import("./Components/Views/Home"));
const MostPopular = React.lazy(() => import("./Components/Views/MostPopular"));
const MostRecent = React.lazy(() => import("./Components/Views/MostRecent"));
// const Trending = React.lazy(() => import("./Components/Views/Trending"));
const Featured = React.lazy(() => import("./Components/Views/Featured"));
const Searching = React.lazy(() => import("./Components/Views/Search"));
const Filter = React.lazy(() => import("./Components/Views/Filter"));
const MyChannel = React.lazy(() => import("./Components/Views/MyChannel"));
const Following = React.lazy(() => import("./Components/Views/Following"));
const ChannelHome = React.lazy(() => import("./Components/ChannelViews/Home"));
const IndividualChannel = React.lazy(() =>
  import("./Components/Views/IndividualChannel")
);
const ChannelVideos = React.lazy(() =>
  import("./Components/ChannelViews/Videos")
);
const ChannelAbout = React.lazy(() =>
  import("./Components/ChannelViews/About")
);

export const nav_routes = [
  {
    path: "/",
    name: "Home",
    navShow: true,
    component: Home,
    exact: true,
  },

  {
    path: "/following",
    name: "Following",
    navShow: true,
    component: Following,
    exact: true,
  },

  {
    path: "/mostpopular",
    name: "Most Popular",
    component: MostPopular,
    exact: true,
    navShow: true,
  },
  {
    path: "/mostrecent",
    name: "Most Recent",
    component: MostRecent,
    exact: true,
    navShow: true,
  },
  // {
  //   path: "/trending",
  //   name: "Trending",
  //   component: Trending,
  //   exact: true,
  //   navShow: true,
  // },
  {
    path: "/featured",
    name: "Featured",
    component: Featured,
    exact: true,
    navShow: true,
  },

  {
    path: "/individualchannel/:id",
    name: "IndividualChannel",
    // navShow: true,
    component: IndividualChannel,
    exact: true,
  },

  { path: "/results", name: "Searching", component: Searching, exact: true },
  // https://www.amazon.in/s?k=shirt&i=under-ten-dollars&ref=nb_sb_noss
  { path: "/s", name: "Filter", component: Filter, exact: true },

  {
    path: "/mychannel",
    name: "My Channels",
    navShow: true,
    component: MyChannel,
    exact: true,
  },
];

export const channel_routes = [
  {
    path: "/",
    name: "Home",
    component: ChannelHome,
    exact: true,
    navShow: true,
  },
  {
    path: "/channel-videos",
    name: "Videos",
    component: ChannelVideos,
    exact: true,
    navShow: true,
  },
  {
    path: "/channel-about",
    name: "About",
    component: ChannelAbout,
    exact: true,
    navShow: true,
  },
];
