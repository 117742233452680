import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import { nav_routes, routes } from "../routes";
import { Redirect } from "react-router-dom";
import { MemoryRouter } from "react-router-dom";
const AppContent = () => {
  // useEffect(() => {
  //   if (window.location.pathname != "/results") {
  //     localStorage.removeItem("search");
  //   }
  // }, []);

  return (
    <>
      <Suspense>
        <Switch>
          {nav_routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <>
                      <route.component {...props} />
                    </>
                  )}
                />
              )
            );
          })}
          <Redirect from="/" to="/" />
        </Switch>
      </Suspense>
    </>
  );
};

export default React.memo(AppContent);
