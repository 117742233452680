import {
    USER_LIST_USERS_FAIL,
    USER_LIST_USERS_REQUEST,
    USER_LIST_USERS_SUCCESS
} from "../types";

export const UserListUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LIST_USERS_REQUEST:
            return { loading: true };
        case USER_LIST_USERS_SUCCESS:
            return { loading: false, UserListUsers: action.payload };
        case USER_LIST_USERS_FAIL:
            return { loading: false, UserListUsers: action.payload };
        default:
            return state;
    }
};