import REACT_APP_BASEURL, { xml2json } from "../../utils";
import {
  CONTENT_FILE_SOCIAL_ADD_REQUEST,
  CONTENT_FILE_SOCIAL_ADD_SUCCESS,
  CONTENT_FILE_SOCIAL_ADD_FAIL,
  CONTENT_FILE_SOCIAL_REMOVE_REQUEST,
  CONTENT_FILE_SOCIAL_REMOVE_SUCCESS,
  CONTENT_FILE_SOCIAL_REMOVE_FAIL,
  SHARE_LINK_REQUEST,
  SHARE_LINK_SUCCESS,
  SHARE_LINK_FAIL,
  SHARE_LINK_CLEAR,
  COLLECTION_SOCIAL_ADD_REQUEST,
  COLLECTION_SOCIAL_ADD_SUCCESS,
  COLLECTION_SOCIAL_ADD_FAIL,
  FLAG_VIDEO_REQUEST,
  FLAG_VIDEO_SUCCESS,
  FLAG_VIDEO_FAIL,
  FLAG_VIDEO_CLEAR,
  LIST_UPLOAD_REQUEST,
  LIST_UPLOAD_SUCCESS,
  LIST_UPLOAD_FAIL,
  LIST_UPLOAD_CLEAR,
} from "../types";
import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";
import axios from "axios";

export const LikeAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_SOCIAL_ADD_REQUEST,
    });

    data = await axiosInstance.post(endPoint.CONTENT_FILE_SOCIAL_ADD, formData);
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_SOCIAL_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_SOCIAL_ADD_FAIL,
      payload: data,
    });
  }
};

export const RemoveLikeAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: CONTENT_FILE_SOCIAL_REMOVE_REQUEST,
    });

    data = await axiosInstance.post(
      endPoint.CONTENT_FILE_SOCIAL_REMOVE,
      formData
    );
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: CONTENT_FILE_SOCIAL_REMOVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_FILE_SOCIAL_REMOVE_FAIL,
      payload: data,
    });
  }
};

export const ShareLinkAction = (formData, socialName) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: SHARE_LINK_REQUEST,
    });

    data = await axios.post(
      `https://eqnetwork.com/${endPoint.SHARE_LINK}`,
      formData
    );
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: SHARE_LINK_SUCCESS,
      payload: { ...data, socialName },
    });
  } catch (error) {
    dispatch({
      type: SHARE_LINK_FAIL,
      payload: data,
    });
  }
};

export const ShareLinkClearAction = () => {
  return { type: SHARE_LINK_CLEAR };
};

export const CollectionSocialAddAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: COLLECTION_SOCIAL_ADD_REQUEST,
    });

    data = await axiosInstance.post(
      endPoint.COLLECTION_SOCIAL_ADD,
      formData
    );
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: COLLECTION_SOCIAL_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COLLECTION_SOCIAL_ADD_FAIL,
      payload: data,
    });
  }
};

export const FlagVideoAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: FLAG_VIDEO_REQUEST,
    });

    data = await axios.post(
      `https://eqnetwork.com${endPoint.FLAG_AS_INAPPROPRIATE}`,
      formData
    );
    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: FLAG_VIDEO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FLAG_VIDEO_FAIL,
      payload: data,
    });
  }
};

export const FlagVideoClearResponse = () => {
  return { type: FLAG_VIDEO_CLEAR };
};

export const ListUploadCallAction = (formData, retryCount = 0) => async (dispatch, getState) => {
  let data;
  try {
    dispatch({
      type: LIST_UPLOAD_REQUEST,
    });

    const cancelTokenSource = axios.CancelToken.source(); // Create a cancel token source

    const config = {
      cancelToken: cancelTokenSource.token, // Attach the cancel token to the request
    };

    data = await axios.post(
      `${REACT_APP_BASEURL}Api2.0/Upload2_ListUploads.aspx`,
      formData,
      config
    );

    // Check if the request was canceled
    if (axios.isCancel(data)) {
      if (retryCount < 5) {
        // If canceled and retry count is less than 5, recall the action
        dispatch(ListUploadCallAction(formData, retryCount + 1));
      } else {
        dispatch({
          type: LIST_UPLOAD_FAIL,
          payload: { message: 'Max retry count reached' },
        });
      }
      return; // Exit the function to prevent dispatching the success action
    }

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: LIST_UPLOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      if (retryCount < 5) {
        // If canceled and retry count is less than 5, recall the action
        dispatch(ListUploadCallAction(formData, retryCount + 1));
      } else {
        dispatch({
          type: LIST_UPLOAD_FAIL,
          payload: { message: 'Max retry count reached' },
        });
      }
      return; // Exit the function to prevent dispatching the failure action
    }

    if (error.response) {
      const status = error.response.status;
      if ((status === 502 || status === 400) && retryCount < 5) {
        // If specific error status code and retry count is less than 5, recall the action
        dispatch(ListUploadCallAction(formData, retryCount + 1));
        return; // Exit the function to prevent dispatching the failure action
      }
    }

    dispatch({
      type: LIST_UPLOAD_FAIL,
      payload: error,
    });
  }
};


export const ListUploadCallClear = () => {
  return { type: LIST_UPLOAD_CLEAR };
};