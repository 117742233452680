let years = Number;
let months = Number;
let days = Number;

export const wrapper = (data) => {
  if (data) {
    let result = JSON.stringify(data);
    return result
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/"/g, "")
      .replace(/:/g, "=")
      .replace(/,/g, "&");
  }
};

// Changes XML to JSON
export function xml2json(xml) {
  // Create the return object
  var obj = {};

  if (xml.nodeType == 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj[attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof obj[nodeName] == "undefined") {
        obj[nodeName] = xml2json(item);
      } else {
        if (typeof obj[nodeName].push == "undefined") {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xml2json(item));
      }
    }
  }
  return obj;
}

// get url parameter

export const getParameters = (search) => {
  var paramStr = search.substring(1).split("&");
  var parameters = {};
  paramStr.map((item) => {
    let keyValue = item.split("=");
    return (parameters[keyValue[0]] = keyValue[1]);
  });
  return parameters;
};

// export function xml2json(xml) {
//   try {
//     var obj = {};
//     if (xml.children.length > 0) {
//       for (var i = 0; i < xml.children.length; i++) {
//         var item = xml.children.item(i);
//         var nodeName = item.nodeName;

//         if (typeof obj[nodeName] == "undefined") {
//           obj[nodeName] = xml2json(item);
//         } else {
//           if (typeof obj[nodeName].push == "undefined") {
//             var old = obj[nodeName];

//             obj[nodeName] = [];
//             obj[nodeName].push(old);
//           }
//           obj[nodeName].push(xml2json(item));
//         }
//       }
//     } else {
//       obj = xml.textContent;
//     }
//     return obj;
//   } catch (e) {
//     console.log(e.message);
//   }
// }

export const isSignIn = () => {
  if (JSON.parse(localStorage.getItem("SessionGuid"))) {
    return true;
  }
  return false;
};
// export const SessionGuide = () => {
//   if (JSON.parse(localStorage.getItem("SessionGuid"))) {
//     return localStorage.getItem("SessionGuid");
//   }
//   return "";
// };

export const getOsInfo = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  var name = "Unknown";
  var version = "Unknown";
  if (userAgent.indexOf("win") > -1) {
    name = "Windows";
    if (userAgent.indexOf("windows nt 5.0") > -1) {
      version = "Windows 2000";
    } else if (
      userAgent.indexOf("windows nt 5.1") > -1 ||
      userAgent.indexOf("windows nt 5.2") > -1
    ) {
      version = "Windows XP";
    } else if (userAgent.indexOf("windows nt 6.0") > -1) {
      version = "Windows Vista";
    } else if (
      userAgent.indexOf("windows nt 6.1") > -1 ||
      userAgent.indexOf("windows 7") > -1
    ) {
      version = "Windows 7";
    } else if (
      userAgent.indexOf("windows nt 6.2") > -1 ||
      userAgent.indexOf("windows 8") > -1
    ) {
      version = "Windows 8";
    } else if (userAgent.indexOf("windows nt 6.3") > -1) {
      version = "Windows 8.1";
    } else if (
      userAgent.indexOf("windows nt 6.2") > -1 ||
      userAgent.indexOf("windows nt 10.0") > -1
    ) {
      version = "Windows 10";
    } else {
      version = "Unknown";
    }
  } else if (userAgent.indexOf("iphone") > -1) {
    name = "iPhone";
  } else if (userAgent.indexOf("mac") > -1) {
    name = "Mac";
    version = userAgent.match(/mac\s(os\sx\s[\d.\_]+)/)[1];
  } else if (
    userAgent.indexOf("x11") > -1 ||
    userAgent.indexOf("unix") > -1 ||
    userAgent.indexOf("sunname") > -1 ||
    userAgent.indexOf("bsd") > -1
  ) {
    name = "Unix";
  } else if (userAgent.indexOf("linux") > -1) {
    if (userAgent.indexOf("android") > -1) {
      name = "Android";
    } else {
      name = "Linux";
    }
  } else {
    name = "Unknown";
  }
  return {
    name,
    version,
  };
};

export const getBrowserInfo = () => {
  var userAgent = navigator.userAgent;
  var name = "Unknown";
  var version = "Unknown";
  var isIE11 = userAgent.toLowerCase().match(/rv:([\d.]+)\) like gecko/);
  var isOpera = userAgent.indexOf("Opera") > -1;
  var isIE =
    (userAgent.indexOf("compatible") > -1 &&
      userAgent.indexOf("MSIE") > -1 &&
      !isOpera) ||
    isIE11;
  var isEdge = userAgent.indexOf("Edg") > -1;
  var isFF = userAgent.indexOf("Firefox") > -1;
  var isSafari =
    userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1;
  var isChrome =
    userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1;

  if (isIE) {
    name = "IE";
    if (isIE11) {
      version = "11";
    } else {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      reIE.test(userAgent);
      var fIEVersion = parseFloat(RegExp.$1);

      if (fIEVersion < 7) {
        version = "version too old";
      } else {
        version = fIEVersion;
      }
    }
    return {
      name,
      version,
    };
  }

  if (isEdge) {
    return {
      name: "Edge",
      version: userAgent.match(/Edg\/([\d.]+)/)[1],
    };
  }
  if (isOpera) {
    return {
      name: "Opera",
      version: userAgent.match(/Opera.([\d.]+)/)[1],
    };
  }
  if (isFF) {
    return {
      name: "Firefox",
      version: userAgent.match(/Firefox\/([\d.]+)/)[1],
    };
  }
  if (isSafari) {
    return {
      name: "Safari",
      version: userAgent.match(/Safari\/([\d.]+)/)[1],
    };
  }
  if (isChrome) {
    return {
      name: "Chrome",
      version: userAgent.match(/Chrome\/([\d.]+)/)[1],
    };
  }

  return {
    name,
    version,
  };
};

// getting months year day

export const monthDiff = (start) => {
  let startDate = start.toString().split(" ");
  let newStartDate = startDate[0].split("/");
  let actualDate =
    newStartDate[2] +
    "-" +
    newStartDate[0] +
    "-" +
    newStartDate[1] +
    "T" +
    startDate[1] +
    "Z";

  var t1 = new Date(actualDate);
  var t2 = new Date();

  var dif = (t2.getTime() - t1.getTime()) / 1000;

  return dif;
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

// export const getFormatedStringFromDays = (numberOfDays) => {
//   if (numberOfDays >= 30 && numberOfDays < 365) {
//     months = Math.floor((numberOfDays % 365) / 30);
//     res = months + " Months ago";
//   } else if (numberOfDays >= 365) {
//     years = Math.floor(numberOfDays / 365);
//     res = years + " Years ago";
//   } else if (numberOfDays <= 1) {
//     Minute = Math.floor(numberOfDays * 24 * 60 * 60);
//   } else if (numberOfDays <= 1) {
//     Minute = Math.floor(numberOfDays * 24 * 60 * 60);
//   } else {
//     days = Math.floor((numberOfDays % 365) % 30);
//     res = days + " Days ago";
//   }
//   return res;
// };

export const getFormatedStringFromDays = (value) => {
  let res = "";
  let y = Math.floor(value / 31536000);
  let mo = Math.floor(value / 2628288);
  let d = Math.floor(value / 86400);
  let h = Math.floor(value / 3600);
  let m = Math.floor(value / 60);
  // let s = Math.floor(value % 60);

  if (y >= 1) {
    if (y === 1) {
      res = y + " year ago";
    } else {
      res = y + " years ago";
    }
  } else if (mo <= 12 && mo > 0) {
    if (mo === 1) {
      res = mo + " month ago";
    } else {
      res = mo + " months ago";
    }
  } else if (d <= 31 && d > 0) {
    if (d === 1) {
      res = d + " day ago";
    } else {
      res = d + " days ago";
    }
  } else if (h <= 24 && h > 0) {
    if (h === 1) {
      res = h + " hour ago";
    } else {
      res = h + " hours ago";
    }
  } else if (m <= 60 && m > 0) {
    if (m === 1) {
      res = m + " minute ago";
    } else {
      res = m + " minutes ago";
    }
  } else {
    let sec = Math.round(value + 2);
    if (sec === 1) {
      res = Math.round(value + 2) + " second ago";
    } else {
      res = Math.round(value) + " seconds ago";
    }
  }

  return res; // Return is HH : MM : SS
};

export const secondsToHms = (d) => {
  let res;
  d = Number(d);

  let e = d + 4;

  var h = Math.floor(e / 3600);
  var m = Math.floor((e % 3600) / 60);
  var s = Math.floor((e % 3600) % 60).toFixed();

  var hDisplay = h > 0 ? h + (h == 1 ? "" : "") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "" : "") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";

  if (hDisplay >= 1) {
    res = `${hDisplay}:${mDisplay}:${sDisplay} `;
  } else if (mDisplay >= 1 && mDisplay <= 60) {
    if (sDisplay < 10) {
      res = `${mDisplay}:0${sDisplay}`;
    } else {
      res = `${mDisplay}:${sDisplay}`;
    }
  } else if (sDisplay >= 10 && sDisplay < 60) {
    res = `0:${sDisplay}`;
  } else if (sDisplay >= 1 && sDisplay < 10) {
    res = `0:0${sDisplay}`;
  }
  //  else if (ms >= 1) {
  //   res = `${sDisplay}+1`;
  // }
  else {
    res = 0;
  }

  return res;
};

// export const getFormatedStringFromDays = (value) => {
//   let res = "";
//   let y = Math.floor(value / 31536000);
//   let mo = Math.floor((value % 31536000) / 2628000);
//   let d = Math.floor(((value % 31536000) % 2628000) / 86400);
//   let h = Math.floor((value % (3600 * 24)) / 3600);
//   let m = Math.floor((value % 3600) / 60);
//   let s = Math.floor(value % 60);

//   if (y > 365) {
//     res = y + "year ago";
//   }
//   if (mo > 31) {
//     res = mo + "month ago";
//   }
//   if (d > 24) {
//     res = d + "days ago";
//   }
//   if (h < 60) {
//     res = h + "hours ago";
//   }
//   if (m < 60) {
//     res = m + "minutes ago";
//   }
//   if (s < 60) {
//     res = s + " seconds ago";
//   }
//   return res; // Return is HH : MM : SS
// };

// const Views = (view) => {
//   let res = "";

//   return <div>utils</div>;
// };

// export const data = {
//   // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
//   datasets: [
//     {
//       label: "",
//       data: [65, 30],
//       backgroundColor: ["rgba(95, 199, 241, 1)", "rgba(71, 71, 71, 1)"],
//       borderColor: ["rgba(95, 199, 241, 1)", "rgba(71, 71, 71, 1)"],
//       borderWidth: 0.5,
//     },
//   ],
// };


export const REACT_APP_BASEURL_MAIN = "https://eqnetwork.com/"
export const REACT_APP_CLIENTID = "com.eqnetwork"

let REACT_APP_BASEURL = "";

if (typeof window !== "undefined") {
  const currentURL = window.location.href;
  if (currentURL?.includes("localhost") || currentURL?.includes("stage")) {
    REACT_APP_BASEURL = "https://eqnetwork.com/sd/" //stage
  } else {
    REACT_APP_BASEURL = "https://eqnetwork.com/sd/" //Production
  }
}
export default REACT_APP_BASEURL;
