import {
    UPLOAD_VIDEO_CANCEL_REQUEST,
    UPLOAD_VIDEO_CANCEL_SUCCESS,
    UPLOAD_VIDEO_CANCEL_FAIL,
    UPLOAD_VIDEO_CANCEL_RESPONSE_CLEAR
} from "../types";

export const UploadVideoCancelReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_VIDEO_CANCEL_REQUEST:
            return { loading: true };
        case UPLOAD_VIDEO_CANCEL_SUCCESS:
            return { loading: false, UploadVideoCancel: action.payload };
        case UPLOAD_VIDEO_CANCEL_FAIL:
            return { loading: false, UploadVideoCancel: action.payload };
        case UPLOAD_VIDEO_CANCEL_RESPONSE_CLEAR:
            return { loading: false, UploadVideoCancel: {} };
        default:
            return state;
    }
};
