import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS

import { isSignIn } from "../../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FollowContributeReqModal = ({ modalMsg = "", isOpen = false, setIsOpen = () => { } }) => {
    const history = useHistory();
    const modalRef = useRef();
    const location = useLocation();

    useEffect(() => {
        // Expose the show method to the parent component
        if (isOpen) {
            showModal();
            setIsOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            setIsOpen(false);
        };
    }, [])

    const showModal = () => {
        if (modalRef.current) {
            // Use the Bootstrap modal method to show the modal
            const modal = new window.bootstrap.Modal(modalRef.current); // Use window.bootstrap
            modal.show();
        }
    };

    const onclickHandlerClick = (val) => {
        const url = `${location.pathname}${location.search}`;
        localStorage.setItem("path", url);
        if (url) {
            history.push(val);
        }
    };

    return (
        <>
            {/* modal Popup */}
            <div
                style={{ width: "30rem" }}
                className="modal fade add_invite_modal"
                id="FollowContributeReqModal"
                aria-labelledby="FollowContributeReqModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                ref={modalRef}
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl "
                    style={{ zIndex: "initial" }}
                >
                    <div className="modal-content radius_none eq_grey">
                        <div className="modal-header">
                            <h5 className="modal-title text-white" id="exampleModalLabel">
                                EQ Network
                            </h5>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Grey_close_x.svg/1024px-Grey_close_x.svg.png"
                                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                data-bs-toggle="modal"
                                data-bs-whatever="@mdo"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            {isSignIn()
                                ? <div>{modalMsg}</div>
                                : <div>You must authenticate in order to contribute to this channel</div>
                            }
                            <div className="mt-5" style={{ float: "right" }}>
                                {isSignIn()
                                    ? <Button
                                        type="button"
                                        className="btn eq_button my-2 mx-2 text-white pointer"
                                        data-bs-toggle="modal"
                                        data-bs-whatever="@mdo"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Ok
                                    </Button>
                                    : <div className="d-flex">
                                        <Button
                                            type="button"
                                            className="btn eq_button my-2 mx-2 text-white pointer"
                                            data-bs-toggle="modal"
                                            data-bs-whatever="@mdo"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            className="btn eq_button my-2 mx-2 text-white pointer"
                                            data-bs-toggle="modal"
                                            data-bs-whatever="@mdo"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => onclickHandlerClick("/signin")}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FollowContributeReqModal;
