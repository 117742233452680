import { xml2json } from "../../utils";
import {
  FOLLOW_COLLECTION_REQUEST,
  FOLLOW_COLLECTION_SUCCESS,
  FOLLOW_COLLECTION_FAIL,
  REMOVE_COLLECTION_REQUEST,
  REMOVE_COLLECTION_SUCCESS,
  REMOVE_COLLECTION_FAIL,
  ADD_REMOVE_RELATIONSHIP_REQUEST,
  ADD_REMOVE_RELATIONSHIP_SUCCESS,
  ADD_REMOVE_RELATIONSHIP_FAIL,
} from "../types";
import { axiosInstance } from "../AxiosInstance/AxiosInstance";
import { endPoint } from "./apiConstant/EndPoints";

// follow collection
export const SubscribeAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: FOLLOW_COLLECTION_REQUEST,
    });
    data = await axiosInstance.post(endPoint.FOLLOW_COLLECTION, formData);

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: FOLLOW_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FOLLOW_COLLECTION_FAIL,
      payload: data,
    });
  }
};

export const UnSubscribeAction = (formData) => async (dispatch) => {
  let data;
  try {
    dispatch({
      type: REMOVE_COLLECTION_REQUEST,
    });
    data = await axiosInstance.post(endPoint.REMOVE_COLLECTION, formData);

    const res = `${data.data}`;
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    data = xml2json(xmlDoc);

    dispatch({
      type: REMOVE_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_COLLECTION_FAIL,
      payload: data,
    });
  }
};

// follow user
export const UserAddRemoveRelationshipAction =
  (formData) => async (dispatch) => {
    let data;
    try {
      dispatch({
        type: ADD_REMOVE_RELATIONSHIP_REQUEST,
      });
      data = await axiosInstance.post(endPoint.USER_ADDRELATIONSHIP, formData);

      const res = `${data.data}`;
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(res, "text/xml");
      data = xml2json(xmlDoc);

      dispatch({
        type: ADD_REMOVE_RELATIONSHIP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_REMOVE_RELATIONSHIP_FAIL,
        payload: data,
      });
    }
  };
