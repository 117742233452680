import {
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESPONSE_REMOVE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
} from "../types";
export const UpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, UpdateProfile: action.payload };
    case USER_UPDATE_FAIL:
      return { loading: false, UpdateProfile: action.payload };
    case USER_UPDATE_RESPONSE_REMOVE:
      return { loading: false, UpdateProfile: null };
    default:
      return state;
  }
};
export const DeleteProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, DeleteProfile: action.payload };
    case USER_DELETE_FAIL:
      return { loading: false, DeleteProfile: action.payload };
    default:
      return state;
  }
};
