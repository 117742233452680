import {
    RESEND_CONFIRMATION_EMAIL_FAIL,
    RESEND_CONFIRMATION_EMAIL_REQUEST,
    RESEND_CONFIRMATION_EMAIL_SUCCESS
} from "../types";

export const ResendConfirmationEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case RESEND_CONFIRMATION_EMAIL_REQUEST:
            return { loading: true };
        case RESEND_CONFIRMATION_EMAIL_SUCCESS:
            return { loading: false, ResendConfirmationEmail: action.payload };
        case RESEND_CONFIRMATION_EMAIL_FAIL:
            return { loading: false, ResendConfirmationEmail: action.payload };
        default:
            return state;
    }
};