import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const [version, setVersion] = useState("");
  useEffect(() => {
    setVersion(process.env.REACT_APP_BUILD_VERSION);
  }, []);

  return (
    <>
      <footer
        className="footer footer_Bottom mt-auto py-3 bg-black"
        style={{
          padding: "0px",
          color: "rgba(0, 0, 0, 0.85)",
          fontSize: "14px",

          flex: "0 0 auto",
        }}
      >
        <div className="container-fluid d-flex ">
          <div className=" d-flex justify-content-center align-content-center flex-grow-1">
            <NavLink to="/devloper-support" className="text-decoration-none">
              <h6 className="text-muted mx-3 text-white">
                Developer Support Page
              </h6>
            </NavLink>
            <NavLink to="/help" className="text-decoration-none ">
              <h6 className="text-muted mx-3 text-white ">Help</h6>
            </NavLink>
            <NavLink to="/faqs" className="text-decoration-none ">
              <h6 className="text-muted mx-3 text-white ">FAQs.</h6>
            </NavLink>
            <a
              target="_blank"
              href="https://eqnetwork.com/privacy.html"
              className="text-decoration-none"
            >
              <h6 className="text-muted mx-3 text-white">Privacy Policy</h6>
            </a>
            <a
              target="_blank"
              href="https://eqnetwork.com/tos.html"
              className="text-decoration-none"
            >
              <h6 className="text-muted mx-3 text-white">Terms of Service</h6>
            </a>
          </div>
          <div className="text-muted">V {version}</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
